import { DatePicker } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import Button from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import InfoCell from '../../../../components/InfoCell'
import { threeOptions } from '../../../../features/constants'
import { CATEGORY } from '../../../../features/packaging/model'
import { selectAllTaxonomies } from '../../../../features/packaging/selectors'
import {
  INNOVATION_LEVEL_DROPDOWN,
  LEVEL_DROPDOWN
} from '../../../../features/product/constants'
import { selectUserRole } from '../../../../features/users/selectors'
import {
  checkFieldVisibilityByCategory,
  checkFieldVisibilityByRoles,
  getDynamicPackDropdownByKey
} from '../../../../utils'
import { checkFieldDisability } from '../../../../utils/packagingFormHelper'
import { checkRangeOptions, sortItemsRanges } from '../../../../utils/sortItemsRanges'

import { LabelContainer, Label, Value, DatePickerWrapper } from './styled'
import { ISideInfoFormProps } from './types'

const SideInfoForm: FC<ISideInfoFormProps> = ({
  data,
  isEditing = false,
  updateData = () => {},
  isCreating = false
}) => {
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const userRoles = useSelector(selectUserRole)

  const categoryValue: string = get(data, 'category', '')
  const subCategoryValue: string = data.subcategory || ''

  const catInLowercase = categoryValue.toLowerCase()
  const subCatInLowercase = subCategoryValue.toLowerCase()
  const isVials = catInLowercase === CATEGORY.VIALS
  const url = `${window.location.href}`
  const wikipack = url.includes('wikipack')

  const cleanValue = get(data, 'clean', null)

  const updateMultiSelect: (key: string, inputValue: any, isString?: boolean) => void = (
    key,
    inputValue,
    isString = false
  ) => {
    const value = isString ? inputValue : inputValue.map(({ value }: any) => value)
    updateData(key, value)
  }

  const isFieldVisible = (key: string) => {
    let visibility = true
    visibility =
      visibility &&
      checkFieldVisibilityByCategory({
        key,
        category: catInLowercase,
        subcategory: subCatInLowercase
      })

    visibility =
      visibility &&
      checkFieldVisibilityByRoles({
        key,
        roles: userRoles,
        isOnCreate: isCreating && isEditing,
        isOnEdit: !isCreating && isEditing,
        isOnRead: !isCreating && !isEditing
      })

    return visibility
  }

  const isFieldDisable = (key: string) => {
    return checkFieldDisability(key, catInLowercase, subCatInLowercase)
  }

  const getCategoryDropDown = () => {
    return allTaxonomies.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getFilteredCategory = () => {
    return allTaxonomies.find(
      ({ value: catValue }: any) => catValue.toLowerCase() === catInLowercase
    )
  }

  const getSubCategoryDropDown = () => {
    const filteredCategory = getFilteredCategory()
    const filteredSubcategory = get(filteredCategory, 'children.subcategory', [])
    return filteredSubcategory.map(({ value }: any) => ({
      name: value,
      value
    }))
  }

  const getDropDown = (key: string) => {
    return getDynamicPackDropdownByKey({
      key,
      category: categoryValue,
      subcategory: subCategoryValue,
      taxonomies: allTaxonomies
    })
  }

  const freeOfMaterialDropdown = getDropDown('freeOfMaterial')

  const subCategoryDropDown = getSubCategoryDropDown()

  const isSubCategoryDisable =
    !categoryValue || (!!categoryValue && isFieldDisable('subcategory'))
  const isBottomVisible =
    (isEditing &&
      categoryValue &&
      ((subCategoryDropDown.length && subCategoryValue) ||
        !subCategoryDropDown.length)) ||
    !isEditing

  const isCatOthers = catInLowercase === CATEGORY.OTHERS
  const isSizeInput = catInLowercase === CATEGORY.AEROSOL || isCatOthers

  const materialValue = get(data, 'material', isCatOthers ? '' : [])

  const FreeOfMaterialLabel = (
    <LabelContainer>
      Free-of material
      {isEditing && (
        <Button
          variant="link"
          label="Select All"
          onClick={() => updateMultiSelect('freeOfMaterial', freeOfMaterialDropdown)}
        >
          Select All
        </Button>
      )}
    </LabelContainer>
  )

  return (
    <>
      <BoxWrapper enablePadding>
        <InfoCell
          label="Category"
          value={categoryValue}
          inputType="select"
          isEditing={isEditing}
          inputConfig={{
            items: getCategoryDropDown(),
            onChange: ({ value }: Record<string, any>) => {
              updateData('category', value)
            },
            orderItems: true,
            orderKey: 'name'
          }}
        />
        {isFieldVisible('subcategory') && (
          <InfoCell
            label="Subcategory"
            value={subCategoryValue}
            inputType={isCatOthers ? 'input' : 'select'}
            isEditing={isEditing}
            inputConfig={{
              items: subCategoryDropDown,
              onChange: (inputValue: Record<string, any>) => {
                updateData('subcategory', isCatOthers ? inputValue : inputValue.value)
              },
              orderItems: true,
              orderKey: 'name',
              disabled: isSubCategoryDisable
            }}
          />
        )}
      </BoxWrapper>
      <Divider />
      {isBottomVisible && (
        <>
          <BoxWrapper enablePadding>
            {isFieldVisible('size') && (
              <InfoCell
                label="Size"
                value={get(data, 'size', isSizeInput || isCreating ? '' : [])}
                inputType={isSizeInput ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('size'),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelect('size', inputValue, isSizeInput)
                      : updateData('size', isSizeInput ? inputValue : inputValue.value),
                  orderItems: true,
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}
            {isFieldVisible('material') && (
              <InfoCell
                label="Bulk-Contact Material"
                value={materialValue}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('material'),
                  required: isCreating && isVials,
                  error: isCreating && isVials && !materialValue.length,
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('material', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('panNumber') && (
              <InfoCell
                label="Pan Number"
                value={get(data, 'panNumber', '')}
                isEditing={isEditing}
                inputConfig={{
                  onChange: (value: string) => updateData('panNumber', value)
                }}
              />
            )}
            {isFieldVisible('tipShape') && (
              <InfoCell
                label="Tip Shape"
                value={get(data, 'tipShape', '')}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('tipShape'),
                  onChange: ({ value }: Record<string, any>) =>
                    updateData('tipShape', value),
                  orderItems: true,
                  orderKey: 'name'
                }}
              />
            )}
            {isFieldVisible('panShape') && (
              <InfoCell
                label="Pan Shape"
                value={get(data, 'panShape', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('panShape'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('panShape', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('shape') && (
              <InfoCell
                label="Ext. Shape"
                value={get(data, 'shape', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('shape'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('shape', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  disabled: !categoryValue,
                  mode: 'multiple'
                }}
              />
            )}

            {isFieldVisible('technology') && (
              <InfoCell
                label="Technology"
                value={get(data, 'technology', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('technology'),
                  onChange: (inputValue: Record<string, any>) =>
                    updateData('technology', isCatOthers ? inputValue : inputValue.value),
                  orderItems: true,
                  orderKey: 'name'
                }}
              />
            )}
            {isFieldVisible('capacityRange') && (
              <InfoCell
                label="Capacity Range"
                value={get(data, 'capacityRange', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: sortItemsRanges(getDropDown('capacityRange')),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelect('capacityRange', inputValue, isCatOthers)
                      : updateData(
                          'capacityRange',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                  orderItems: !checkRangeOptions(getDropDown('capacityRange')),
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}
            {isFieldVisible('panSize') && (
              <InfoCell
                label="Pan Size"
                value={get(data, 'panSize', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: sortItemsRanges(getDropDown('panSize')),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('panSize', value, isCatOthers),
                  mode: 'multiple'
                }}
              />
            )}
          </BoxWrapper>
          <BoxWrapper enablePadding>
            {isFieldVisible('applicatorAndAccessories') && (
              <InfoCell
                label={isVials ? 'Accessories' : 'Applicator and Accessories'}
                value={get(data, 'applicatorAndAccessories', isCatOthers ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('applicatorAndAccessories'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('applicatorAndAccessories', value, isCatOthers),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}

            {isFieldVisible('closure') && (
              <InfoCell
                label="Closure"
                value={get(data, 'closure', isCatOthers || isCreating ? '' : [])}
                inputType={isCatOthers ? 'input' : 'select'}
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('closure'),
                  onChange: (inputValue: Record<string, any>) =>
                    !isCreating
                      ? updateMultiSelect('closure', inputValue, isCatOthers)
                      : updateData(
                          'closure',
                          isCatOthers ? inputValue : inputValue.value
                        ),
                  orderItems: true,
                  orderKey: 'name',
                  mode: !isCreating ? 'multiple' : undefined
                }}
              />
            )}

            <InfoCell
              label="Level"
              value={get(data, 'level', [])}
              inputType="select"
              isEditing={isEditing}
              inputConfig={{
                items: getDropDown('level'),
                onChange: (inputValue: Record<string, any>) =>
                  !isCreating
                    ? updateMultiSelect('level', inputValue)
                    : updateData('level', inputValue.value),
                orderItems: true,
                orderKey: 'name',
                mode: !isCreating ? 'multiple' : undefined
              }}
            />
            <InfoCell
              label="Note"
              value={get(data, 'note', '')}
              isEditing={isEditing}
              inputConfig={{
                onChange: (value: any) => updateData('note', value)
              }}
            />
          </BoxWrapper>
          <BoxWrapper enablePadding>
            <InfoCell
              label="Scouting"
              dataType="options-value"
              value={get(data, 'scouting', null)}
              inputType="options-button"
              isEditing={isEditing}
              inputConfig={{
                options: threeOptions,
                onChange: (value: string) => updateData('scouting', value)
              }}
            />
            {isFieldVisible('mirror') && (
              <InfoCell
                label="Mirror"
                value={get(data, 'mirror', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updateData('mirror', value)
                }}
              />
            )}
            <InfoCell
              label="Airtight"
              value={get(data, 'airtight', '')}
              inputType="select"
              isEditing={isEditing}
              inputConfig={{
                items: getDropDown('airtight'),
                onChange: ({ value }: any) => updateData('airtight', value),
                orderItems: true,
                orderKey: 'name'
              }}
            />
          </BoxWrapper>
          <Divider />
          <BoxWrapper enablePadding>
            {isFieldVisible('clean') && (
              <InfoCell
                label="Clean"
                value={cleanValue}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updateData('clean', value)
                }}
              />
            )}
            {isFieldVisible('cleanTypes') && (
              <InfoCell
                label="Clean Types"
                value={get(data, 'cleanTypes', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: getDropDown('cleanTypes'),
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('cleanTypes', value),
                  orderItems: true,
                  orderKey: 'name',
                  disabled: !cleanValue,
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('freeOfMaterial') && (
              <InfoCell
                label={FreeOfMaterialLabel}
                value={get(data, 'freeOfMaterial', [])}
                inputType="select"
                isEditing={isEditing}
                inputConfig={{
                  items: freeOfMaterialDropdown,
                  onChange: (value: Record<string, any>) =>
                    updateMultiSelect('freeOfMaterial', value),
                  orderItems: true,
                  orderKey: 'name',
                  mode: 'multiple'
                }}
              />
            )}
            {isFieldVisible('foodgrade') && (
              <InfoCell
                label="Foodgrade"
                value={get(data, 'foodgrade', null)}
                inputType="options-button"
                dataType="options-value"
                isEditing={isEditing}
                inputConfig={{
                  options: threeOptions,
                  onChange: (value: any) => updateData('foodgrade', value)
                }}
              />
            )}
            {!wikipack && (
              <>
                {isEditing ? (
                  <DatePickerWrapper>
                    <span>Stability Start Date</span>
                    <DatePicker
                      value={data.stabilityStartDate && moment(data.stabilityStartDate)}
                      format="DD/MM/YYYY"
                      onChange={(value: any) => {
                        updateData('stabilityStartDate', value.utc().format())
                      }}
                    />
                  </DatePickerWrapper>
                ) : (
                  <DatePickerWrapper>
                    <Label>Stability Start Date</Label>
                    <Value>{get(data, 'stabilityStartDate')}</Value>
                  </DatePickerWrapper>
                )}

                <InfoCell
                  label="Innovation Level"
                  value={get(data, 'innovationLevel', '')}
                  isEditing={isEditing}
                  inputType="select"
                  inputConfig={{
                    items: INNOVATION_LEVEL_DROPDOWN,
                    onChange: ({ value }: any) => updateData('innovationLevel', value)
                  }}
                />
                <InfoCell
                  label="Bulk Level"
                  value={get(data, 'bulkLevel', '')}
                  isEditing={isEditing}
                  inputType="select"
                  inputConfig={{
                    items: LEVEL_DROPDOWN,
                    onChange: ({ value }: any) => updateData('bulkLevel', parseInt(value))
                  }}
                />
                <InfoCell
                  label="Compatibility Level"
                  value={get(data, 'compatibility', '')}
                  isEditing={isEditing}
                  inputType="select"
                  inputConfig={{
                    items: LEVEL_DROPDOWN,
                    onChange: ({ value }: any) =>
                      updateData('compatibility', parseInt(value))
                  }}
                />
                <InfoCell
                  label="Industrial Level"
                  value={get(data, 'industrialLevel', '')}
                  isEditing={isEditing}
                  inputType="select"
                  inputConfig={{
                    items: LEVEL_DROPDOWN,
                    onChange: ({ value }: any) =>
                      updateData('industrialLevel', parseInt(value))
                  }}
                />
                <InfoCell
                  label="Packaging Level"
                  value={get(data, 'packagingLevel', '')}
                  isEditing={isEditing}
                  inputType="select"
                  inputConfig={{
                    items: LEVEL_DROPDOWN,
                    onChange: ({ value }: any) =>
                      updateData('packagingLevel', parseInt(value))
                  }}
                />
              </>
            )}
          </BoxWrapper>
        </>
      )}
    </>
  )
}

export default SideInfoForm
