import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../components'
import { pageTitleHeight } from '../../../components/PageTitle/styled'
import { selectOrderListPagination } from '../../../features/orderList/selectors'
import { pageHeaderHeight } from '../../../pages/OrderDetails/styled'
import { columnsGeneratorHelper } from '../../../utils'
import { orderDataExtractor } from '../../../utils/orderHelpers'
import { rowsGeneratorHelper } from '../../../utils/tableHelpers'
import { filterActionHeight } from '../../OrderListContent/styled'
import { IOrdersTableProps } from './types'

const OrdersTable: FC<IOrdersTableProps> = ({
  tableConfig,
  actionConfig,
  dataList,
  isLoading,
  emptyBody,
  onChangePage
}) => {
  const dataRowGenerator = () => {
    const rowsData = rowsGeneratorHelper(dataList, tableConfig)
    return rowsData.map((order, index) => {
      const { ownerName, ownerSurname } = orderDataExtractor(dataList[index])
      order.ownerFullName = `${ownerName} ${ownerSurname}`
      return order
    })
  }

  const paginationData = useSelector(selectOrderListPagination)
  const columns = columnsGeneratorHelper(tableConfig)
  const dataRow = dataList.length ? dataRowGenerator() : []

  return (
    <Table
      columns={columns}
      items={dataRow}
      rowKey="id"
      enablePagination
      paginationPosition={'BOTTOM_RIGHT'}
      pagination={{
        current: paginationData?.page,
        pageSize: paginationData?.pageSize,
        total: paginationData?.total
      }}
      contentOverTableHeight={pageHeaderHeight + pageTitleHeight + filterActionHeight}
      forceHeight
      isLoading={isLoading}
      emptyBody={emptyBody}
      onChangePage={onChangePage}
      actionColumnConfig={actionConfig}
    />
  )
}

export default OrdersTable
