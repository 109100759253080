import { get } from 'lodash'
import {
  BaseSpecificationCharacteristic,
  IProjectSpecDetail,
  ProjectSpecCharacteristic
} from '../../features/masterSpec/model'
import { SapCodeConcerned } from '../../features/packaging/model'

export const convertBaseCharToProjectChar = (
  baseChar: BaseSpecificationCharacteristic & ProjectSpecCharacteristic
): ProjectSpecCharacteristic => {
  const projectChar: ProjectSpecCharacteristic = {
    aql: baseChar.aql,
    catalog: baseChar.catalog,
    characteristicMeasurementUnit: baseChar.characteristicMeasurementUnit,
    measurementUnitRef: baseChar.measurementUnitRef,
    controlCharacteristic: baseChar.controlCharacteristic,
    controlPoint: baseChar.controlPoint,
    displayOrder: baseChar.displayOrder,
    generalStatus: baseChar.generalStatus,
    inspectionLevel: baseChar.inspectionLevel,
    map: baseChar.map,
    measurementUnit: baseChar.measurementUnit,
    method: baseChar.method,
    operationNumber: baseChar.operationNumber,
    plant: baseChar.plant,
    samplingProcedure: baseChar.samplingProcedure,
    skipRules: baseChar.skipRules,

    usage: baseChar.usage,
    workCenter: baseChar.workCenter,

    testingMethod: baseChar.method,

    standard: baseChar.catalogDescription,
    itemName: baseChar.characteristicDescription,
    overrideItemName:
      baseChar.overrideItemName ||
      baseChar.characteristicDescription ||
      baseChar.itemName,
    overrideStandard:
      baseChar.overrideStandard || baseChar.catalogDescription || baseChar.standard,

    specificationSectionId: baseChar.specificationSectionId,
    hasUpperLimit: !!baseChar.hasUpperLimit,
    hasLowerLimit: !!baseChar.hasLowerLimit,
    allowedDecimalsLimit: baseChar.allowedDecimalsLimit,
    baseSpecificationCharacteristicId:
      baseChar.baseSpecificationCharacteristicId || baseChar.id
  }
  return projectChar
}

export const cleanProjectSpec = (
  projectSpec: Partial<IProjectSpecDetail>,
  packageVariantId?: string
) => {
  if (projectSpec.projectSpecificationCharacteristics)
    return {
      id: get(projectSpec, 'id', '').length > 30 ? projectSpec.id : undefined,
      sideCollectorId: projectSpec.sideCollectorId,
      packageVariantId: packageVariantId || projectSpec.packageVariantId,
      thumbnailOverride: projectSpec.thumbnailOverride,
      supplierName: projectSpec.supplierName,
      masterCode: projectSpec.masterCode,
      supplierItemCode: projectSpec.supplierItemCode,
      title: projectSpec.title,
      description: projectSpec.description,
      sapDescription: projectSpec.sapDescription,
      status: projectSpec.status,
      customerProjectName: projectSpec.customerProjectName,
      nbShade: projectSpec.nbShade,
      remarks: projectSpec.remarks,
      masterSpecificationId: projectSpec.masterSpecificationId,
      concernedSapCodes: projectSpec.concernedSapCodes || [],

      projectSpecificationCharacteristics:
        projectSpec.projectSpecificationCharacteristics.map((characteristic) => {
          const cleanChar = {
            id: get(characteristic, 'id', '').length > 30 ? characteristic.id : undefined,
            itemName: characteristic.itemName,
            overrideItemName: characteristic.overrideItemName,
            standard: characteristic.standard,
            overrideStandard: characteristic.overrideStandard,
            qualityInspection: characteristic.qualityInspection,
            masterHom: characteristic.masterHom,
            customizedDev: characteristic.customizedDev,
            supplierCoa: characteristic.supplierCoa,
            specificationSectionId: characteristic.specificationSectionId,
            baseSpecificationCharacteristicId:
              characteristic.baseSpecificationCharacteristicId,
            upperLimit: characteristic.upperLimit
              ? parseFloat(characteristic.upperLimit)
              : null,
            lowerLimit: characteristic.lowerLimit
              ? parseFloat(characteristic.lowerLimit)
              : null,
            sapCodes: characteristic.sapCodes?.map((code) => {
              const { projectSpecificationCharacteristicsId, ...cleanCode } = code
              return cleanCode
            })
          }
          return cleanChar
        })
    }
}

export const createDuplicatesMessage = (data: any) => {
  const message = data.reduce(
    (acc: string, curr: any) =>
      `The SAP CODE ${curr.sapCode} is already associated to a Control Plan with a Project Specification of Package ${curr.packagingCode}.\n` +
      acc,
    'Do you want to associate the SAP to the new Control Plan? If yes the SAP CODE will be automatically disassociated from the oldest one.'
  )
  return 'Please be informed that: \n' + message
}

export const createSendToSapMessage = (data: any) => {
  return data.reduce(
    (acc: string, curr: any) => acc + `${curr.code}\n`,
    'The following Control Plans will be sent to SAP QM, \n Are you sure you want to continue? \n\n'
  )
}

interface IApplySapMessageArgs {
  sapCodes: SapCodeConcerned[]
  status: string
}

export const createApplyAllSapToCharactersMessage: (
  arg: IApplySapMessageArgs
) => string = ({ sapCodes, status }) => {
  const sapCodesStringList = sapCodes.map(({ code }) => `${code}\n`).join('')
  const message =
    status === 'SENT_TO_SAP'
      ? 'The following Control Plans will be sent to SAP QM, \n Are you sure you want to continue? \n\n'
      : 'You are going to apply all Sap Codes to all Characteristics, \n Are you sure you want to continue? \n\n'

  return `${message}${sapCodesStringList}`
}
