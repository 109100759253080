import { MsalProvider } from '@azure/msal-react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import packageJson from '../package.json'

import App from './App'
import { msalInstance } from './configs/auth-msal'
import { store } from './features/store'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
console.log(`🔖 APP_VERSION: v${packageJson.version}`)

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MsalProvider>
)
