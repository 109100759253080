import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Text } from '../../../components/Text'
import { PACKAGING_STATUS } from '../../../features/packaging/constants'
import { CATEGORY } from '../../../features/packaging/model'
import { getAllStatus } from '../../../features/packaging/selectors'
import { statusesByRole } from '../../../features/packaging/utils'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import {
  checkFieldVisibilityByCategory,
  checkFieldVisibilityByRoles
} from '../../../utils'
import { canUserReadPackagingData } from '../../../utils/permissionsHelpers'
import { IPackagingDataProps } from './types'

const PackagingData: FC<IPackagingDataProps> = ({ data, isMulti }) => {
  const packageStatus = get(data, 'status', '')
  const userRoles = useSelector(selectUserRole)

  const statusesDropdown = useSelector(getAllStatus)
    .filter(({ name }: { name: PACKAGING_STATUS }) =>
      userRoles.some((role: any) => statusesByRole[name].includes(role))
    )
    .map(({ name }: { name: PACKAGING_STATUS }) => name)

  const canSeeDetail =
    canUserReadPackagingData({ userRoles }) && statusesDropdown.includes(packageStatus)

  const category = get(data, 'category', '')
  const subcategory = get(data, 'subcategory', '')
  const packageId = get(data, 'packageId', '')
  const variantId = get(data, 'variantId', '')
  const isVials = category?.toLowerCase() === CATEGORY.VIALS
  const isFieldVisible: (k: string) => boolean = (key) => {
    let isVisible = true
    isVisible =
      isVisible &&
      checkFieldVisibilityByRoles({
        key,
        isOnRead: true,
        roles: userRoles
      })

    isVisible =
      isVisible &&
      checkFieldVisibilityByCategory({
        key,
        category: category?.toLowerCase(),
        subcategory: subcategory?.toLowerCase()
      })
    if (key === 'material') {
      isVisible = isVisible && category?.toLowerCase() !== CATEGORY.VIALS
    }
    return isVisible
  }

  return (
    <Form layout="vertical">
      <Text
        text={
          '*Packaging data are inherited from Packaging DB and it’s under constant review by Packaging Team'
        }
        italic
      />
      <BoxWrapper enablePadding>
        <InfoCell
          label={'PK Intercos Code'}
          dataType={canSeeDetail ? 'link' : 'string'}
          value={get(data, 'packagingCode', '')}
          linkConfig={{
            path: `${AppRoutes.WIKIPACK_PACKAGING}/${packageId}`
          }}
        />

        {!isMulti && (
          <InfoCell label={'Packaging Category'} value={get(data, 'category', '')} />
        )}
        {isMulti && (
          <InfoCell label={'Packaging Macro Category'} value={'Multipackage'} />
        )}

        {!isMulti && (
          <InfoCell
            label={'Packaging Subcategory'}
            value={get(data, 'subcategory', '')}
          />
        )}

        {!isMulti && isVials && (
          <InfoCell
            label={'Variant Code'}
            value={get(data, 'variantCode', '')}
            dataType={canSeeDetail ? 'link' : 'string'}
            linkConfig={{
              path: `${AppRoutes.WIKIPACK_PACKAGING}/${packageId}/variant/${variantId}`
            }}
          />
        )}

        {isVials && isFieldVisible('variantSupplierCode') && (
          <InfoCell
            label={'Variant SupplierCode'}
            value={get(data, 'variantSupplierCode', '')}
          />
        )}
        {isFieldVisible('supplierRef') && (
          <InfoCell label={'Supplier Pkg Code'} value={get(data, 'supplierRef', '')} />
        )}
        {isFieldVisible('testedSupplier') && (
          <InfoCell
            label={'Packaging Supplier'}
            value={get(data, 'testedSupplier.name', '')}
          />
        )}
        {!isMulti && isFieldVisible('bottle') && (
          <InfoCell label="Bottle" value={get(data, 'bottle', '')} />
        )}
        {!isMulti && isFieldVisible('dipstick') && (
          <InfoCell label="Stem" value={get(data, 'dipstick', '')} />
        )}
        {!isMulti && isFieldVisible('wiper') && (
          <InfoCell label="Wiper" value={get(data, 'wiper', '')} />
        )}
        {!isMulti && isFieldVisible('applicator') && (
          <InfoCell label="Applicator" value={get(data, 'applicator', '')} />
        )}
        {!isMulti && <InfoCell label={'Format'} value={get(data, 'category', '')} />}

        {!isMulti && (
          <InfoCell
            label={'AT Packaging Need'}
            value={get(data, 'packageFamilies[0].airtight', '')}
          />
        )}
        {!isMulti && isFieldVisible('material') && (
          <InfoCell
            label={'Main material in contact with Formula'}
            value={get(data, 'packageFamilies[0].material', '')}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default PackagingData
