import { compact, get } from 'lodash'

import { SUBCATEGORY_DISABLE_LIST } from '../features/packaging/constants'
import {
  CATEGORY,
  PACKAGE_TYPE,
  PackagingCreationFlowType,
  PackagingCreationType,
  SUBCATEGORY
} from '../features/packaging/model'

const fieldsInBothFlow = [
  'status',
  'supplierRef',
  'testedSupplier',
  'USDTargetPrice',
  'expiryDate',
  'leadTimeDays',
  'MOQ',
  'roomAllocation',
  'isValidNote'
]

export const checkSearchFormValidation = (formData: Record<string, any>) => {
  const isMulti = get(formData, 'type', '') === PACKAGE_TYPE.MULTI
  const categories = compact(
    get(formData, 'packageFamilies', []).map(({ category }: any) => category)
  )
  if (isMulti) {
    return false
  }
  return (
    !get(formData, '_all', '') &&
    !get(formData, 'packagingCode', '') &&
    !get(formData, 'supplierRef', '') &&
    !get(formData, 'attachmentsSAPCode', '') &&
    !get(formData, 'status', '') &&
    !get(formData, 'testedSupplier.name', '') &&
    !get(formData, 'isValidNote', '') &&
    !get(formData, 'neutroName', '') &&
    !get(formData, 'countryOfOriginCodes', '') &&
    (get(formData, 'targetPriceMin') === null ||
      get(formData, 'targetPriceMin') === undefined) &&
    (get(formData, 'targetPriceMax') === null ||
      get(formData, 'targetPriceMax') === undefined) &&
    !get(formData, 'nullPricesOnly', false) &&
    !get(formData, 'packageFamilies[0].neutroName', []).length &&
    !categories.length
  )
}

export const checkFieldVisibilityByType: (args: {
  key: string
  type: PackagingCreationFlowType
}) => boolean = ({ key, type }) => {
  if (type === 'multi') {
    return fieldsInBothFlow.includes(key)
  }
  return true
}

export const checkFieldVisibilityByCategory: (args: {
  key: string
  category: string
  subcategory: string
}) => boolean = ({ key, category, subcategory }) => {
  switch (key) {
    case 'subcategory':
      return !!category
    case 'technology':
      return (
        (category === CATEGORY.PENCILS &&
          (subcategory === SUBCATEGORY.MARKER ||
            subcategory === SUBCATEGORY.ONLY_PROPEL)) ||
        category === CATEGORY.STICK ||
        category === CATEGORY.OTHERS
      )
    case 'size':
      return (
        category === CATEGORY.AEROSOL ||
        category === CATEGORY.NAILS ||
        category === CATEGORY.PENCILS ||
        category === CATEGORY.STICK ||
        category === CATEGORY.OTHERS ||
        (category === CATEGORY.SQUEEZABLE && subcategory === SUBCATEGORY.TUBES)
      )
    case 'dipstick':
      return category === CATEGORY.VIALS
    case 'applicator':
      return category === CATEGORY.VIALS
    case 'applicatorNumber':
      return category === CATEGORY.VIALS
    case 'wiper':
      return category === CATEGORY.VIALS
    case 'bottle':
      return category === CATEGORY.VIALS
    case 'material':
      return category !== CATEGORY.ACCESSORIES
    case 'capacityRange':
      return (
        category === CATEGORY.JARS_POT ||
        category === CATEGORY.MASK_FABRIC ||
        category === CATEGORY.MONODOSE ||
        category === CATEGORY.PENCILS ||
        category === CATEGORY.PUMPS_DROPPERS ||
        category === CATEGORY.SACHET ||
        category === CATEGORY.VIALS ||
        category === CATEGORY.OTHERS ||
        (category === CATEGORY.SQUEEZABLE && subcategory !== SUBCATEGORY.TUBES)
      )
    case 'shape':
      return (
        category === CATEGORY.COMPACT_PALETTE ||
        category === CATEGORY.JARS_POT ||
        category === CATEGORY.MASK_FABRIC ||
        category === CATEGORY.MONODOSE ||
        category === CATEGORY.NAILS ||
        category === CATEGORY.PUMPS_DROPPERS ||
        category === CATEGORY.SACHET ||
        category === CATEGORY.STICK ||
        category === CATEGORY.SQUEEZABLE ||
        category === CATEGORY.OTHERS ||
        category === CATEGORY.VIALS
      )
    case 'panShape':
      return category === CATEGORY.COMPACT_PALETTE || category === CATEGORY.OTHERS
    case 'panSize':
      return category === CATEGORY.COMPACT_PALETTE || category === CATEGORY.OTHERS
    case 'panNumber':
      return category === CATEGORY.COMPACT_PALETTE || category === CATEGORY.OTHERS
    case 'tipShape':
      return category === CATEGORY.PENCILS
    case 'applicatorAndAccessories':
      return (
        category === CATEGORY.AEROSOL ||
        category === CATEGORY.COMPACT_PALETTE ||
        category === CATEGORY.JARS_POT ||
        category === CATEGORY.MASK_FABRIC ||
        category === CATEGORY.MONODOSE ||
        category === CATEGORY.PENCILS ||
        category === CATEGORY.SACHET ||
        category === CATEGORY.SQUEEZABLE ||
        category === CATEGORY.STICK ||
        category === CATEGORY.VIALS ||
        category === CATEGORY.OTHERS
      )
    case 'closure':
      return (
        category === CATEGORY.AEROSOL ||
        category === CATEGORY.COMPACT_PALETTE ||
        category === CATEGORY.MASK_FABRIC ||
        category === CATEGORY.MONODOSE ||
        category === CATEGORY.PENCILS ||
        category === CATEGORY.SACHET ||
        category === CATEGORY.STICK ||
        category === CATEGORY.SQUEEZABLE ||
        category === CATEGORY.VIALS ||
        category === CATEGORY.OTHERS
      )
    case 'mirror':
      return (
        category === CATEGORY.COMPACT_PALETTE ||
        category === CATEGORY.JARS_POT ||
        category === CATEGORY.OTHERS
      )
    default:
      return true
  }
}

export const checkFieldDisability: (
  key: string,
  category: string,
  subcategory: string
) => boolean = (key, category, _) => {
  switch (key) {
    case 'subcategory':
      return SUBCATEGORY_DISABLE_LIST.includes(category)
    default:
      return false
  }
}

export const singleSideFormValidation = (
  sideFormData: Record<string, any>,
  allTaxonomies: Record<string, any>[]
) => {
  const categoryValue: string = get(sideFormData, 'category', '')
  const isSideVial = categoryValue.toLocaleLowerCase() === PackagingCreationType.VIAL
  const subcategoryValue = get(sideFormData, 'subcategory', '')
  const materialSelected = get(sideFormData, 'material', [])
  const filteredCategory = allTaxonomies.find(
    ({ value: catValue }: any) => catValue === categoryValue
  )
  const subCategoryList = get(filteredCategory, 'children.subcategory', [])
  const catIsOthers = categoryValue.toLowerCase() === CATEGORY.OTHERS
  const isSubCategoryValid =
    (catIsOthers && subcategoryValue) ||
    (subcategoryValue && subCategoryList.length) ||
    !subCategoryList.length

  const vialsHasVariants = !!get(sideFormData, 'packageVariants', []).length

  return (
    ((!categoryValue || !isSubCategoryValid) && !isSideVial) ||
    (isSideVial &&
      (!categoryValue ||
        !isSubCategoryValid ||
        !materialSelected.length ||
        !vialsHasVariants))
  )
}

export const sideFormValidation = (
  formData: Record<string, any>,
  allTaxonomies: Record<string, any>[]
) => {
  const sideValidation = get(formData, 'packageFamilies', []).map(
    (packFamily: Record<string, any>) =>
      singleSideFormValidation(packFamily, allTaxonomies)
  )
  return sideValidation.some((val: boolean) => val)
}

export const refactorSearchPackageFilter = (filters: any) => {
  const { type = '', packageFamilies = [], ...otherFilters } = filters
  const isMonoByDefault = !!get(packageFamilies[0], 'category', '')
  const refactoredPackageFam = packageFamilies.map((value: any) => {
    const isVials = get(value, 'category', '').toLowerCase() === CATEGORY.VIALS
    const newValue = {
      ...value,
      material: isVials
        ? get(value, 'bottle', undefined)
        : get(value, 'material', undefined)
    }
    if (isVials && newValue['bottle']) {
      delete newValue['bottle']
    }
    return newValue
  })
  return {
    ...otherFilters,
    packageFamilies: refactoredPackageFam,
    type: isMonoByDefault ? PACKAGE_TYPE.MONO.toUpperCase() : type.toUpperCase()
  }
}
