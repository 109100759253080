import { get } from 'lodash'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, InfoCell } from '../../../../components'
import { BoxInfo } from '../../../../components/BoxInfo'
import { Divider } from '../../../../components/Divider'
import { FlexSection } from '../../../../components/FlexSection'
import { InputNumber } from '../../../../components/Form/InputNumber'
import { Text } from '../../../../components/Text'
import { cartActions } from '../../../../features/cart'
import { getContentofConditionalProductCodeField } from '../../../../features/cart/utils'
import { StyledCartItemCard, ImageWrapper } from './styled'
import { ICartItemCardProps } from './types'

export const CartItemCard: FC<ICartItemCardProps> = ({
  data,
  isEditable,
  onUpdate = () => {}
}) => {
  const { quantity, item, id: itemId } = data
  const dispatch = useDispatch()
  const conditionalProductCodeField = getContentofConditionalProductCodeField(item)

  const deleteItem = useCallback(() => {
    dispatch(cartActions.removeItemFromCart({ itemId }))
  }, [itemId, dispatch])

  const availability = get(item, 'availableQuantity', 0)
  const productName = get(item, 'productName', '')

  const helperText = !availability
    ? 'product no more available'
    : quantity > availability
    ? `only ${availability} pieces available`
    : ''

  return (
    <>
      <FlexSection flexDirection="row" justifyContent="space-between" alignItems="center">
        <Text text={productName} variant="h4" />
        {isEditable && (
          <Button
            iconName="DeleteOutlined"
            variant="link"
            label="Delete"
            onClick={deleteItem}
          />
        )}
      </FlexSection>
      <Divider />
      <StyledCartItemCard>
        <ImageWrapper>
          <InfoCell
            value={get(item, 'thumbnail.url', '')}
            label={`product-${data?.productCode || ''}`}
            dataType="image"
            inputConfig={{
              isUrl: true
            }}
          />
        </ImageWrapper>
        <FlexSection flexDirection="column">
          <FlexSection flexDirection="row" alignItems="end">
            {!isEditable ? (
              <BoxInfo label="Quantity" value={quantity} />
            ) : (
              <InputNumber
                value={quantity as number}
                minNumber={1}
                maxNumber={availability}
                onChange={(value) => onUpdate('quantity', value)}
                helperText={helperText}
                errorMessage={`The maximum available quantity is ${availability}`}
              />
            )}
          </FlexSection>
          <FlexSection flexDirection="row">
            <FlexSection flexDirection="column">
              <BoxInfo label={'Final Name'} value={get(item, 'finalName')} />
            </FlexSection>
            <FlexSection flexDirection="column">
              <BoxInfo
                label={conditionalProductCodeField.label}
                value={conditionalProductCodeField.value}
              />
            </FlexSection>
            <FlexSection flexDirection="column">
              <BoxInfo label={'PK Intercos Code'} value={get(item, 'packagingRef')} />
            </FlexSection>
          </FlexSection>
        </FlexSection>
      </StyledCartItemCard>
    </>
  )
}
