import { apiClient } from '@/api/client'

import { IOrderData, IOrdersResultSearch, IReasonApiData, TOrderStatus } from './models'

export const searchOrders = (data: any) => {
  return apiClient.post<IOrdersResultSearch>('order/search', data)
}

export const getOrderListTaxonomies = () => {
  return apiClient.get<Record<string, any>>('order/taxonomies')
}

export const getOrder = (orderId: string) => {
  return apiClient.get<IOrderData>(`order/${orderId}`)
}
export const fetchLatestOrder = (orderStatus: TOrderStatus) => {
  return apiClient.get<IOrderData>(`order/last/${orderStatus}`)
}

export const rejectOrder = (orderId: string, data: IReasonApiData) => {
  return apiClient.patch<Record<string, any>>(`order/refuse/${orderId}`, data)
}

export const acceptOrder = (orderId: string, acceptNote?: string) => {
  return apiClient.patch<Record<string, any>>(`order/accept/${orderId}`, { acceptNote })
}

export const processOrder = (orderId: string, processNote?: string) => {
  return apiClient.patch<Record<string, any>>(`order/process/${orderId}`, { processNote })
}

export const proposeOrder = (orderId: string, data: IOrderData) => {
  return apiClient.patch<Record<string, any>>(`order/propose/${orderId}`, data)
}
