import { Form } from 'antd'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { selectGroupNamesDropdown } from '../../../features/administration/selectors'
import { ISupplierFormProps } from './types'

const SupplierForm: FC<ISupplierFormProps> = ({
  isOnCreate = false,
  data,
  onUpdate,
  hasSapCode = false
}) => {
  const updateCountyHandler = (value: string) => {
    if (value.length < 3) {
      onUpdate('country', value)
    }
  }

  const groupNameDropDownItems = useSelector(selectGroupNamesDropdown)

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        {hasSapCode && (
          <InfoCell
            label="SAPCode"
            isEditing
            value={data?.SAPCodes || ''}
            inputConfig={{
              placeholder: '',
              onChange: (value: string) => onUpdate('SAPCodes', value)
            }}
          />
        )}
        <InfoCell
          label="Name"
          isEditing
          value={data?.name || ''}
          inputConfig={{
            placeholder: '',
            onChange: (value: string) => onUpdate('name', value)
          }}
        />
        <InfoCell
          label="Locality"
          isEditing
          value={data?.locality || ''}
          inputConfig={{
            placeholder: '',
            onChange: (value: string) => onUpdate('locality', value)
          }}
        />
        <InfoCell
          label="Country"
          isEditing
          value={data?.country || ''}
          inputConfig={{
            placeholder: 'Max 2 characters',
            error: !!data?.country && data?.country.length < 2,
            onChange: updateCountyHandler
          }}
        />
        {isOnCreate ? (
          <InfoCell
            label="Group Supplier Name"
            isEditing
            value={data?.groupName || ''}
            inputConfig={{
              placeholder: '',
              error: !!data?.country && data?.country.length < 2,
              onChange: (value: string) => onUpdate('groupName', value)
            }}
          />
        ) : (
          <InfoCell
            label="Group Supplier Name"
            value={data?.groupName || ''}
            isEditing
            inputType="select"
            inputConfig={{
              items: groupNameDropDownItems,
              onChange: ({ value }: Record<string, any>) => {
                return onUpdate('groupName', value)
              },
              orderItems: true,
              orderKey: 'name'
            }}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default SupplierForm
