import { TablePaginationConfig } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Modal } from '../../../components/Modal'
import { appActions } from '../../../features/app'
import { fSchemaActions, fSchemaSliceActions } from '../../../features/formulaSchema'
import {
  getItemsSchema,
  getSchemaFilters,
  getSchemaTempFilters,
  selectFormulaSchemaPagination,
  selectFSchemaIsLoading
} from '../../../features/formulaSchema/selectors'
import FormulaSchemaTable from '../../FormulaSchemaTable'
import { FSCHEMA_TABLE_CONFIG } from '../../FormulaSchemaTable/constants'
import { TagsContainer } from '../../TagsContainer'
import { FSchemaSearchForm } from '../FSchemaSearchForm'
import { TagsWrapper } from './styled'
import { IFSchemaResultSectionProps } from './types'

const FSchemaResultSection: FC<IFSchemaResultSectionProps> = ({
  hasTitle = false,
  hasFilterBtn = false,
  hasAction = false,
  enableSelect = false,
  selectedRows,
  isSingleSelect = false,
  onSelect
}) => {
  const dispatch = useDispatch()
  const modalKey = 'modal-schema'
  const filters = useSelector(getSchemaFilters)
  const tempFilters = useSelector(getSchemaTempFilters)
  const fSchemaList = useSelector(getItemsSchema)

  const isLoading = useSelector(selectFSchemaIsLoading)
  const { page, pageSize, total } = useSelector(selectFormulaSchemaPagination)

  const changePageHandler = (pageData: TablePaginationConfig) => {
    dispatch(
      fSchemaSliceActions.setPagination({
        page: get(pageData, 'current', 1),
        pageSize: get(pageData, 'pageSize', 10),
        total: get(pageData, 'total', 10)
      })
    )
    dispatch(fSchemaActions.getFormulaSchema())
  }

  const checkIfDisabled = () =>
    Object.values(tempFilters).every(
      (value) => value === null || value === '' || value === undefined
    )
  const searchHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    dispatch(fSchemaSliceActions.applyFilters(tempFilters))
    dispatch(fSchemaActions.getFormulaSchema())
  }

  const removeTagHandler = (path: string) => {
    dispatch(fSchemaSliceActions.cancelFilter({ path }))
    dispatch(fSchemaActions.getFormulaSchema())
  }
  return (
    <>
      {hasTitle && (
        <>
          <SectionTitle value="Formula Schema" isBold />
          <Divider />
        </>
      )}
      <FlexSection flexDirection="row">
        {hasFilterBtn && (
          <Modal
            btnName="Filter"
            title="Filter Formula Schema"
            btnClicked={() => dispatch(fSchemaSliceActions.filterToTemp())}
            modalKey={modalKey}
            size="large"
            footer={[
              <Button
                label="Search"
                onClick={searchHandler}
                enableKeyPress
                disabled={checkIfDisabled()}
              />
            ]}
          >
            <FSchemaSearchForm />
          </Modal>
        )}
        <TagsWrapper>
          {!isEmpty(filters) && (
            <TagsContainer
              tags={filters}
              inner
              reducer={'formulaschema'}
              flowName={'formulaschema'}
              onRemoveTag={removeTagHandler}
              basePath={'filters'}
            />
          )}
        </TagsWrapper>
      </FlexSection>
      <FormulaSchemaTable
        enablePagination
        onChangePage={changePageHandler}
        pageData={{ page, pageSize, total }}
        isLoading={isLoading}
        hasRedirect={hasAction}
        dataList={fSchemaList}
        tableConfig={FSCHEMA_TABLE_CONFIG}
        enableSelect={enableSelect}
        selectedRows={selectedRows}
        isSingleSelect={isSingleSelect}
        onSelect={onSelect}
      />
    </>
  )
}

export default FSchemaResultSection
