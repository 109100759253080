import { FC } from 'react'
import { useSelector } from 'react-redux'

import Table from '../../components/Table'
import { selectUserRole } from '../../features/users/selectors'
import { checkFieldVisibilityByRoles, columnsGeneratorHelper } from '../../utils'
import { rowsGeneratorHelper } from '../../utils/tableHelpers'
import { IVariantsTableProps } from './types'

const VariantsTable: FC<IVariantsTableProps> = ({
  dataList,
  isEditing = false,
  isCreating = false,
  emptyState,
  actionConfig,
  tableConfig,
  enableSelect = false,
  selectedRows = [],
  onSelect,
  isSingleSelect = false
}) => {
  const userRoles = useSelector(selectUserRole)

  // NOTE this is temporary solution - need to define better
  const hideVariantCode = () => {
    return checkFieldVisibilityByRoles({
      key: 'variantSupplierCode',
      isOnCreate: isCreating && isEditing,
      isOnEdit: !isCreating && isEditing,
      isOnRead: !isCreating && !isEditing,
      roles: userRoles
    })
  }

  const columnGenerator = () => {
    const columnList = columnsGeneratorHelper(tableConfig)
    const test = columnList.map((col: any) => {
      const { key } = col
      const isHide = hideVariantCode()
      if (key === 'variantSupplierCode' && !isHide) {
        col['hide'] = true
      }
      return col
    })
    return test
  }

  const rowsGenerator = () => rowsGeneratorHelper(dataList, tableConfig)

  const columns = columnGenerator()
  const dataRows = dataList.length ? rowsGenerator() : []

  return (
    <Table
      rowKey="id"
      columns={columns}
      items={dataRows}
      actionColumnConfig={actionConfig}
      emptyBody={isEditing && emptyState}
      enableRowSelect={enableSelect}
      selectedRows={selectedRows}
      onSelectRow={onSelect}
      isSingleSelect={isSingleSelect}
    />
  )
}

export default VariantsTable
