import { Divider, Form } from 'antd'
import { get, pickBy } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InfoCell, SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { threeOptions } from '../../../features/constants'
import { fSchemaSliceActions } from '../../../features/formulaSchema'
import {
  getSchemaTempFilters,
  selectFormulaSchemaCollection,
  selectFormulaSchemaDevexApplicationArea,
  selectFormulaSchemaFinish,
  selectFormulaSchemaLaunchYear,
  selectIndustrialCodeShelfLife
} from '../../../features/formulaSchema/selectors'
import { IFSchemaSearchFormProps } from './types'

export const FSchemaSearchForm: FC<IFSchemaSearchFormProps> = () => {
  const dispatch = useDispatch()
  const tempFilters = useSelector(getSchemaTempFilters)
  const launchYearDropdown = useSelector(selectFormulaSchemaLaunchYear)
  const collectionDropdown = useSelector(selectFormulaSchemaCollection)
  const fsFinishDropdown = useSelector(selectFormulaSchemaFinish)
  const icShelfLifeDropdown = useSelector(selectIndustrialCodeShelfLife)
  const devexApplicationAreaDropdown = useSelector(
    selectFormulaSchemaDevexApplicationArea
  )

  const updateHandler = (key: string, value: any) => {
    const newTempFilters = {
      ...tempFilters,
      [key]: value
    }
    const cleanTempFilters = pickBy(newTempFilters, (value: any) => value !== '')

    dispatch(fSchemaSliceActions.updateTempFilters(cleanTempFilters))
  }

  return (
    <>
      <Form layout="vertical">
        <SectionTitle value="Formula Schema" isBold />
        <Divider />
        <BoxWrapper enablePadding>
          <InfoCell
            label="Formula Schema Code"
            value={get(tempFilters, 'formulaSchemaCode', '')}
            inputConfig={{
              onChange: (value: any) => updateHandler('formulaSchemaCode', value),
              placeholder: 'Formula Schema Code'
            }}
            isEditing
          />
          <InfoCell
            label="Formula Schema Name"
            value={get(tempFilters, 'name', '')}
            inputConfig={{
              onChange: (value: any) => updateHandler('name', value),
              placeholder: 'Formula Schema Name'
            }}
            isEditing
          />
          <InfoCell
            label="FS Launch Year"
            value={get(tempFilters, 'launchYear', '')}
            inputType="select"
            inputConfig={{
              withLookup: true,
              items: launchYearDropdown,
              onChange: ({ value }: any) => updateHandler('launchYear', value)
            }}
            isEditing
          />
          <InfoCell
            label="Industrial Code"
            value={get(tempFilters, 'industrialCode', '')}
            inputConfig={{
              onChange: (value: any) => updateHandler('industrialCode', value),
              placeholder: 'Industrial Code'
            }}
            isEditing
          />
          <InfoCell
            label="Lab Reference Code"
            value={get(tempFilters, 'labReferenceCode', '')}
            inputConfig={{
              onChange: (value: any) => updateHandler('labReferenceCode', value),
              placeholder: 'Lab Reference Code'
            }}
            isEditing
          />
          <InfoCell
            label="FS Collection"
            value={get(tempFilters, 'collection', '')}
            inputType="select"
            inputConfig={{
              withLookup: true,
              items: collectionDropdown,
              onChange: ({ value }: any) => updateHandler('collection', value)
            }}
            isEditing
          />
          <InfoCell
            label="FS Finish"
            value={get(tempFilters, 'fsFinish', '')}
            inputType="select"
            inputConfig={{
              withLookup: true,
              items: fsFinishDropdown,
              onChange: ({ value }: any) => updateHandler('fsFinish', value)
            }}
            isEditing
          />
          <InfoCell
            label="DevEx Application Area"
            value={get(tempFilters, 'devexApplicationArea', '')}
            inputType="select"
            inputConfig={{
              withLookup: true,
              items: devexApplicationAreaDropdown,
              onChange: ({ value }: any) => updateHandler('devexApplicationArea', value)
            }}
            isEditing
          />
          <InfoCell
            label="IC PAO"
            value={get(tempFilters, 'icPao', '')}
            inputConfig={{
              onChange: (value: any) => updateHandler('icPao', value)
            }}
            isEditing
          />
          <InfoCell
            label="IC Shelf Life"
            value={get(tempFilters, 'icShelfLife', '')}
            inputType="select"
            inputConfig={{
              withLookup: true,
              items: icShelfLifeDropdown,
              onChange: ({ value }: any) => updateHandler('icShelfLife', value)
            }}
            isEditing
          />
          <InfoCell
            label="FS SPF"
            dataType="options-value"
            value={get(tempFilters, 'fsSpf', null)}
            inputType="options-button"
            isEditing
            inputConfig={{
              options: threeOptions,
              onChange: (value: any) => updateHandler('fsSpf', value)
            }}
          />
          <InfoCell
            label="OTC"
            dataType="options-value"
            value={get(tempFilters, 'otc', null)}
            inputType="options-button"
            isEditing
            inputConfig={{
              options: threeOptions,
              onChange: (value: any) => updateHandler('otc', value)
            }}
          />
        </BoxWrapper>
      </Form>
    </>
  )
}
