import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import { ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG } from '../../containers/AttachmentsTable/constants'
import { MasterSpecificationsList } from '../../containers/Packaging/MasterSpecificationsList'
import { masterSpecActions } from '../../features/masterSpec/actions'
import {
  selectIsLoading,
  selectMasterSpecList
} from '../../features/masterSpec/selectors'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import {
  getPackageDetail,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'

export const MasterSpecificationsResult: FC = () => {
  const { packageId, packageVariantId } = useParams()
  const dispatch = useDispatch()

  const packagingError = useSelector(selectPackagingError)
  const dataList = useSelector(selectMasterSpecList)
  const userRoles = useSelector(selectUserRole)
  const packagingData = useSelector(getPackageDetail) as Record<string, any>
  const isLoading = useSelector(selectIsLoading)
  const sideCollectorId = packagingData?.id
  const category = get(packagingData, 'category', '')

  useEffect(() => {
    dispatch(
      masterSpecActions.getList({
        filters: !packageVariantId
          ? { sideCollectorId, category: get(packagingData, 'category', '') }
          : { packageVariantId, category: get(packagingData, 'category', '') },
        isAddNew: false,
        category: category
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    packageId &&
      dispatch(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    dispatch(
      masterSpecActions.getList({
        filters: !packageVariantId
          ? { sideCollectorId, category: get(packagingData, 'category', '') }
          : { packageVariantId, category: get(packagingData, 'category', '') },
        isAddNew: false
      })
    )
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideCollectorId])

  const hrefPrefix = '/wikipack/packaging'
  const breadcrumbName = `detail packaging ${packagingData?.packagingCode}`
  const breadcrumbs = [
    { name: 'Packaging', href: hrefPrefix },
    { name: breadcrumbName, href: `${hrefPrefix}/${packageId}` },
    { name: 'master specifications' }
  ]

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb items={breadcrumbs} />
            <Divider disableBorder />
            <Divider disableBorder />
            <MasterSpecificationsList
              packageId={packageId}
              dataList={dataList}
              tableConfig={ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG}
            />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}
