import { FC, useEffect } from 'react'

import { Form } from 'antd'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { canUserSeeFschemaData } from '@/utils/permissionsHelpers'

import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import StatusTooltip from '../../../components/StatusTooltip'
import { dropdownify } from '../../../components/TestedClaim/TestedClaimForm/utils'
import { selectModalKey } from '../../../features/app/selectors'
import { threeOptions } from '../../../features/constants'
import {
  selectFormulaSchemaDropdown,
  selectTestedClaimTaxonomy
} from '../../../features/formulaSchema/selectors'
import { prodActions } from '../../../features/product'
import { DYNAMIC_TAXONOMIES } from '../../../features/product/constants'
import { IProdStatus } from '../../../features/product/model'
import {
  getDropDownsByKey,
  getProdFilteredStatusByCRUD
} from '../../../features/product/selectors'
import { productsSliceActions } from '../../../features/products'
import { selectTempFilter } from '../../../features/products/selectors'
import { selectUserRole } from '../../../features/users/selectors'

import { ISearchFormProps } from './types'

const SearchForm: FC<ISearchFormProps> = () => {
  const dispatch = useDispatch()
  const tempFilter = useSelector(selectTempFilter)
  const productStatus = useSelector(getProdFilteredStatusByCRUD('onEdit'))
  const businessUnitDropdown = useSelector(getDropDownsByKey('businessUnit'))
  const businessSegmentDropdown = useSelector(getDropDownsByKey('businessSegment'))
  const applicationAreaDropdown = useSelector(getDropDownsByKey('applicationArea'))
  const productCategoryDropdown = useSelector(getDropDownsByKey('productCategory'))
  const productFormDropdown = useSelector(getDropDownsByKey('productForm'))
  const launchYearDropdown = useSelector(getDropDownsByKey('pdLaunchYear'))
  const collectionDropdown = useSelector(getDropDownsByKey('pdCollection'))
  const packageTypeDropdown = useSelector(getDropDownsByKey('packageType'))
  const formatDropdown = useSelector(getDropDownsByKey('format'))
  const fsDropdown = useSelector(selectFormulaSchemaDropdown)

  // Add NULL on selects
  const lvmhBLComplianceDropdown = get(fsDropdown, 'lvmhBLCompliance', []).slice()
  lvmhBLComplianceDropdown.push({ name: 'Null', value: null })

  const GMODropdown = get(fsDropdown, 'GMO', []).slice()
  GMODropdown.push({ name: 'Null', value: null })

  const currentModalKey = useSelector(selectModalKey)
  const userRoles = useSelector(selectUserRole)

  const claimTaxonomy = useSelector(selectTestedClaimTaxonomy)
  const claimDropdown = dropdownify(claimTaxonomy?.claim || [])

  const canSeeFschemaFilter =
    canUserSeeFschemaData({ userRoles }) ||
    get(tempFilter, 'status', '') !== 'LEVEL_2_IN_PROGRESS'

  const cleanSelectorFilters = () => {
    updateHandler('businessUnit', [])
    updateHandler('businessSegment', [])
    updateHandler('applicationArea', [])
    updateHandler('productCategory', [])
    updateHandler('productForm', [])
    updateHandler('format', [])
    updateHandler('launchYear', [])
    updateHandler('collection', [])
  }

  const updateHandler = (key: string, value: any) => {
    dispatch(productsSliceActions.updateTempFilter({ key, value }))
    if (!value) return
    if (DYNAMIC_TAXONOMIES.includes(key)) {
      const newFilters = {
        ...tempFilter,
        [key]: value
      }
      dispatch(prodActions.filterTaxonomies({ filters: newFilters }))
    }
  }

  const updateHandlerWithNull = (key: string, value: any) => {
    dispatch(productsSliceActions.updateTempFilter({ key, value }))
    if (DYNAMIC_TAXONOMIES.includes(key)) {
      const newFilters = {
        ...tempFilter,
        [key]: value
      }
      dispatch(prodActions.filterTaxonomies({ filters: newFilters }))
    }
  }

  const updateMultiSelect = (key: string, inputValue: Record<string, any>) => {
    const newValue = inputValue.map(({ value }: any) => value)
    updateHandler(key, newValue)
  }

  useEffect(() => {
    return () => {
      if (currentModalKey !== 'products-filter') {
        cleanSelectorFilters()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE -  this condizion is temporary hidden at the moment
  // in DB there is only one product status
  // const hasOnlyOne = productStatus.length === 1
  const hasOnlyOne = false

  const prodStatus = get(tempFilter, 'status', '')

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          value={get(tempFilter, 'industrialCode', '')}
          label="Industrial Code"
          isEditing
          inputConfig={{
            placeholder: 'Industrial Code',
            onChange: (value: string) => updateHandler('industrialCode', value)
          }}
        />
        {canSeeFschemaFilter && (
          <InfoCell
            value={get(tempFilter, 'formulaSchemaCode', '')}
            label="Formula Schema Code"
            isEditing
            inputConfig={{
              placeholder: 'Formula Schema Code',
              onChange: (value: string) => updateHandler('formulaSchemaCode', value)
            }}
          />
        )}
        <InfoCell
          value={get(tempFilter, 'name', '')}
          label="Final Name"
          isEditing
          inputConfig={{
            placeholder: 'Final Name',
            onChange: (value: string) => updateHandler('name', value)
          }}
          tooltipConfig={{
            enable: true,
            message:
              'Final name selected after the development of the formula - different than the working name'
          }}
        />

        <InfoCell
          value={get(tempFilter, 'packagingCode', '')}
          label={'PK Intercos Code'}
          isEditing
          inputConfig={{
            placeholder: 'PK Intercos Code',
            onChange: (value: string) => updateHandler('packagingCode', value)
          }}
        />
        <InfoCell
          value={get(tempFilter, 'productCode', '')}
          label="Product Code"
          isEditing
          inputConfig={{
            placeholder: 'Product Code',
            onChange: (value: string) => updateHandler('productCode', value)
          }}
        />
        {!hasOnlyOne && (
          <InfoCell
            label="Product Status"
            value={prodStatus}
            inputType="select"
            isEditing
            tooltipConfig={{
              enable: !!productStatus.length,
              message: <StatusTooltip statusList={productStatus as IProdStatus[]} />
            }}
            inputConfig={{
              items: productStatus,
              onChange: ({ value }: Record<string, any>) => {
                if (!canUserSeeFschemaData({ userRoles }))
                  updateHandler('formulaSchemaCode', undefined)
                updateHandler('status', value)
              }
            }}
          />
        )}
        <InfoCell
          value={get(tempFilter, 'businessUnit', [])}
          label="Business Unit"
          isEditing
          inputType="select"
          inputConfig={{
            items: businessUnitDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('businessUnit', value),
            mode: 'multiple'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'businessSegment', [])}
          label="Business Segment"
          isEditing
          inputType="select"
          inputConfig={{
            items: businessSegmentDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('businessSegment', value),
            mode: 'multiple'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'applicationArea', [])}
          label="Application Area"
          isEditing
          inputType="select"
          inputConfig={{
            items: applicationAreaDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('applicationArea', value),
            mode: 'multiple'
          }}
          tooltipConfig={{
            enable: true,
            message:
              'Section refers to the different areas where a product is used and applied'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'productCategory', [])}
          label="Product Category"
          isEditing
          inputType="select"
          inputConfig={{
            items: productCategoryDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('productCategory', value),
            mode: 'multiple'
          }}
          tooltipConfig={{
            enable: true,
            message:
              'Section refers to a specific group of products grouped by specific features and/or elements'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'productForm', [])}
          label="Product Form"
          isEditing
          inputType="select"
          inputConfig={{
            items: productFormDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('productForm', value),
            mode: 'multiple'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'packageType', '')}
          label="Packaging Type"
          isEditing
          inputType="select"
          inputConfig={{
            items: packageTypeDropdown,
            onChange: ({ value }: Record<string, any>) =>
              updateHandler('packageType', value)
          }}
        />
        <InfoCell
          value={get(tempFilter, 'format', [])}
          label="Packaging Category"
          isEditing
          inputType="select"
          inputConfig={{
            items: formatDropdown,
            onChange: (value: Record<string, any>) => updateMultiSelect('format', value),
            mode: 'multiple'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'launchYear', [])}
          label="PD Launch Year"
          isEditing
          inputType="select"
          inputConfig={{
            items: launchYearDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('launchYear', value),
            mode: 'multiple'
          }}
          tooltipConfig={{
            enable: true,
            message: 'Year when the product has been presented to the clients'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'collection', [])}
          label="PD Collection"
          isEditing
          inputType="select"
          inputConfig={{
            items: collectionDropdown,
            onChange: (value: Record<string, any>) =>
              updateMultiSelect('collection', value),
            mode: 'multiple'
          }}
          tooltipConfig={{
            enable: true,
            message: 'Specific collection where the product has been presented'
          }}
        />
        <InfoCell
          value={get(tempFilter, 'sapOfferCode', '')}
          label="Sap Offer Code"
          isEditing
          inputConfig={{
            placeholder: 'Sap Offer Code',
            onChange: (value: string) => updateHandler('sapOfferCode', value)
          }}
        />

        <InfoCell
          label="Claim"
          value={get(tempFilter, 'claim', '')}
          inputType="select"
          isEditing
          inputConfig={{
            items: claimDropdown,
            withLookup: true,
            onChange: ({ value }: Record<string, any>) => updateHandler('claim', value)
          }}
        />
      </BoxWrapper>
      <br />
      <Divider />
      <BoxWrapper enablePadding>
        <InfoCell
          label="SPF DevEx"
          dataType="options-value"
          value={get(tempFilter, 'fsSpf', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('fsSpf', value)
          }}
        />
        <InfoCell
          label="Clean At Sephora"
          dataType="options-value"
          value={get(tempFilter, 'cleanAtSephora', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('cleanAtSephora', value)
          }}
        />
        <InfoCell
          label="Clean Of Microplastic"
          dataType="options-value"
          value={get(tempFilter, 'microplasticFree', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('microplasticFree', value)
          }}
        />
        <InfoCell
          label="Clean Of Silicones"
          dataType="options-value"
          value={get(tempFilter, 'siliconesFree', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('siliconesFree', value)
          }}
        />
        <InfoCell
          label="Clean Of Talc"
          dataType="options-value"
          value={get(tempFilter, 'talcFree', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('talcFree', value)
          }}
        />
        <InfoCell
          label="Clean Of PEG"
          dataType="options-value"
          value={get(tempFilter, 'pegFree', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('pegFree', value)
          }}
        />
        <InfoCell
          label="LVMH BL Compliance"
          dataType="options-value"
          value={get(tempFilter, 'lvmhBLCompliance', null) || 'Null'}
          inputType="select"
          isEditing
          inputConfig={{
            items: lvmhBLComplianceDropdown,
            onChange: ({ value }: Record<string, any>) =>
              updateHandlerWithNull('lvmhBLCompliance', value)
          }}
        />
        <InfoCell
          label="Clean Of Preservatives"
          dataType="options-value"
          value={get(tempFilter, 'cleanOfPreservatives', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('cleanOfPreservatives', value)
          }}
        />
        <InfoCell
          label="Clean Of Mineral Oils"
          dataType="options-value"
          value={get(tempFilter, 'cleanOfMineralOils', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('cleanOfMineralOils', value)
          }}
        />
        <InfoCell
          label="Clean Of Silica"
          dataType="options-value"
          value={get(tempFilter, 'silicaFree', null)}
          inputType="options-button"
          isEditing
          inputConfig={{
            options: threeOptions,
            onChange: (value: string) => updateHandler('silicaFree', value)
          }}
        />
        <InfoCell
          label="Clean Of GMO"
          dataType="options-value"
          value={get(tempFilter, 'GMO', null) || 'Null'}
          inputType="select"
          isEditing
          inputConfig={{
            items: GMODropdown,
            onChange: ({ value }: Record<string, any>) =>
              updateHandlerWithNull('GMO', value)
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default SearchForm
