import { IError } from '../../containers/Error/types'
import { TAppLoaders } from '../app/model'
import { Pagination } from '../formulaSchema/model'
import { IDropdown } from '../types'

export interface ITypeToProdForm {
  [key: string]: string
}

const storeProdTypes = [
  'PRODUCT_WITH_PACKAGE_STANDARD', // Prodotto con package standard
  'PRODUCT_WITH_MULTIPACKAGE', // Prodotto con package multipackage
  'MULTIBULK_WITH_PACKAGE', // Prodotto Multibulk;
  'PALETTE', // Palette
  'PACKAGE', // Packaging (package vuoti)
  'BULK_WITHOUT_PACKAGE', // Bulk senza package;
  'MULTIBULK_WITHOUT_PACKAGE' // Multibulk senza package
] as const

export type StoreProdType = (typeof storeProdTypes)[number]

const productForms = [
  'MULTIPACK',
  'MULTIBULK',
  'PALETTE',
  'PACKAGE',
  'BPANMOLD',
  'MBPANMOLD',
  'BAKED',
  'BAKED EXTRUDED',
  'COMPACT POWDER',
  'EMULSION',
  'EXTRUDED',
  'FLUID ANHYDROUS',
  'INK',
  'KIT',
  'LOOSE',
  'MATRIX POWDER',
  'OTHER FORMS',
  'POURED ANHYDROUS',
  'POURED COMPACT ANHYDROUS',
  'PRISMA',
  'SLURRY INJECTION',
  'SOLID EMULSION',
  'SOLID GEL'
] as const

export type ProductFormType = (typeof productForms)[number]

export const RProductForms: ReadonlyArray<string> = productForms

export interface IStoreProdChild {
  id?: string
  FPO?: string
  SPF?: string
  airtightPackaging?: boolean
  applicationArea?: string
  availableQuantity?: number
  businessSegment?: string
  businessUnit?: string
  collection?: string
  color?: string
  colorNameOnLabel?: string
  createdAt?: string
  createdBy?: string
  deletedAt?: string
  deletetBy?: string
  family?: string
  finalName?: string
  finish?: string
  formulaSchemaCode?: string
  grid?: string
  groupCode?: string
  industrialCode?: string
  isDeleted?: boolean
  labCode?: string
  labCodeInLabel?: string
  launchYear?: string
  note?: string
  noteBU?: string
  opacPackaging?: string
  packagingRef?: string
  panMold?: string
  pmBU?: string
  productCategory?: string
  productCode?: string
  productForm?: string
  shakeWell?: string
  sharedCode?: string
  specialEffect?: boolean
  thumbnailId?: string
  updatedAt?: string
  updatedBy?: string
  noteSR?: string
}

export interface IThumbnail {
  id?: string
  idDbPackage?: string
  url?: string
  name?: string
  description?: string
  version?: number
  dataType?: string
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
  thumbnailId?: string
}

export interface IImageData {
  name?: string
  size?: number
  type?: string
  uid?: string
  value?: string
  createdAt?: string
}

export interface IStoreProd {
  type?: StoreProdType
  id?: string
  SPF?: string
  airtightPackaging?: boolean
  applicationArea?: string
  availableQuantity?: number
  businessSegment?: string
  businessUnit?: string
  children?: IStoreProdChild[]
  collection?: string
  color?: string
  colorNameOnLabel?: string
  createdAt?: string
  createdBy?: string
  deletedAt?: string
  deletetBy?: string
  family?: string
  finalName?: string
  finish?: string
  formulaSchemaCode?: string
  grid?: string
  groupCode?: string
  industrialCode?: string
  isDeleted?: boolean
  labCode?: string
  labCodeInLabel?: string
  launchYear?: string
  note?: string
  noteSR?: string
  noteBU?: string
  opacPackaging?: string
  packagingRef?: string
  panMold?: string
  pmBU?: string
  productCategory?: string
  productCode?: string
  productForm?: ProductFormType
  shakeWell?: string
  sharedCode?: string
  specialEffect?: boolean
  thumbnailId?: string
  updatedAt?: string
  updatedBy?: string
  image?: IImageData
  thumbnail?: IThumbnail
  FPO?: string
  formulaSchemaId?: string
  isFormulaSchemaConsistent?: boolean
}

export interface IStoreProdDropdowns {
  type?: IDropdown[]
  productForm?: IDropdown[]
  collection?: IDropdown[]
  applicationArea?: IDropdown[]
  color?: IDropdown[]
  productCategory?: IDropdown[]
  family?: IDropdown[]
  launchYear?: IDropdown[]
  finish?: IDropdown[]
  FPO?: IDropdown[]
}
export interface ISuccessInfo {
  title?: string
  message?: string
}

export interface IStoreProdFilters {
  productCode?: string
  productForm?: string
  finalName?: string
  labCode?: string
  groupCode?: string
  color?: string
  finish?: string
  applicationArea?: string
  packagingRef?: string
  formulaSchemaCode?: string
  industrialCode?: string
  launchYear?: string
  collection?: string
  family?: string
  productCategory?: string[]
}

export type StoreProductsState = {
  new: IStoreProd
  dropDown: IStoreProdDropdowns
  items: IStoreProd[]
  itemsByIds: Record<string, any>
  detail?: IStoreProd
  childDetail?: IStoreProdChild
  newChild: IStoreProdChild
  temp?: IStoreProd | {}
  tempFilters: IStoreProdFilters
  filters: IStoreProdFilters
  pagination: Pagination
  hasActionsAddon: boolean
  isLoading: boolean
  error: IError
  successInfo?: ISuccessInfo
}

export type OrderableItem = {
  applicationArea: string
  availability: Record<string, any> | null
  collection: string
  color: string
  family: string
  finalCode: string
  finish: string
  formulaSchemaCode: string
  id: string
  itemCode: string
  labCode: string
  launchYear: string
  packagingRef: string
  packagingSupplier: string
  panMold: string
  productCategory: string
  productName: string
  specialEffect: boolean
  usage: string
  workingName: string
}

export interface IOrderItemResult {
  results: OrderableItem[]
  page: number
  pageSize: number
  totalPages: number
  total: number
}

export interface ICheckItemAvailabilityByIdPayload {
  id: string
}

export interface IGetStoreProdDynamicFiltersPayload {
  applicationArea?: string
  productCategory?: string[]
  productForm?: string
}

export interface IStoreFilterTaxonomiesPayload {
  filters: IStoreProdFilters
  modalType?: TAppLoaders
}
