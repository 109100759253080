import { Divider } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../../components'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Text } from '../../../../components/Text'
import { selectAllTaxonomies } from '../../../../features/packaging/selectors'
import { selectUserRole } from '../../../../features/users/selectors'
import {
  checkFieldVisibilityByRoles,
  getDynamicPackDropdownByKey
} from '../../../../utils'
import { IVariantInfoProps } from './types'

const VariantInfo: FC<IVariantInfoProps> = ({
  data,
  onUpdate = () => {},
  isEditing = false,
  isCreating = false,
  bottleValues = [],
  category,
  subcategory
}) => {
  const allTaxonomies = useSelector(selectAllTaxonomies)
  const userRoles = useSelector(selectUserRole)

  const getDropDown = (key: string) => {
    return getDynamicPackDropdownByKey({
      key,
      category,
      subcategory,
      taxonomies: allTaxonomies
    })
  }

  const hasVariantSupplierCode = checkFieldVisibilityByRoles({
    key: 'variantSupplierCode',
    isOnCreate: isCreating && isEditing,
    isOnEdit: !isCreating && isEditing,
    isOnRead: !isCreating && !isEditing,
    roles: userRoles
  })

  return (
    <>
      <Text text="Information" variant="h3" />
      <Divider />
      <BoxWrapper sm={2} md={2} lg={2} enablePadding>
        {hasVariantSupplierCode && (
          <InfoCell
            label="Variant Supplier Code"
            value={get(data, 'variantSupplierCode', '')}
            isEditing={isEditing}
            inputConfig={{
              onChange: (value: Record<string, any>) =>
                onUpdate('variantSupplierCode', value)
            }}
          />
        )}
        <InfoCell
          label="Bottle"
          value={bottleValues}
          inputType="select"
          inputConfig={{
            mode: 'multiple',
            disabled: true,
            onChange: () => {}
          }}
          isEditing={isEditing}
        />
      </BoxWrapper>
      <BoxWrapper sm={2} md={2} lg={2} enablePadding>
        <InfoCell
          label="Stem"
          value={get(data, 'dipstick', '')}
          inputType="select"
          inputConfig={{
            items: getDropDown('dipstick'),
            onChange: ({ value }: Record<string, any>) => onUpdate('dipstick', value),
            orderItems: true,
            orderKey: 'name'
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Wiper"
          value={get(data, 'wiper', '')}
          inputType="select"
          inputConfig={{
            items: getDropDown('wiper'),
            onChange: ({ value }: Record<string, any>) => onUpdate('wiper', value),
            orderItems: true,
            orderKey: 'name'
          }}
          isEditing={isEditing}
        />
      </BoxWrapper>
      <BoxWrapper sm={2} md={2} lg={2} enablePadding>
        <InfoCell
          label="Applicator"
          value={get(data, 'applicator', '')}
          inputType="select"
          inputConfig={{
            items: getDropDown('applicator'),
            orderItems: true,
            orderKey: 'name',
            onChange: ({ value }: Record<string, any>) => onUpdate('applicator', value)
          }}
          isEditing={isEditing}
        />
        <InfoCell
          label="Applicator Number"
          value={get(data, 'applicatorNumber', '')}
          inputConfig={{
            onChange: (value: Record<string, any>) => onUpdate('applicatorNumber', value)
          }}
          isEditing={isEditing}
        />
      </BoxWrapper>
    </>
  )
}

export default VariantInfo
