import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import FooterControl from '../../../components/FooterControl'
import { ITActionColumnConfig } from '../../../components/Table/types'
import AttachmentForm from '../../../containers/Administration/AttachmentForm'
import AttachmentsTable from '../../../containers/AttachmentsTable'
import { ATTACH_MANAGE_TABLE_CONFIG } from '../../../containers/AttachmentsTable/constants'
import {
  administrationActions,
  administrationSliceActions
} from '../../../features/administration'
import { selectAttachments } from '../../../features/administration/selectors'
import { IAttachment } from '../../../features/packaging/model'
import { AppRoutes } from '../../constants'
import { footerActionHeight } from '../../CreationPackage/styled'
import { ButtonWrapper } from './styled'
import { IAttachAdminCreateForm } from './types'

const ManageAttachments = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const attachmentList = useSelector(selectAttachments)
  const [isOnCreate, setIsOnCreate] = useState(false)
  const [attachment, setAttachment] = useState<IAttachAdminCreateForm>({})

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <Button
          label="Edit"
          variant="text"
          onClick={() =>
            navigate(
              `${AppRoutes.VAULT_ADMINISTRATION}/attachment/${(data as IAttachment).id}`
            )
          }
        />
      )
    }
  }

  useEffect(() => {
    dispatch(administrationActions.getAttachments())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateNewAttachment = (key: string, value: any) => {
    setAttachment({
      ...attachment,
      [key]: value
    })
  }

  const backHandler = () => {
    dispatch(administrationSliceActions.clearType())
    navigate(AppRoutes.VAULT_ADMINISTRATION)
  }

  const resetHandler = () => {
    setAttachment({})
    setIsOnCreate(false)
  }

  const createHandler = () => {
    resetHandler()
    dispatch(
      administrationActions.createAttachment({
        attachData: {
          // NOTE - at the moment isImage input is not available
          // so it's forced to false
          ...attachment,
          idDbPackage: 0,
          isImage: false
        }
      })
    )
  }

  return (
    <>
      <SectionTitle value="Administration" isBold />
      <Divider disableBorder />
      {!isOnCreate ? (
        <>
          <SectionTitle
            value="Attachments management"
            addon={
              <Button
                label="Add"
                iconName="PlusOutlined"
                variant="text"
                iconPosition="left"
                onClick={() => setIsOnCreate(true)}
              />
            }
            isBold
          />
          <AttachmentsTable
            dataList={(attachmentList as IAttachment[]) || []}
            tableConfig={ATTACH_MANAGE_TABLE_CONFIG}
            actionConfig={actionConfig}
            enablePagination
            overTableHeight={footerActionHeight}
            forceHeight
          />
          <FooterControl justifyContent="flex-start">
            <Button label="Back" onClick={backHandler} />
          </FooterControl>
        </>
      ) : (
        <>
          <ButtonWrapper>
            <Button label="Cancel" variant="text" onClick={resetHandler} />
            <Button label="Create" onClick={createHandler} disabled={!attachment?.name} />
          </ButtonWrapper>
          <SectionTitle value="Create a new attachment" />
          <Divider disableBorder />
          <AttachmentForm data={attachment} onUpdate={updateNewAttachment} />
        </>
      )}
    </>
  )
}

export default ManageAttachments
