import { get } from 'lodash'
import { FC } from 'react'
import { Text } from '../../../components/Text'
import { OTHER_PACKAGES } from '../../../features/packaging/model'
import { TitleWrapper } from '../../../pages/CreationPackage/styled'
import AddRelationModal from '../RelationshipsTable/AddRelationModal'
import { RELATION_MESSAGE } from '../RelationshipsTable/AddRelationModal/constants'
import { AlternativeWrapper, EmptyWrapper } from './styled'
import { IAlternativeProps } from './types'
import RelationshipsTable from '../RelationshipsTable'

const Alternative: FC<IAlternativeProps> = ({
  isEditing,
  data,
  onAdd,
  onDelete,
  modalKey
}) => {
  const list = get(data, OTHER_PACKAGES.ALTERNATIVE, [])

  const message = RELATION_MESSAGE[OTHER_PACKAGES.ALTERNATIVE]

  return (
    <AlternativeWrapper>
      <TitleWrapper>
        <Text text="Alternative Package" variant="h4" />
        {isEditing && !!list.length && (
          <AddRelationModal
            btnType="text"
            confirmAdd={onAdd}
            btnLabel="Add"
            hasIcon
            relationType={OTHER_PACKAGES.ALTERNATIVE}
            modalKey={modalKey}
          />
        )}
      </TitleWrapper>
      {(!!list.length && isEditing) || !isEditing ? (
        <RelationshipsTable
          data={data}
          onAdd={onAdd}
          onDelete={onDelete}
          isEditing={isEditing}
          hasDetailBtn
          relationType={OTHER_PACKAGES.ALTERNATIVE}
          isChildOpen
        />
      ) : (
        <EmptyWrapper>
          <Text text={`There are no ${message}`} variant="h4" />
          <AddRelationModal
            btnType="ghost"
            confirmAdd={onAdd}
            btnLabel="Add new one"
            relationType={OTHER_PACKAGES.ALTERNATIVE}
            modalKey={modalKey}
          />
        </EmptyWrapper>
      )}
    </AlternativeWrapper>
  )
}

export default Alternative
