import { FC } from 'react'

import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import { Table } from '../../components'
import { ITActionColumnConfig } from '../../components/Table/types'
import { AZ_VAULT_TOOLING_R_GROUP } from '../../constants/users'
import { selectUserRole } from '../../features/users/selectors'
import { AppRoutes } from '../../pages/constants'
import { columnsGeneratorHelper, rowsGeneratorHelper } from '../../utils'

import { IToolingTableProps } from './types'

const ToolingTable: FC<IToolingTableProps> = ({
  tableConfig,
  dataList,
  enableSelect,
  enableActions = true,
  onSelectRow,
  selectedRows,
  isSingleSelect,
  enablePagination = false,
  onChangePage,
  isLoading
}) => {
  const userRoles = useSelector(selectUserRole)
  const columnGenerator = () => {
    const columnList = columnsGeneratorHelper(tableConfig)

    return columnList
  }

  let actionConfig: ITActionColumnConfig = {}
  // if at least one of the user Roles is in the Read Group for tooling, show the detail page
  if (
    userRoles.some((role) => AZ_VAULT_TOOLING_R_GROUP.includes(role)) &&
    enableActions
  ) {
    actionConfig = {
      columnTitle: 'Actions',
      detail: {
        destinationPath: AppRoutes.VAULT_TOOLING_DETAILS,
        btnLabel: 'Details',
        btnVariant: 'text'
      }
    }
  }

  const rowsGenerator = () => rowsGeneratorHelper(dataList, tableConfig)

  const columns = columnGenerator()
  const dataRows = dataList.length ? rowsGenerator() : []

  return (
    <Table
      rowKey="id"
      columns={columns}
      items={dataRows}
      enableRowSelect={enableSelect}
      selectedRows={selectedRows}
      onSelectRow={onSelectRow}
      isSingleSelect={isSingleSelect}
      enablePagination={enablePagination}
      onChangePage={onChangePage}
      isLoading={isLoading}
      actionColumnConfig={isEmpty(actionConfig) ? undefined : actionConfig}
    />
  )
}

export default ToolingTable
