import { FC, useEffect } from 'react'

import { Tabs } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'

import {
  canUserSeeFschemaData,
  canUserSeeOtherTestedPackagingForProduct,
  canUserSeeTestedClaimForProduct
} from '@/utils/permissionsHelpers'

import { InfoCell, SectionTitle, Table } from '../../../components'
import { Accordion } from '../../../components/Accordion'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { DATE_FORMAT } from '../../../components/Form/RangeDatePicker/contants'
import { ActionsAttachs } from '../../../components/Table/attachments'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { Text } from '../../../components/Text'
import { IAttachment, PACKAGE_TYPE } from '../../../features/packaging/model'
import { productStatusDropdownValues } from '../../../features/product/constants'
import { characteristicsMappedLabels } from '../../../features/products/constants'
import { TProductDtoCharacteristics } from '../../../features/products/model'
import { getProductsDetail } from '../../../features/products/selectors'
import { selectPermissions, selectUserRole } from '../../../features/users/selectors'
import { TESTED_CLAIM_COLUMNS } from '../../../pages/FSchemaDetail/constants'
import { IVariantInfo } from '../../../pages/ProductCreation/Summary/types'
import {
  columnsGeneratorHelper,
  getRecentDate,
  getSideRegulatoryDates,
  productFSMonoRefactor,
  productRegulatoryGenData
} from '../../../utils'
import { filterAttachmentsByPermissions } from '../../../utils/attachmentsHelper'
import AttachmentsTable from '../../AttachmentsTable'
import {
  PRODUCT_ATTACHMENTS_TABLE_CONFIG,
  PRODUCT_MARKETING_TABLE_CONFIG
} from '../../AttachmentsTable/constants'
import FormulaSchemaTable from '../../FormulaSchemaTable'
import {
  PRODUCT_IC_DETAIL_MONO_TABLE_CONFIG,
  PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG
} from '../../FormulaSchemaTable/constants'
import RegulatoryCheckInfo from '../../RegulatoryCheckInfo'
import ToolingInfo from '../../ToolingInfo'
import DetailInformation from '../DetailInformation'
import FormulaSchemaData from '../FormulaSchemaData'
import ProdGeneralData from '../GeneralData'
import PackagingData from '../PackagingData'
import PackagingSideData from '../PackagingSideData'

import ProductOtherTestedPack from './ProductOtherTestedPack'
import { IProductDetailProps } from './types'

const ProductDetail: FC<IProductDetailProps> = ({ data }) => {
  const permissions = useSelector(selectPermissions)
  const userRoles = useSelector(selectUserRole)

  const isMulti = get(data, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const productSides = data.productSides || []
  const [sideMonoInfo = {}] = productSides
  const fSchemaMonoData = sideMonoInfo?.formulaSchema || {}
  const hasMaketingProfile = !!get(data, 'marketingProfile.value', '')
  const productDetails = useSelector(getProductsDetail)

  const prodCharacteristics: TProductDtoCharacteristics = get(
    productDetails,
    'characteristics',
    {}
  )
  const characteristicList = Object.keys(prodCharacteristics)
  const toolingData = get(sideMonoInfo, 'tooling', '')
  const isReady = get(data, 'status', '') === productStatusDropdownValues.READY_TO_GO
  const isLevel2InProgress =
    get(data, 'status', '') === productStatusDropdownValues.LEVEL_2_IN_PROGRESS

  const fSchemaList = productSides.map(({ formulaSchema }: any) => formulaSchema || {})
  const refactorRegulatoryData = productRegulatoryGenData(fSchemaList)

  const canSeeFschemaData =
    canUserSeeFschemaData({ userRoles }) ||
    get(productDetails, 'status') !== 'LEVEL_2_IN_PROGRESS'

  const getPackagingInfo = (sideIdx: number) => {
    const sideInfo = productSides[sideIdx]
    const packageInfo = get(sideInfo, 'packageVariant.packageFamily', {})
    return {
      packageId: get(packageInfo, 'sideCollectorId', ''),
      status: get(packageInfo, 'sideCollector.status', ''),
      packagingCode: get(sideInfo, 'packagingCode', ''),
      category: get(packageInfo, 'category', ''),
      subcategory: get(packageInfo, 'subcategory', ''),
      supplierRef: get(sideInfo, 'packagingSupplierRef', ''),
      testedSupplier: { name: get(sideInfo, 'packagingTestedSupplier', '') },
      format: get(sideInfo, 'format', ''),
      packageFamilies: [packageInfo],
      USDTargetPrice: get(packageInfo, 'USDTargetPrice', ''),
      MOQ: get(packageInfo, 'MOQ', '')
    }
  }

  const getVariantInfo: (sideIdx: number) => IVariantInfo = (sideIdx) => {
    const sideInfo = productSides[sideIdx]
    const variantInfo = get(sideInfo, 'packageVariant', {})
    return {
      ...get(variantInfo, 'packageFamily', {}),
      bottle: get(variantInfo, 'vialDetails.bottle', []),
      dipstick: get(variantInfo, 'vialDetails.dipstick', ''),
      wiper: get(variantInfo, 'vialDetails.wiper', ''),
      applicator: get(variantInfo, 'vialDetails.applicator', ''),
      variantId: get(variantInfo, 'id', ''),
      variantCode: get(variantInfo, 'variantCode', ''),
      variantSupplierCode: get(variantInfo, 'vialDetails.variantSupplierCode', ''),
      attachments: isMulti
        ? get(sideInfo, 'packagingAttachments', [])
        : get(variantInfo, 'attachments', [])
    }
  }

  const getDetailInfo = (sideIdx: number) => {
    const sideInfo = productSides[sideIdx]
    return {
      businessUnit: get(data, 'businessUnit', ''),
      businessSegment: get(data, 'businessSegment', ''),
      SPF: get(data, 'SPF', ''),
      applicationArea: get(sideInfo, 'applicationArea', ''),
      productCategory: get(sideInfo, 'productCategory', ''),
      productForm: get(sideInfo, 'productForm', ''),
      fillingMethodCycle: get(sideInfo, 'fillingMethodCycle', ''),
      internalLastingPosition: get(sideInfo, 'internalLastingPosition', ''),
      intercosInside: get(sideInfo, 'intercosInside', null),
      intercosInsidePositioning: get(data, 'intercosInsidePositioning', ''),
      marketBench: get(sideInfo, 'marketBench', ''),
      firstProductionOrigin: get(sideInfo, 'firstProductionOrigin', ''),
      formulaTransferTo: get(sideInfo, 'formulaTransferTo', ''),
      fillingAvailableIn: get(data, 'fillingAvailableIn', ''),
      sapOfferCode: get(data, 'sapOfferCode', ''),
      externalClaim: get(sideInfo, 'externalClaim', ''),
      mascaraPositioning: get(sideInfo, 'mascaraPositioning', ''),
      coverage: get(sideInfo, 'coverage', ''),
      opaquePackaging: get(sideInfo, 'opaquePackaging', null),
      note: get(sideInfo, 'note', ''),
      stabilityStartDate: get(sideInfo, 'stabilityStartDate', ''),
      innovationLevel: get(sideInfo, 'innovationLevel', ''),
      bulkLevel: get(sideInfo, 'bulkLevel', ''),
      industrialLevel: get(sideInfo, 'industrialLevel', ''),
      packagingLevel: get(sideInfo, 'packagingLevel', ''),
      pdLaunchYear: get(sideInfo, 'pdLaunchYear') || get(sideInfo, 'launchYear', ''),
      pdCollection: get(sideInfo, 'pdCollection') || get(sideInfo, 'collection', ''),
      compatibility: get(sideInfo, 'compatibility', '')
    }
  }

  const testedClaim = productSides.reduce(
    (acc: any, curr: any) => acc.concat([...curr.formulaSchema.testedExtensClaims]),
    []
  )

  const multiAttachments = get(data, 'packagingAttachments', [])
  const monoAttachments = getVariantInfo(0).attachments
  const attachmentList = isMulti ? multiAttachments : monoAttachments
  const filteredAttachments = filterAttachmentsByPermissions({
    attachments: attachmentList,
    permissions,
    section: 'vaultProduct',
    crud: 'onRead'
  })

  const attachmentsActions: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return <ActionsAttachs url={data.url} />
    }
  }

  const productAllRegulatoryDates = productSides.reduce(
    (acc: any, curr: any) => {
      acc = [
        ...acc,
        curr?.formulaSchema?.regulatoryLastUpdate,
        ...curr.formulaSchema.formula.map((formula: any) => formula.regulatoryLastUpdate)
      ]
      return acc
    },
    [data?.regulatoryLastUpdate]
  )
  const regulatoryLastUpdatedDate = productSides.length
    ? getRecentDate(productAllRegulatoryDates)
    : ''

  // NOTE - CR100 - temporary hidden
  // const hasCharacteristics = !isMulti && !!characteristicList.length
  const hasCharacteristics = false

  return (
    <>
      <SectionTitle value={get(data, 'name', '')} isBold />
      <Divider />
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="product-image"
          value={get(data, 'image.value', '')}
          dataType="image"
          inputConfig={{
            isUrl: true
          }}
        />
      </BoxWrapper>
      <Text
        text="*Picture might not be a reference for color and finish: please refer to information below"
        italic
      />
      <Accordion
        title={'General Data'}
        variant={'Title'}
        open={true}
        isDate={false}
        subtitleValue={''}
        subtitleLabel={''}
      >
        <ProdGeneralData data={data} isMulti={isMulti} />
      </Accordion>
      {!isMulti && canSeeFschemaData && (
        <Accordion
          title={'Formula Schema Data'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <FormulaSchemaData data={productFSMonoRefactor(fSchemaMonoData)} />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Detailed Information'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <DetailInformation data={getDetailInfo(0)} isReady={isReady} />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Regulatory Check FS'}
          variant={'Title'}
          open={false}
          subtitleLabel={'Last Update: '}
          subtitleValue={regulatoryLastUpdatedDate}
          isDate
        >
          <RegulatoryCheckInfo
            data={fSchemaMonoData as Record<string, any>}
            hasDisclaimer
          />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Industrial/Trial Code Information'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <FormulaSchemaTable
            tableConfig={PRODUCT_IC_DETAIL_MONO_TABLE_CONFIG}
            dataList={get(fSchemaMonoData, 'formula', [])}
          />
        </Accordion>
      )}

      <Accordion
        title={'Packaging Data'}
        variant={'Title'}
        subtitleValue={''}
        subtitleLabel={''}
        open={false}
        isDate={false}
      >
        <PackagingData
          data={{ ...getPackagingInfo(0), ...getVariantInfo(0) }}
          isMulti={isMulti}
        />
      </Accordion>
      {isMulti && (
        <Accordion
          title={'Regulatory General Data'}
          variant={'Title'}
          open={false}
          subtitleLabel={'Last Update: '}
          subtitleValue={regulatoryLastUpdatedDate}
          isDate
        >
          <RegulatoryCheckInfo data={refactorRegulatoryData} isMulti hasDisclaimer />
        </Accordion>
      )}
      {isMulti && (
        <Accordion
          title={'Sides'}
          variant={'Title'}
          open={false}
          subtitleValue={''}
          subtitleLabel={''}
          isDate={false}
        >
          <Tabs>
            {productSides.map((sideData: any, sideIdx: number) => (
              <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                {(canUserSeeFschemaData({ userRoles }) || !isLevel2InProgress) && (
                  <>
                    <SectionTitle value="Formula Schema Data" />
                    <Divider />
                    <FormulaSchemaData
                      data={productFSMonoRefactor(sideData.formulaSchema)}
                    />
                    <Divider disableBorder />
                  </>
                )}
                <SectionTitle value="Detail Information" />
                <Divider />
                <DetailInformation
                  data={getDetailInfo(sideIdx)}
                  isMulti
                  isReady={isReady}
                />
                <Divider disableBorder />
                <SectionTitle value="Packaging Side Data" />
                <Divider />
                <PackagingSideData data={getVariantInfo(sideIdx)} />
                <Divider disableBorder />
                <SectionTitle
                  value="Side Regulatory"
                  addon={`Last Update: ${
                    getRecentDate(getSideRegulatoryDates(sideData))
                      ? moment(getRecentDate(getSideRegulatoryDates(sideData))).format(
                          DATE_FORMAT
                        )
                      : '-'
                  } `}
                />
                <Divider />
                <RegulatoryCheckInfo
                  data={get(sideData, 'formulaSchema', {})}
                  hasDisclaimer
                />
                <Divider disableBorder />
                <SectionTitle value="Industrial/Trial Code Information" />
                <Divider />
                <FormulaSchemaTable
                  tableConfig={PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG}
                  dataList={sideData.formulaSchema?.formula || []}
                />
                {/* NOTE - temporary hidden */}
                {/* <Divider disableBorder />
                  <SectionTitle value="Attachments" />
                  <Divider />
                  <AttachmentsTable
                    dataList={getVariantInfo(sideIdx).attachments as IAttachment[]}
                    tableConfig={PRODUCT_ATTACHMENTS_TABLE_CONFIG}
                  /> */}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Accordion>
      )}

      {toolingData && (
        <Accordion
          title={'Tooling Information'}
          variant={'Title'}
          subtitleValue={''}
          subtitleLabel={''}
          open={false}
          isDate={false}
        >
          <ToolingInfo data={toolingData} />
        </Accordion>
      )}
      {canUserSeeOtherTestedPackagingForProduct({ userRoles }) && (
        <Accordion
          title={'Other Tested Packaging'}
          variant={'Title'}
          subtitleValue={''}
          subtitleLabel={''}
          open={false}
          isDate={false}
        >
          <ProductOtherTestedPack />
        </Accordion>
      )}
      {canUserSeeTestedClaimForProduct({ userRoles }) && (
        <Accordion
          title={'Tested Claim'}
          variant={'Title'}
          subtitleValue={''}
          subtitleLabel={''}
          open={false}
          isDate={false}
        >
          <Table
            enablePagination={false}
            paginationPosition={'TOP_RIGHT'}
            rowKey="id"
            columns={columnsGeneratorHelper(TESTED_CLAIM_COLUMNS)}
            items={testedClaim || []}
          />
        </Accordion>
      )}
      <Accordion
        title={'Attachments'}
        variant={'Title'}
        open={false}
        subtitleValue={''}
        subtitleLabel={''}
        isDate={false}
      >
        <Text
          text={
            '*Marketing Fiche is intended to be a source of qualitative information only, before sending it to clients please double check that it is updated. As tested claims are concerned, please note that different IC belonging to same FS might report conflicting results. Particularly, before considering a result valid for the whole FS, please verify with BU that the tested IC did not contain any specific active not included in the FS'
          }
          italic
        />
        <Divider disableBorder />
        {hasMaketingProfile && (
          <>
            <SectionTitle value="Marketing Profile" />
            <Divider />
            <AttachmentsTable
              actionConfig={attachmentsActions}
              dataList={[get(data, 'marketingProfile', {})]}
              tableConfig={PRODUCT_MARKETING_TABLE_CONFIG}
            />
            <Divider disableBorder />
            <Divider disableBorder />
          </>
        )}
        <SectionTitle value="Attachments" />
        <Divider />
        <AttachmentsTable
          actionConfig={
            filteredAttachments.length ||
            get(productDetails, 'testedClaimAttachments', []).length
              ? attachmentsActions
              : undefined
          }
          dataList={
            filteredAttachments.concat(
              get(productDetails, 'testedClaimAttachments', [])
            ) as IAttachment[]
          }
          tableConfig={PRODUCT_ATTACHMENTS_TABLE_CONFIG}
          childActionConfig={{
            hasDelete: true
          }}
        />
      </Accordion>
      {hasCharacteristics && (
        <Accordion
          title={'Characteristics'}
          variant={'Title'}
          open={false}
          subtitleValue={''}
          subtitleLabel={''}
          isDate={false}
        >
          <BoxWrapper enablePadding>
            {Object.keys(prodCharacteristics).map((charKey) => (
              <InfoCell
                key={charKey}
                label={get(characteristicsMappedLabels, charKey, '')}
                value={get(productDetails, `characteristics.${charKey}`, '')}
              />
            ))}
          </BoxWrapper>
        </Accordion>
      )}
    </>
  )
}

export default ProductDetail
