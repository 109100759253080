import { FC, useEffect, useRef, useState } from 'react'

import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { footerHeight, headerHeight, sectionTitleHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Loader } from '../../components/Loader'
import { Steps } from '../../components/Steps'
import { PageContent } from '../../containers'
import { FORMULA_TABLE_CONFIG } from '../../containers/FormulaSchemaTable/constants'
import Success from '../../containers/Success'
import { packagingSliceActions } from '../../features/packaging'
import { CATEGORY, PackagingDto } from '../../features/packaging/model'
import { prodActions, prodSliceActions } from '../../features/product'
import {
  getIsPackageVariantSelected,
  getIsSidesFormulaSelected,
  hasPackage,
  hasTooling,
  isAdditionalDataFormValid,
  selectProdError,
  selectProdFSchema,
  selectProdFormulaCodes,
  selectProdIsLoading,
  selectSelectedPackage,
  selectSelectedTooling,
  selectSidesData,
  selectSuccessInfo
} from '../../features/product/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { useWindowSize } from '../../utils'
import { AppRoutes } from '../constants'

import AdditionalData from './AdditionalData'
import DataFormulaSchema from './DataFormulaSchema'
import DataPackaging from './DataPackaging'
import Summary from './Summary'
import { FooterActions, StepContent, StyledText, footerActionHeight } from './styled'

const ProductCreation: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productError = useSelector(selectProdError)
  const successInfo = useSelector(selectSuccessInfo)
  const isLoading = useSelector(selectProdIsLoading)
  const userRoles = useSelector(selectUserRole)
  const isVariantSelected = useSelector(getIsPackageVariantSelected)
  const isToolingSelected = useSelector(hasTooling)
  const isPackSelected = useSelector(hasPackage)
  const selectedPackage = useSelector(selectSelectedPackage)
  const selectedTooling = useSelector(selectSelectedTooling)
  const sidesData = useSelector(selectSidesData)
  const isAdditionalDataValid = useSelector(isAdditionalDataFormValid)
  const isSidesFormulaSelected = useSelector(getIsSidesFormulaSelected)
  const selectedFormulaSchema = useSelector(selectProdFSchema)
  const formulaCodes = useSelector(selectProdFormulaCodes)

  const selectedFormula = sidesData.map(({ fSchema }) => fSchema?.formula || [])

  const [currentStep, setCurrentStep] = useState(0)
  const [heightToRemove, setHeightToRemove] = useState<number>(0)
  const { width } = useWindowSize()

  const stepperRef = useRef<HTMLDivElement>(null)

  const getTopAndBottomHeigts = () => {
    let totalHeight = sectionTitleHeight
    if (stepperRef.current) {
      totalHeight += stepperRef.current.offsetHeight
    }
    setHeightToRemove(totalHeight)
  }

  useEffect(() => {
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    return () => {
      dispatch(prodSliceActions.clearSuccess())
      dispatch(prodSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTopAndBottomHeigts()
  }, [width])

  const backStepHandler = () => {
    setCurrentStep((prevStep) => --prevStep)
  }

  const nextStepHandler = () => {
    setCurrentStep((prevStep) => ++prevStep)
  }

  const resetHandler = () => {
    dispatch(prodSliceActions.removePackaging())
    dispatch(prodSliceActions.resetItem())
    dispatch(prodSliceActions.deleteTooling())
    dispatch(prodSliceActions.deleteSchema())
    dispatch(prodSliceActions.clearSuccess())
    setCurrentStep(0)
  }

  const selectVariantHandler = (sideIdx: number, selected: any[]) => {
    const [selectedVariant] = selected
    dispatch(prodSliceActions.setVariant({ sideIdx, selected: selectedVariant }))
  }

  const selectedVariants = sidesData.length
    ? sidesData.map(({ vialsDetailId, ...args }) => ({
        id: vialsDetailId,
        ...args
      }))
    : []

  const autoSelectSingleVariants = (selectedPackages: Record<string, any>[]) => {
    const [selectedPack] = selectedPackages
    const packageFamilies = get(selectedPack, 'packageFamilies', [])
    const sides = packageFamilies.map(() => ({}))
    packageFamilies.forEach((family: Record<string, any>, idx: number) => {
      const isVials = get(family, 'category', '').toLowerCase() === CATEGORY.VIALS
      const variantData = get(family, 'packageVariants', []).map(
        ({ vialDetails, id }: any) => ({
          ...vialDetails,
          idPackaging: id
        })
      )
      // NOTE
      // vials - can have multiple variant
      // not vial - have ONLY one variant
      if (!isVials) {
        const [selectedVariant] = variantData
        sides[idx] = {
          vialsDetailId: get(selectedVariant, 'id', ''),
          packageVariantId: get(selectedVariant, 'idPackaging', ''),
          airtightPackagingNeeded: get(selectedVariant, 'airtight', false)
        }
      }
    })
    dispatch(prodSliceActions.updateProduct({ key: 'sides', value: sides }))
  }

  const selectPackHandler = (selectedPackages: Record<string, any>[]) => {
    dispatch(prodSliceActions.updatePackaging(selectedPackages as PackagingDto[]))
    autoSelectSingleVariants(selectedPackages)
  }

  const selectToolHandler = (selectedTooling: Record<string, any>[]) => {
    dispatch(prodActions.addTooling({ selected: selectedTooling }))
  }

  const removePackHandler = () => dispatch(prodSliceActions.removePackaging())
  const removeToolHandler = () => dispatch(prodSliceActions.deleteTooling())

  const removeFSchemaHandler = (idx: number) => {
    dispatch(prodSliceActions.removeSchema({ sideIdx: idx }))
    dispatch(prodSliceActions.removeFormulaCodesDEVEX({ sideIdx: idx }))
  }

  const selectFormulaHandler = (idx: number, selectedFormulas: any[]) => {
    dispatch(prodSliceActions.applyFormula({ sideIdx: idx, formulas: selectedFormulas }))
  }
  const selectFSchemaHandler = (idx: number, selected: any[]) => {
    const [selectedFSchema] = selected
    dispatch(prodActions.selectFSchema({ idx, selected: selectedFSchema }))
  }

  const steps = [
    {
      title: 'DATA PACKAGING',
      body: (
        <>
          <DataPackaging
            isByPack
            isByTool
            onRemovePack={removePackHandler}
            onRemoveTool={removeToolHandler}
            onSelectVariant={selectVariantHandler}
            isPackSelected={isPackSelected}
            isToolSelected={isToolingSelected}
            selectedPackage={selectedPackage}
            selectedTooling={selectedTooling}
            selectedVariants={selectedVariants}
            onPackSelect={selectPackHandler}
            onToolSelect={selectToolHandler}
            searchPackBtnLabel="ADD PACKAGING"
            searchToolBtnLabel="ADD PANS ET ALIA"
          />
          <StyledText
            italic
            variant="h4"
            text={
              'For products that involve the usage of pans, grids or terracotta tiles please remember to start the PD creation by clicking on the "Add Pans et alia" tab'
            }
          />
        </>
      ),
      footer: [
        <Button
          key="next"
          label="Next"
          onClick={nextStepHandler}
          disabled={!isVariantSelected}
        />
      ]
    },
    {
      title: 'DATA FORMULA SCHEMA',
      body: (
        <DataFormulaSchema
          onRemoveFSchema={removeFSchemaHandler}
          onSelectFormula={selectFormulaHandler}
          onSelectFSchema={selectFSchemaHandler}
          packageData={selectedPackage}
          formulaCodes={formulaCodes}
          fSchemaSelected={selectedFormulaSchema}
          isFSchemaSingleSelect
          formulaSelected={selectedFormula}
          formulaTableConfig={FORMULA_TABLE_CONFIG}
        />
      ),
      footer: [
        <Button key="back" label="Back" onClick={backStepHandler} />,
        <Button
          key="next"
          label="Next"
          onClick={nextStepHandler}
          disabled={!isSidesFormulaSelected}
        />
      ]
    },
    {
      title: 'ADDITIONAL DATA',
      body: <AdditionalData />,
      footer: [
        <Button key="back" label="Back" onClick={backStepHandler} />,
        <Button
          key="next"
          label="Next"
          onClick={() => {
            const pageContent = document.getElementById('content')
            pageContent?.scrollTo(0, 0)
            nextStepHandler()
          }}
          disabled={!isAdditionalDataValid}
        />
      ]
    },
    {
      title: 'SUMMARY',
      body: <Summary />,
      footer: [
        <Button key="back" label="Back" onClick={backStepHandler} />,
        <Button
          key="save"
          label="Save"
          onClick={() => dispatch(prodActions.createProduct())}
        />
      ]
    }
  ]

  return (
    <GenericLayout error={productError}>
      <PageContent
        footerHeight={footerHeight + (!successInfo ? footerActionHeight : 0)}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <SectionTitle value="Product Creation" isBold />
        <Steps
          steps={steps as Record<string, any>[]}
          container_variables={{ current: currentStep }}
          ref={stepperRef}
        />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <StepContent heightToRemove={heightToRemove}>
              {!successInfo ? (
                steps[currentStep].body
              ) : (
                <Success
                  data={successInfo}
                  buttons={[
                    {
                      label: 'Create new product',
                      onClick: resetHandler,
                      variant: 'ghost'
                    },
                    {
                      label: 'Go to the RTG product selection',
                      onClick: () => navigate(AppRoutes.VAULT_PRODUCTS_SEARCH)
                    },
                    {
                      label: 'Back to home',
                      onClick: () => navigate(AppRoutes.HOME),
                      iconName: 'HomeOutlined',
                      variant: 'text'
                    }
                  ]}
                />
              )}
            </StepContent>
            {!!steps[currentStep].footer && !successInfo && (
              <FooterActions>{steps[currentStep].footer}</FooterActions>
            )}
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ProductCreation
