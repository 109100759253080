import { Form } from 'antd'
import { get } from 'lodash'
import React, { FC } from 'react'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { IFSchemaDetailSection } from './types'

const FSchemaDetailSection: FC<IFSchemaDetailSection> = ({ data = {} }) => {
  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell label="Formula Schema Name" value={get(data, 'name', '')} />
        <InfoCell label="FS Launch Year" value={get(data, 'launchYear', '')} />
        <InfoCell label="FS Collection" value={get(data, 'collection', '')} />
        <InfoCell label="FS Finish" value={get(data, 'fsFinish', '')} />
        <InfoCell label="FS SPF" value={get(data, 'fsSpf', null)} />
        <InfoCell label="OTC" value={get(data, 'otc', null)} />
      </BoxWrapper>
    </Form>
  )
}

export default FSchemaDetailSection
