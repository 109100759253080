import { useEffect } from 'react'

import emitter from './emitter'

export const useModalListener = (modalKey: string, cb: any) => {
  useEffect(() => {
    emitter.on('modal-instance-' + modalKey, cb)
    return () => {
      emitter.off('modal-instance-' + modalKey, cb)
    }
  }, [modalKey, cb])
}
