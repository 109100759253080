import { Form, TablePaginationConfig } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Loader, Modal, Select } from '../../../../components'
import { Advice } from '../../../../components/Advice'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Input } from '../../../../components/Form/Input'
import { IPageData } from '../../../../components/Table/types'
import { appActions, appSliceActions } from '../../../../features/app'
import { packagingActions, packagingSliceActions } from '../../../../features/packaging'
import { PACKAGING_TEMP_TO_FILTER } from '../../../../features/packaging/constants'
import { OTHER_PACKAGES } from '../../../../features/packaging/model'
import {
  getDropdownTestedSupplier,
  getPackagingItems,
  getPackagingTempFilters
} from '../../../../features/packaging/selectors'
import { IError } from '../../../Error/types'
import OthersPackagingTable from '../../OthersPackagingTable'
import { RELATIONSHIPS_TABLE_CONFIG } from '../../OthersPackagingTable/constants'
import { RELATION_MESSAGE } from './constants'
import { ModalBodyContainer } from './styled'
import { IAddRelationModalProps } from './types'

const AddRelationModal: FC<IAddRelationModalProps> = ({
  confirmAdd,
  hasIcon,
  btnType = 'text',
  btnLabel = 'Add',
  relationType,
  modalKey
}) => {
  const [isFilterApplied, setIsFilterApply] = useState(false)
  const [selectedPakages, setSelectedPakages] = useState<any[]>([])
  const [pageDataState, setPageDataState] = useState<IPageData>({
    page: 1,
    pageSize: 10,
    total: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<IError>({})

  const { id: packageId } = useParams()

  const message = RELATION_MESSAGE[relationType]

  const dispatch = useDispatch()
  const tempFilters = useSelector(getPackagingTempFilters)
  const searchResult = useSelector(getPackagingItems)
  const testedSupplierDropdowns = useSelector(getDropdownTestedSupplier)

  const closeModalHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
  }

  const resetPaginationHandler = () => {
    setPageDataState({
      page: 1,
      pageSize: 10,
      total: 0
    })
  }

  const resetModal = () => {
    if (isFilterApplied) {
      setIsFilterApply(false)
    }
    if (!isEmpty(error)) {
      setError({})
    }
    resetPaginationHandler()
    dispatch(packagingSliceActions.clearTempFilter())
    setSelectedPakages([])
  }

  const updateTempFilter = (value: any, key: string) => {
    dispatch(
      packagingSliceActions.setTempFilter({
        ...tempFilters,
        [key]: value
      })
    )
  }

  const searchHandler = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setIsLoading(true)
    dispatch(
      packagingActions.getPackageAndRelations({
        withFurther: relationType === OTHER_PACKAGES.FURTHER,
        withRelated:
          relationType === OTHER_PACKAGES.RELATED ||
          relationType === OTHER_PACKAGES.ALTERNATIVE,
        hasLoader: false,
        pageData: { page, pageSize },
        successCallback: (newPageData) => {
          setIsLoading(false)
          setIsFilterApply(true)
          setPageDataState(newPageData)
        },
        failCallback: (err) => {
          setIsLoading(false)
          setError(err)
        }
      })
    )
  }

  const changePageHandler = (pageData: TablePaginationConfig = {}) => {
    setPageDataState({
      page: +get(pageData, 'current', 0),
      pageSize: +get(pageData, 'pageSize', 10),
      total: +get(pageData, 'total', 10)
    })
  }

  const applyFilter = () => {
    dispatch({ type: PACKAGING_TEMP_TO_FILTER })
  }

  const addHandler = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Do you want to confirm this operation?',
        key: `popup-${modalKey}`,
        message: (
          <div>
            You are adding the following packaging:
            <ul>
              {selectedPakages.map(({ id, packagingCode }) => (
                <li key={id}>{packagingCode}</li>
              ))}
            </ul>
            {`as ${message} one/s.`}
          </div>
        ),
        footer: [
          <Button
            key="no"
            label="No"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="yes"
            label="Yes"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              closeModalHandler()
              const data = {
                selectedPackages: selectedPakages,
                id: packageId || ''
              }
              // TODO - now that this component has no-async callback as well
              // it's better to type the confirmAdd as  a generic function
              // for the <Alternative/>, confimrAdd should be a simple update of tempDetail or newPackage
              confirmAdd && dispatch(confirmAdd(data))
            }}
          />
        ]
      })
    )
  }

  const backHandler = () => {
    resetPaginationHandler()
    setIsFilterApply(false)
  }

  const footerList = !isFilterApplied
    ? [
        <Button
          key="cancel"
          label="Cancel"
          onClick={closeModalHandler}
          variant="ghost"
        />,
        <Button
          key="search"
          label="Search"
          onClick={() => {
            applyFilter()
            searchHandler(pageDataState)
          }}
          enableKeyPress
        />
      ]
    : [
        <Button key="back" label="Back" onClick={backHandler} variant="ghost" />,
        <Button
          key="add"
          label="Add"
          onClick={addHandler}
          disabled={!selectedPakages.length}
        />
      ]

  const selectHandler = (rows: any[]) => {
    setSelectedPakages(rows)
  }

  return (
    <Modal
      btnName={btnLabel}
      btnIcon={hasIcon ? 'PlusCircleOutlined' : ''}
      size={!isFilterApplied ? 'medium' : 'large'}
      isSmall={!isFilterApplied}
      modalKey={modalKey}
      title="Filter package"
      btnType={btnType}
      paddingBody="0px"
      footer={footerList}
      btnClicked={resetModal}
      centered
    >
      <ModalBodyContainer>
        {!isEmpty(error) ? (
          <Advice
            status={error?.status || '500'}
            subTitle={error?.subTitle}
            title={error?.title}
          >
            {error?.message}
          </Advice>
        ) : (
          <>
            {!isFilterApplied &&
              (isLoading ? (
                <Loader />
              ) : (
                <Form layout="vertical">
                  <BoxWrapper md={2} lg={3} enablePadding>
                    <Input
                      label="PK Intercos Code"
                      value={get(tempFilters, 'packagingCode', '')}
                      onChange={(value) => updateTempFilter(value, 'packagingCode')}
                    />
                    <Input
                      label="Supplier Pkg Code"
                      value={get(tempFilters, 'supplierRef', '')}
                      onChange={(value) => updateTempFilter(value, 'supplierRef')}
                    />
                    <Select
                      items={testedSupplierDropdowns}
                      label="Packaging Supplier"
                      withLookup
                      value={get(tempFilters, 'testedSupplier.id', '')}
                      onChange={({ value }) => {
                        updateTempFilter({ id: value }, 'testedSupplier')
                      }}
                      orderItems
                      orderKey="name"
                    />
                  </BoxWrapper>
                </Form>
              ))}
            {isFilterApplied && (
              <OthersPackagingTable
                enablePagination
                tableConfig={get(RELATIONSHIPS_TABLE_CONFIG, relationType, {})}
                dataList={searchResult}
                enableRowSelect
                onSelectRow={selectHandler}
                disableHeaderSelect
                onChangePage={(pageData) => {
                  changePageHandler(pageData)
                  searchHandler({
                    page: +get(pageData, 'current', 0),
                    pageSize: +get(pageData, 'pageSize', 10)
                  })
                }}
                selectedRows={selectedPakages}
                isLoading={isLoading}
                pageData={pageDataState}
              />
            )}
          </>
        )}
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddRelationModal
