import {
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_WIKIPACK_IE,
  AZ_WIKIPACK_MRKT,
  AZ_WIKIPACK_PKACCEVO,
  AZ_WIKIPACK_PKDEV,
  AZ_WIKIPACK_QC,
  AZ_WIKIPACK_RDF,
  AZ_WIKIPACK_SHUB,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_TCS
} from '../../constants/users'
import { PACKAGING_TABLE_CONFIG_MONO } from '../../containers/Packaging/PackagingTable/constant'
import { PACKAGE_TYPE } from '../../features/packaging/model'
import {
  canUserSeeSupplierRef,
  canUserSeeTargetPrice,
  canUserSeeTestedSupplier
} from '../permissionsHelpers'
import { columnsGeneratorHelper, rowsGeneratorHelper_new } from '../tableHelpers'

import {
  TCheckFieldVisibilityByRolesFunc,
  TPackagingResultColumnGeneratorFunc,
  TPackagingResultRowGeneratorFunc,
  TRefactorPackageFilterFunc,
  TRefactorTargetPriceFunc
} from './types'

export const refactorPackageFilter: TRefactorPackageFilterFunc = ({ filters }) => {
  const newFilters = { ...filters }
  if (newFilters.packageFamilies) {
    delete newFilters.packageFamilies
  }
  if (newFilters.type) {
    delete newFilters.type
  }
  if ('nullPricesOnly' in newFilters) {
    if (newFilters.nullPricesOnly) {
      newFilters.nullPricesOnly = 'Null'
    } else {
      delete newFilters.nullPricesOnly
    }
  }
  return newFilters
}

export const refactorTargetPrice: TRefactorTargetPriceFunc = ({
  value,
  currency = 'USD'
}) => {
  const valueInt = +value
  const roundedValue = Math.round(valueInt)
  const valueWidthCurrency = `${roundedValue}${currency ? ` ${currency}` : ''}`
  return valueWidthCurrency
}

export const packagingResultColumnGenerator: TPackagingResultColumnGeneratorFunc = ({
  userRoles
}) => {
  const usersNotAllowedTargetPrice = [AZ_WIKIPACK_IE, AZ_WIKIPACK_RDF]

  // NOTE - for the moment there is an inconsistency between pakage detail and package result
  // abount target price visibility - in package detail usersNotAllowedTargetPrice is not considered
  // maybe usersNotAllowedTargetPrice needs to be add at canUserSeeTargetPrice() or removed from here
  const isTargetPriceVisible =
    canUserSeeTargetPrice({ userRoles }) &&
    !usersNotAllowedTargetPrice.some((role: string) => userRoles.includes(role))

  const isSupplierRefVisible = canUserSeeSupplierRef({ userRoles })

  const isTestedSupplierVisible = canUserSeeTestedSupplier({ userRoles })

  const newConfig = {
    ...PACKAGING_TABLE_CONFIG_MONO,
    USDTargetPrice: {
      ...PACKAGING_TABLE_CONFIG_MONO.USDTargetPrice,
      hide: !isTargetPriceVisible
    },
    supplierRef: {
      ...PACKAGING_TABLE_CONFIG_MONO.supplierRef,
      hide: !isSupplierRefVisible
    },
    testedSupplier: {
      ...PACKAGING_TABLE_CONFIG_MONO.testedSupplier,
      hide: !isTestedSupplierVisible
    }
  }

  const columnList = columnsGeneratorHelper(newConfig)

  return columnList
}

export const packagingResultRowGenerator: TPackagingResultRowGeneratorFunc = ({
  dataList
}) => {
  const rowList = rowsGeneratorHelper_new({
    dataList,
    tableConfig: PACKAGING_TABLE_CONFIG_MONO
  })

  const refactoredList = rowList.map(
    ({ type = '', packageFamilies = [], USDTargetPrice, neutroName, ...rest }) => {
      const isMulti = type.toLocaleLowerCase() === PACKAGE_TYPE.MULTI

      return {
        ...rest,
        type,
        packageFamilies,
        USDTargetPrice: USDTargetPrice && refactorTargetPrice({ value: USDTargetPrice }),
        neutroName,
        children: isMulti ? packageFamilies : undefined
      }
    }
  )
  return refactoredList
}

export const checkFieldVisibilityByRoles: TCheckFieldVisibilityByRolesFunc = ({
  key,
  roles = [],
  isOnCreate = false,
  isOnRead = false,
  isOnEdit = false
}) => {
  if (key === 'status') {
    if (
      roles.includes(AZ_WIKIPACK_PKACCEVO) ||
      roles.includes(AZ_WIKIPACK_QC) ||
      roles.includes(AZ_WIKIPACK_TCS) ||
      (roles.includes(AZ_WIKIPACK_MRKT) && isOnRead)
    ) {
      return false
    }
  }
  if (key === 'USDTargetPrice') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
  }
  if (key === 'MOQ') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
  }
  if (key === 'image') {
    if (
      (roles.includes(AZ_WIKIPACK_RDF) ||
        roles.includes(AZ_WIKIPACK_IE) ||
        roles.includes(AZ_WIKIPACK_SRC) ||
        roles.includes(AZ_WIKIPACK_PKACCEVO)) &&
      (isOnCreate || isOnEdit)
    ) {
      return false
    }
  }
  if (key === 'supplierRef') {
    if (
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      !roles.includes(AZ_DBPRODOTTO_ADMIN) &&
      !roles.includes(AZ_WIKIPACK_QC) &&
      !roles.includes(AZ_WIKIPACK_SRC) &&
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      (isOnCreate || isOnEdit)
    ) {
      return false
    }
    if (
      ((!roles.includes(AZ_WIKIPACK_SRC) &&
        !roles.includes(AZ_WIKIPACK_PKDEV) &&
        !roles.includes(AZ_WIKIPACK_QC) &&
        !roles.includes(AZ_DBPRODOTTO_ADMIN)) ||
        (roles.includes(AZ_WIKIPACK_QC) &&
          roles.includes(AZ_DBPRODOTTO_PACKAGING_READ))) &&
      !roles.includes(AZ_WIKIPACK_QC) &&
      !roles.includes(AZ_WIKIPACK_SRC) &&
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      isOnRead
    ) {
      return false
    }
  }
  if (key === 'variantSupplierCode') {
    if (
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      !roles.includes(AZ_DBPRODOTTO_ADMIN) &&
      !roles.includes(AZ_WIKIPACK_QC) &&
      !roles.includes(AZ_WIKIPACK_SRC) &&
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      (isOnCreate || isOnEdit)
    ) {
      return false
    }
    if (
      ((!roles.includes(AZ_WIKIPACK_SRC) &&
        !roles.includes(AZ_WIKIPACK_PKDEV) &&
        !roles.includes(AZ_WIKIPACK_QC) &&
        !roles.includes(AZ_DBPRODOTTO_ADMIN)) ||
        (roles.includes(AZ_WIKIPACK_QC) &&
          roles.includes(AZ_DBPRODOTTO_PACKAGING_READ))) &&
      isOnRead
    ) {
      return false
    }
  }
  if (key === 'testedSupplier') {
    if (
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      !roles.includes(AZ_DBPRODOTTO_ADMIN) &&
      !roles.includes(AZ_WIKIPACK_QC) &&
      !roles.includes(AZ_WIKIPACK_SRC) &&
      !roles.includes(AZ_WIKIPACK_PKDEV) &&
      (isOnCreate || isOnEdit)
    ) {
      return false
    }
    if (
      (!roles.includes(AZ_WIKIPACK_SRC) &&
        !roles.includes(AZ_WIKIPACK_PKDEV) &&
        !roles.includes(AZ_WIKIPACK_QC) &&
        !roles.includes(AZ_DBPRODOTTO_ADMIN)) ||
      (roles.includes(AZ_WIKIPACK_QC) &&
        roles.includes(AZ_DBPRODOTTO_PACKAGING_READ) &&
        !roles.includes(AZ_WIKIPACK_QC) &&
        !roles.includes(AZ_WIKIPACK_SRC) &&
        !roles.includes(AZ_WIKIPACK_PKDEV) &&
        isOnRead)
    ) {
      return false
    }
  }
  if (key === 'priceUpdatedBy') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
    if (
      roles.includes(AZ_WIKIPACK_PKACCEVO) ||
      roles.includes(AZ_WIKIPACK_MRKT) ||
      roles.includes(AZ_WIKIPACK_RDF) ||
      roles.includes(AZ_WIKIPACK_QC) ||
      roles.includes(AZ_WIKIPACK_TCS) ||
      roles.includes(AZ_WIKIPACK_IE) ||
      roles.includes(AZ_WIKIPACK_SHUB)
    ) {
      return false
    }
  }
  if (key === 'priceUpdatedAt') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
    if (
      roles.includes(AZ_WIKIPACK_PKACCEVO) ||
      roles.includes(AZ_WIKIPACK_MRKT) ||
      roles.includes(AZ_WIKIPACK_RDF) ||
      roles.includes(AZ_WIKIPACK_QC) ||
      roles.includes(AZ_WIKIPACK_TCS) ||
      roles.includes(AZ_WIKIPACK_IE) ||
      roles.includes(AZ_WIKIPACK_SHUB)
    ) {
      return false
    }
  }
  if (key === 'priceUpdatedByLegalEntity') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
    if (
      roles.includes(AZ_WIKIPACK_PKACCEVO) ||
      roles.includes(AZ_WIKIPACK_MRKT) ||
      roles.includes(AZ_WIKIPACK_RDF) ||
      roles.includes(AZ_WIKIPACK_QC) ||
      roles.includes(AZ_WIKIPACK_TCS) ||
      roles.includes(AZ_WIKIPACK_IE) ||
      roles.includes(AZ_WIKIPACK_SHUB)
    ) {
      return false
    }
  }
  if (key === 'countryOfOriginCode') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
  }
  if (key === 'countryOfOriginDescription') {
    if (roles.includes(AZ_WIKIPACK_PKDEV) && (isOnCreate || isOnEdit)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_RDF) || roles.includes(AZ_WIKIPACK_IE)) {
      return false
    }
    if (roles.includes(AZ_WIKIPACK_SRC) && isOnCreate) {
      return false
    }
  }
  return true
}
