import { FC, useEffect, useState } from 'react'

import { Tabs } from 'antd'
import Form from 'antd/lib/form/Form'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { canUserEditProduct } from '@/utils/permissionsHelpers'

import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, InfoCell, Modal, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import FormulaSchemaTable from '../../containers/FormulaSchemaTable'
import { FORMULA_TABLE_CONFIG } from '../../containers/FormulaSchemaTable/constants'
import AdditionalDataForm from '../../containers/Product/AdditionalDataForm'
import AdditionalDataSideForm from '../../containers/Product/AdditionalDataSideForm'
import ProductDetail from '../../containers/Product/ProductDetail'
import ProductSpiderChart from '../../containers/Product/ProductSpiderChart'
import Success from '../../containers/Success'
import { appSliceActions } from '../../features/app'
import { packagingSliceActions } from '../../features/packaging'
import { PACKAGE_TYPE } from '../../features/packaging/model'
import { productStatusDropdownValues } from '../../features/product/constants'
import { productsActions, productsSliceActions } from '../../features/products'
import { characteristicsMappedLabels } from '../../features/products/constants'
import {
  ProductDto,
  TProductCharacteristics,
  TProductDtoCharacteristics
} from '../../features/products/model'
import {
  getProductTemp,
  getProductsDetail,
  getProductsDetailName,
  selectLoader,
  selectProductsError,
  selectSuccessInfo
} from '../../features/products/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'
import { getProductStatusMessage } from '../../utils/productHelpers'
import { AppRoutes } from '../constants'

import { Asterisk, ButtonsWrapper, DeleteMessage } from './styled'

const ProductRTGDetail: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userRoles = useSelector(selectUserRole)
  const productsError = useSelector(selectProductsError)
  const productDetail = useSelector(getProductsDetail)
  const successInfo = useSelector(selectSuccessInfo)
  const tempDetail = useSelector(getProductTemp)
  const isLoading = useSelector(selectLoader)
  const { id } = useParams()
  const productName = useSelector(getProductsDetailName)
  const [isOnEdit, setIsOnEdit] = useState(false)
  const isMulti = get(productDetail, 'type', '')?.toLowerCase() === PACKAGE_TYPE.MULTI
  const productTempSides = get(tempDetail, 'productSides', [])

  const prodCharacteristics: TProductDtoCharacteristics = get(
    productDetail,
    'characteristics',
    {}
  )

  const characteristicList = Object.keys(prodCharacteristics)

  const status = get(tempDetail, 'status', '')

  useEffect(() => {
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    return () => {
      dispatch(productsSliceActions.clearError())
      dispatch(productsSliceActions.clearSuccess())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(productsActions.getDetailsAndOthers({ id }))
    }
    return () => {
      dispatch(productsSliceActions.resetDetail())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const cancelHandler = () => {
    setIsOnEdit(false)
    dispatch(productsSliceActions.clearTemp())
  }

  const saveHandler = () => {
    if (statusMessage && status === productStatusDropdownValues.READY_TO_GO) {
      dispatch(
        appSliceActions.openPopUp({
          title: 'Error',
          key: 'product-status-popup',
          message: statusMessage
        })
      )
    } else {
      setIsOnEdit(false)
      dispatch(productsActions.updateProduct())
    }
  }

  const editHandler = () => {
    setIsOnEdit(true)
    dispatch(productsActions.initTemp())
  }

  const updateHandler = (key: string, value: any) => {
    dispatch(productsSliceActions.updateTemp({ key: key as keyof ProductDto, value }))
  }

  const updateCharacteristicsHandler = (charKey: string, value: number) => {
    const tempProdCharacteristics = get(tempDetail, 'characteristics', {})
    const newValue = {
      ...tempProdCharacteristics,
      [charKey]: value
    }
    updateHandler('characteristics', newValue)
  }

  const updateSideHandler = (sideIdx: number, key: string, value: any) => {
    const newSides = [...productTempSides]
    newSides[sideIdx] = {
      ...newSides[sideIdx],
      [key]: value
    }

    dispatch(productsSliceActions.updateTemp({ key: 'productSides', value: newSides }))
  }

  const getFormulaInfo = (sideIdx: number) => ({
    formula: get(productTempSides[sideIdx], 'formulaSchema.formula', []),
    formulaSelected: get(productTempSides[sideIdx], 'formulaSelected', [])
  })

  const hasIndustrialCode = getFormulaInfo(0).formulaSelected.some(
    (formula: any) => !!formula.formulaCodeDEVEX
  )

  const statusMessage = getProductStatusMessage(hasIndustrialCode)

  const selectHandler = (sideIdx: number, selectedRows: Record<string, any>[]) => {
    updateSideHandler(sideIdx, 'formulaSelected', selectedRows)
  }

  const openDeleteWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: '',
        key: 'popup-delete-warning',
        message: (
          <DeleteMessage>
            Please proceed with permanent deletion of the product only in case it has been
            created by mistake. In the following cases instead please only change the
            product status:
            <ul>
              <li>Ready To Go: Sellable as-is by Sales and Marketing Teams</li>
              <li>
                Level 2 in progress: Product not RTG as testing process not completed
              </li>
              <li>
                Archive: RTG products that must not be promoted by Sales and Marketing
                Teams as obsolete
              </li>
              <li>
                Client Exclusive: Products that have been granted in exclusive to a client
              </li>
              <li>Not Stable: Products that have not passed stability tests</li>
              <li>
                Not Compatible or Industrializable: Products that do not report stability
                issues but have not passed compatibility tests or industrialization phase
              </li>
            </ul>
          </DeleteMessage>
        ),

        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              dispatch(productsActions.deleteProduct())
            }}
          />
        ]
      })
    )
  }

  const openSaveWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Are you sure you want to save the changes?',
        key: 'popup-save-warning',
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              saveHandler()
            }}
          />
        ]
      })
    )
  }

  const checkFormulaValidation = () =>
    productTempSides.every((side: any) => get(side, 'formulaSelected', []).length)

  const isSaveDisable = !tempDetail?.status || !checkFormulaValidation()
  const canEdit = canUserEditProduct({ userRoles })

  const getCharacteristicsHandler = () => {
    if (productDetail) {
      dispatch(
        productsActions.getSpiderChartCharacteristics({
          businessSegment: get(productDetail, 'businessSegment', ''),
          productCategory: get(productDetail, 'productCategory', ''),
          loader: 'modal'
        })
      )
    }
  }

  return (
    <GenericLayout error={productsError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Breadcrumb
          items={[
            {
              name: 'Product',
              href: AppRoutes.VAULT_PRODUCTS_RESULTS
            },
            {
              name: `Product detail ${productName || '-'}`
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && !successInfo && (
          <>
            {productDetail && !isOnEdit && (
              <>
                <ButtonsWrapper>
                  {/* NOTE - temporary hidden */}
                  {/* {!isMulti && (
                    <Modal
                      modalKey={'spider-chart'}
                      btnType="ghost"
                      btnName="Spider Chart"
                      size={characteristicList.length ? 'xLarge' : 'small'}
                      isSmall={!characteristicList.length}
                      title={characteristicList.length ? 'Spider Chart' : ''}
                      centered
                      btnClicked={getCharacteristicsHandler}
                    >
                      <ProductSpiderChart dataList={[productDetail]} />
                    </Modal>
                  )} */}
                  {canEdit && <Button label="Edit" onClick={editHandler} />}
                </ButtonsWrapper>
                {productDetail && <ProductDetail data={productDetail} />}
              </>
            )}
            {tempDetail && isOnEdit && (
              <>
                <ButtonsWrapper>
                  <Button
                    key="cancel"
                    label="Cancel"
                    onClick={cancelHandler}
                    variant="ghost"
                  />
                  <Button
                    key="save"
                    label="Save"
                    onClick={openSaveWarning}
                    disabled={isSaveDisable}
                  />
                  <Button
                    key="delete"
                    label="Delete"
                    onClick={openDeleteWarning}
                    variant="text"
                    iconName="DeleteOutlined"
                  />
                </ButtonsWrapper>
                <AdditionalDataForm
                  data={tempDetail as ProductDto}
                  onUpdate={updateHandler}
                  isMulti={tempDetail?.productSides?.length > 1}
                />
                {isMulti && (
                  <Tabs>
                    {productTempSides.map((sideData: any, sideIdx: number) => (
                      <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                        <AdditionalDataSideForm
                          isReady={
                            get(tempDetail, 'status', '') ===
                            productStatusDropdownValues.READY_TO_GO
                          }
                          data={sideData}
                          onUpdate={(key, value) =>
                            updateSideHandler(sideIdx, key, value)
                          }
                          isMascara={
                            get(tempDetail, 'businessSegment', '')?.toLowerCase() ===
                            'mascara'
                          }
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                )}
                {!isMulti && (
                  <AdditionalDataSideForm
                    isReady={
                      get(tempDetail, 'status', '') ===
                      productStatusDropdownValues.READY_TO_GO
                    }
                    data={{
                      ...productTempSides[0],
                      stabilityStartDate:
                        productTempSides[0].stabilityStartDate ||
                        tempDetail.stabilityStartDate,
                      innovationLevel:
                        productTempSides[0].innovationLevel || tempDetail.innovationLevel,
                      bulkLevel: productTempSides[0].bulkLevel || tempDetail.bulkLevel,
                      compatibility:
                        productTempSides[0].compatibility || tempDetail.compatibility,
                      industrialLevel:
                        productTempSides[0].industrialLevel || tempDetail.industrialLevel,
                      packagingLevel:
                        productTempSides[0].packagingLevel || tempDetail.packagingLevel
                    }}
                    onUpdate={(key, value) => updateSideHandler(0, key, value)}
                    isMascara={
                      get(tempDetail, 'businessSegment', '')?.toLowerCase() === 'mascara'
                    }
                    hideCollection
                    hideLaunchYear
                  />
                )}
                {!isMulti && (
                  <>
                    <SectionTitle value="Characteristics" />
                    <Divider disableBorder />
                    <Form layout="vertical">
                      <BoxWrapper enablePadding>
                        {characteristicList.map((charKey) => (
                          <InfoCell
                            label={get(characteristicsMappedLabels, charKey, '')}
                            value={get(tempDetail, `characteristics.${charKey}`, '')}
                            isEditing={isOnEdit}
                            inputType="number"
                            inputConfig={{
                              onChange: (value: any) =>
                                updateCharacteristicsHandler(charKey, +value),
                              errorMessage: 'The characteristic max value is 10',
                              min: 0,
                              max: 10,
                              hideSideBtn: true
                            }}
                            dataType="number"
                          />
                        ))}
                      </BoxWrapper>
                    </Form>
                  </>
                )}
                <Divider disableBorder />
                <SectionTitle
                  color={checkFormulaValidation() ? 'black' : 'red'}
                  value={
                    <>
                      <Asterisk>*</Asterisk> Formula Schema Data
                    </>
                  }
                />
                {!isMulti && (
                  <FormulaSchemaTable
                    tableConfig={FORMULA_TABLE_CONFIG}
                    dataList={getFormulaInfo(0).formula}
                    selectedRows={getFormulaInfo(0).formulaSelected}
                    enableSelect
                    onSelect={(selectedRows) => selectHandler(0, selectedRows)}
                  />
                )}
                {isMulti && (
                  <Tabs>
                    {productTempSides.map((_: any, sideIdx: number) => (
                      <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                        <FormulaSchemaTable
                          tableConfig={FORMULA_TABLE_CONFIG}
                          dataList={getFormulaInfo(sideIdx).formula}
                          selectedRows={getFormulaInfo(sideIdx).formulaSelected}
                          enableSelect
                          onSelect={(selectedRows) =>
                            selectHandler(sideIdx, selectedRows)
                          }
                        />
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                )}
              </>
            )}
          </>
        )}
        {!isLoading && successInfo && (
          <Success
            data={successInfo}
            buttons={[
              {
                label: 'Back to the products',
                onClick: () => navigate(AppRoutes.VAULT_PRODUCTS_RESULTS)
              },
              {
                label: 'Back to home',
                onClick: () => navigate(AppRoutes.HOME),
                iconName: 'HomeOutlined',
                variant: 'text'
              }
            ]}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ProductRTGDetail
