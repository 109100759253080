import { FC, forwardRef } from 'react'
import { StyledPageHeader, StyledText } from './styled'
import { IPageTitle } from './types'

const PageTitle: FC<IPageTitle> = forwardRef<HTMLDivElement, IPageTitle>(
  ({ title = 'Page title', subtitle }, ref) => {
    return (
      <div ref={ref}>
        <StyledPageHeader title={title} />
        {subtitle && <StyledText text={subtitle} variant="h4" />}
      </div>
    )
  }
)

PageTitle.displayName = 'PageTitle'

export default PageTitle
