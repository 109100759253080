import {
  FORMULA_SCHEMA_STATUS_NOT_VISIBLE,
  FORMULA_STATUS_NOT_VISIBLE
} from '@/features/formulaSchema/constants'
import { Formula } from '@/features/formulaSchema/model'

import { TBeautifyPKIntercosCodeFunc, TRefactorPkCodesDropdownFunc } from './types'

export const checkIsFormulaSchemaStatusValid: (s: string) => boolean = (status: string) =>
  !FORMULA_SCHEMA_STATUS_NOT_VISIBLE.includes(status)

export const checkIsFormulaStatusValid: (s: string) => boolean = (status: string) =>
  !FORMULA_STATUS_NOT_VISIBLE.includes(status)

export const filterFormulaByStatus: (a: Formula[]) => Formula[] = (formulaList) =>
  formulaList ? formulaList.filter(({ status }) => checkIsFormulaStatusValid(status)) : []

export const beautifyPKIntercosCode: TBeautifyPKIntercosCodeFunc = ({
  packagingCode,
  variantCode
}) => {
  const varaintCodeString = variantCode ? `_${variantCode}` : ''
  return `${packagingCode}${varaintCodeString}`
}

export const refactorPkCodesDropdown: TRefactorPkCodesDropdownFunc = (dataList) => {
  return dataList.map((data) => {
    const pKIntercosCodeVal = beautifyPKIntercosCode(data)
    return {
      name: pKIntercosCodeVal,
      value: data.id,
      packageCategory: data.packageCategory,
      sideCollectorId: data.sideCollectorId,
      packageVariantId: data.packageVariantId
    }
  })
}
