import { FC } from 'react'

import { Checkbox as AntdCheckbox, Form } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { InfoCell } from '../../../../components'

import { InputWrapper, TargetPriceLabel, TargetPriceSearchFormContainer } from './styled'
import { ITargetPriceSearchFormProps } from './types'

const TargetPriceSearchForm: FC<ITargetPriceSearchFormProps> = ({ data, onUpdate }) => {
  return (
    <TargetPriceSearchFormContainer className="tartget-price-search-container">
      <TargetPriceLabel>Target Price</TargetPriceLabel>
      <InputWrapper>
        <InfoCell
          label="Min"
          value={data.targetPriceMin}
          isEditing
          inputType="number"
          inputConfig={{
            onChange: (value: number) => onUpdate({ key: 'targetPriceMin', value }),
            hideSideBtn: true,
            disabled: data.nullPricesOnly,
            min: 0
          }}
        />
        <InfoCell
          label="Max"
          value={data.targetPriceMax}
          isEditing
          inputType="number"
          inputConfig={{
            onChange: (value: number) => onUpdate({ key: 'targetPriceMax', value }),
            min: data.targetPriceMin,
            hideSideBtn: true,
            disabled: data.nullPricesOnly
          }}
        />
        <Form.Item>
          <label>Null</label>
          <AntdCheckbox
            checked={data.nullPricesOnly}
            onChange={(e: CheckboxChangeEvent) =>
              onUpdate({ key: 'nullPricesOnly', value: e.target.checked })
            }
          />
        </Form.Item>
      </InputWrapper>
    </TargetPriceSearchFormContainer>
  )
}

export default TargetPriceSearchForm
