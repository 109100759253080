import { Tag as TagAntd } from 'antd'
import { FC } from 'react'
import { StyledTagBox, TagValue } from './styled'
import { ITagProps } from './types'

export const Tag: FC<ITagProps> = ({ value, onRemove, path, closable }) => {
  return (
    <StyledTagBox className="custom-tag-container">
      <TagAntd
        className="custom-tag"
        closable={closable}
        onClose={() => onRemove && onRemove(path)}
      >
        <TagValue closable={closable}>{value}</TagValue>
      </TagAntd>
    </StyledTagBox>
  )
}
