import { createAction } from '@reduxjs/toolkit'

import { fSchemaActionTypes } from './constants'
import {
  Formula,
  IAddOtherTestedPackagingActionPayload,
  IEditOtherTestedPackagingActionPayload
} from './model'

export const fSchemaActions = {
  getFormulaSchema: createAction(fSchemaActionTypes.FORMULA_SCHEMA_GET_FORMULA),
  getFSchemaDetail: createAction<{ id: string; hasLoader?: boolean }>(
    fSchemaActionTypes.FORMULA_SCHEMA_GET_DETAIL
  ),
  getPKCodeDropdownItems: createAction<{ pkCode: string }>(
    fSchemaActionTypes.GET_PK_DROPDOWN
  ),
  updateFSchema: createAction(fSchemaActionTypes.FORMULA_SCHEMA_UPDATE),
  saveFSchemaFormula: createAction<{ data: Formula }>(
    fSchemaActionTypes.FSCHEMA_CONFIRM_UPDATE_FORMULA
  ),
  addOtherTestedPackaging: createAction<IAddOtherTestedPackagingActionPayload>(
    fSchemaActionTypes.ADD_OTHER_TESTED_PACKAGING
  ),
  patchOtherTestedPackaging: createAction<IEditOtherTestedPackagingActionPayload>(
    fSchemaActionTypes.PATCH_OTHER_TESTED_PACKAGING
  ),
  deleteOtherTestedPackaging: createAction<string>(
    fSchemaActionTypes.DELETE_OTHER_TESTED_PACKAGING
  ),
  addTestedClaim: createAction<{ data: any; attachment: any }>(
    fSchemaActionTypes.ADD_TESTED_CLAIM
  ),
  patchTestedClaim: createAction<{ data: any }>(fSchemaActionTypes.PATCH_TESTED_CLAIM),
  deleteTestedClaim: createAction<string>(fSchemaActionTypes.DELETE_TESTED_CLAIM),
  getTestedClaimTaxonomy: createAction(fSchemaActionTypes.GET_TESTED_CLAIM_TAXONOMY)
}
