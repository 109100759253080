import { TProductCharacteristicsMappedLabel } from './model'

export const PRODUCTS_SEARCH = 'products/PRODUCTS_SEARCH'
export const PRODUCTS_CHANGE_PAGE = 'products/PRODUCTS_CHANGE_PAGE'
export const PRODUCTS_GET_DETAILS = 'products/PRODUCTS_GET_DETAILS'
export const PRODUCTS_GET_DETAILS_AND_OTHERS = 'products/PRODUCTS_GET_DETAILS_AND_OTHERS'
export const PRODUCTS_UPDATE = 'products/PRODUCTS_UPDATE'
export const PRODUCTS_DELETE = 'products/PRODUCTS_DELETE'
export const PRODUCTS_INIT_TEMP = 'products/PRODUCTS_INIT_TEMP'
export const PRODUCTS_EXPORT_EXCEL = 'products/exportExcel'
export const GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS =
  'products/GET_PRODUCTS_SPIDER_CHART_CHARACTERISTICS'
export const GET_SCATTER_CHARACTERISTICS = 'products/GET_PRODUCTS_CHARACTERISTICS'

export const characteristicsMappedLabels: TProductCharacteristicsMappedLabel = {
  adherence: 'Adherence',
  blendability: 'Blendability',
  blurring: 'Blurring',
  buildability: 'Buildability',
  colorPurity: 'Colorpurity',
  comfort: 'Comfort',
  coverageCharacteristic: 'Coverage',
  coverageCharacteristicH: 'Coverage H',
  creaminess: 'Creaminess',
  curl: 'Curl',
  dusting: 'Dusting',
  fallOut: 'Fallout',
  finish: 'Finish',
  finishH: 'Finish H',
  flexibility: 'Flexibility',
  gliding: 'Gliding',
  glidingH: 'Gliding H',
  homogeneity: 'Homogeneity',
  length: 'Length',
  luminosity: 'Luminosity',
  luminosityH: 'Luminosity H',
  payoff: 'Payoff',
  pearly: 'Pearly',
  precision: 'Precision',
  richness: 'Richness',
  sensoriality: 'Sensoriality',
  sensorialityH: 'Sensoriality H',
  separation: 'Separation',
  settingTime: 'Setting Time',
  silkiness: 'Silkiness',
  smoothness: 'Smoothness',
  stickiness: 'Stickiness',
  superficiality: 'Superficiality',
  thicknessOverTime: 'Thickness Overtime',
  transferResistance: 'Transfer Resistance',
  volume: 'Volume'
}
