import { FC } from 'react'

import { Form } from 'antd'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { canUserReadFormulaSchemaData } from '@/utils/permissionsHelpers'

import { BoxWrapper } from '../../../components/BoxWrapper'
import InfoCell from '../../../components/InfoCell'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import { checkIsFormulaSchemaStatusValid } from '../../../utils/formulaSchemaHelpers'

import { IFormulaSchemaDataProps } from './types'

const FormulaSchemaData: FC<IFormulaSchemaDataProps> = ({ data }) => {
  const userRoles = useSelector(selectUserRole)

  const canSeeDetail = canUserReadFormulaSchemaData({ userRoles })

  const status = get(data, 'status', '')

  const isFormulaSchemaReady = checkIsFormulaSchemaStatusValid(status)

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          label="Formula Schema Code"
          dataType={canSeeDetail && isFormulaSchemaReady ? 'link' : 'string'}
          value={get(data, 'formulaSchemaCode', '')}
          linkConfig={{
            path: `${AppRoutes.VAULT_FORMULA_SCHEMA}/${get(data, 'id', '')}`
          }}
        />
        <InfoCell label="FS Status" value={get(data, 'status', '')} />
        <InfoCell label="IC PAO" value={get(data, 'icPao', '')} />
        <InfoCell label="IC Shelf Life" value={get(data, 'icShelfLife', '')} />
        <InfoCell label="FS Finish" value={get(data, 'fsFinish', '')} />
      </BoxWrapper>
    </Form>
  )
}

export default FormulaSchemaData
