import { InputNumber } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from '../../../components'
import { Divider } from '../../../components/Divider'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { Title } from '../../../components/Title'
import { Tooltip } from '../../../components/Tooltip'
import { administrationActions } from '../../../features/administration'
import { selectCurrencyRates } from '../../../features/administration/selectors'
import { adminColumnGenerator } from '../../../utils/adminHelpers'
import { TExchangeRateUSDRendererFunc } from '../../../utils/adminHelpers/types'

const PricingTable: FC = () => {
  const dispatch = useDispatch()

  // useSelector variables
  const currencyRates = useSelector(selectCurrencyRates)

  const mapTableData = (currencyRates: Record<string, any>[]) => {
    if (currencyRates)
      return currencyRates.reduce((acc: any, curr: any) => {
        const id = curr.id
        acc[id] = curr.USDExchangeRate
        return acc
      }, {})
  }

  // useState variables
  const [editingId, setEditingId] = useState('')
  const [mappedExchangeValues, setMappedExchangeValues] = useState(
    mapTableData(currencyRates || [])
  )

  const exchangeRateUSDRenderer: TExchangeRateUSDRendererFunc = (
    USDExchangeRate,
    data
  ) => {
    return (
      <InputNumber<string>
        type="number"
        step="0.00001"
        max="9999.99999"
        stringMode
        value={mappedExchangeValues[data.id] || USDExchangeRate}
        min="0.00001"
        disabled={!editingId || editingId !== data.id}
        onChange={(value) => {
          setMappedExchangeValues({
            ...mappedExchangeValues,
            [data.id]: value
          })
        }}
      />
    )
  }

  const columns = adminColumnGenerator({ exchangeRateUSDRenderer })

  useEffect(() => {
    dispatch(administrationActions.getCurrencyRates())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actionConfig = {
    columnTitle: '',
    actionRenderer: ({ data }: any) => (
      <>
        {editingId !== data.id && (
          <Tooltip title={`Edit Exchange Rate for ${data.currency}`}>
            <Button
              onClick={() => {
                editHandler(data.id)
              }}
              iconName="FormOutlined"
              variant="link"
              disabled={!!editingId || data.currency === 'USD'}
            />
          </Tooltip>
        )}
        {editingId === data.id && (
          <>
            <Button
              onClick={cancelHandler}
              iconName="CloseCircleOutlined"
              variant="link"
            />
            <Button onClick={saveHandler} iconName="SaveOutlined" variant="link" />
          </>
        )}
      </>
    )
  }

  const editHandler = (id: string) => {
    setEditingId(id)
  }
  const cancelHandler = () => {
    setEditingId('')
    setMappedExchangeValues(mapTableData(currencyRates || []))
  }
  const saveHandler = () => {
    setEditingId('')
    dispatch(
      administrationActions.setCurrencyRate({
        id: editingId,
        data: mappedExchangeValues[editingId]
      })
    )
  }

  return (
    <>
      <Title title={'Pricing'} variantTitle={'h3'} enableBold />
      <Divider disableBorder />
      <Table
        enablePagination={false}
        paginationPosition={TOP_RIGHT}
        rowKey="id"
        columns={columns}
        items={currencyRates || []}
        actionColumnConfig={actionConfig}
      />
    </>
  )
}

export default PricingTable
