import { Divider } from 'antd'
import { isEmpty, unset } from 'lodash'
import { FC } from 'react'
import { TooltipBtn } from '../../components'
import { ActionsAttachs } from '../../components/Table/attachments'
import { ITActionColumnConfig } from '../../components/Table/types'
import { Text } from '../../components/Text'
import { Tooltip } from '../../components/Tooltip'
import { IAttachment } from '../../features/packaging/model'
import AttachmentsTable from '../AttachmentsTable'
import { TagsContainer } from '../TagsContainer'
import AddModal from './AddModal'
import AttachFilterModal from './AttachFilterModal'
import { AddBtnWrapper, ButtonsWrapper, EmptyWrapper, TagsWrapper } from './styled'
import SyncModal from './SyncModal'
import { IAttachSectionProps } from './types'
import UpdateAttachModal from './UpdateAttachModal'

const AttachSection: FC<IAttachSectionProps> = ({
  dataList = [],
  tableConfig,
  hasDelBtn,
  onDelete = () => {},
  hasDownBtn = false,
  hasEditBtn = false,
  onUpdate = () => {},
  hasSyncBtn = false,
  onSync = () => {},
  hasAddNewVerBtn = false,
  onAddNewVersion = () => {},
  hasFilterBtn = false,
  filters = {},
  onUpdateFilter = () => {},
  addConfig,
  showEmptyOnTable,
  titleConfig = {}
}) => {
  const hasAdd = addConfig && !isEmpty(addConfig)

  const {
    btnLabel: addBtnLabel = 'Add',
    hasStatus: addHasStatus = false,
    confirmLabel: addConfirmStatus = 'Save',
    uploadTopMess: addUploadTopMess = 'Drop or browse the attachment',
    onAddAttachment = () => {},
    hasCancel: addHasCancel = false,
    isNameDisableByDefault = false,
    topAddHasIcon = false,
    requiredFields: addRequiredFields
  } = addConfig || {}

  const {
    value: titleValue = 'Attachments',
    variant: titleVariant = 'h4',
    isBold: titleIsBold = false
  } = titleConfig

  const removeTagHandler = (path: string) => {
    const filtersCopy = { ...filters }
    const cleanPath = path.replace('filters.', '')
    unset(filtersCopy, cleanPath)
    onUpdateFilter(filtersCopy)
  }

  const hasAllActions =
    hasAddNewVerBtn && hasEditBtn && hasSyncBtn && hasDownBtn && hasDelBtn

  const hasAnyAction =
    hasAddNewVerBtn || hasEditBtn || hasSyncBtn || hasDownBtn || hasDelBtn

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    width: hasAllActions ? 250 : 200,
    actionRenderer: ({ data }) => {
      return (
        <>
          {hasAddNewVerBtn && (
            <Tooltip title="Upload New Version">
              <AddModal
                data={data}
                key={`add-new-version-${data.id}`}
                modalKey={`add-new-version-${data.id}`}
                btnIcon="PlusOutlined"
                onConfirm={onAddNewVersion}
              />
            </Tooltip>
          )}
          {hasEditBtn && (
            <Tooltip title="Edit">
              <UpdateAttachModal
                data={data}
                onSave={onUpdate}
                modalKey={`update-attachment-${data.id}`}
              />
            </Tooltip>
          )}
          {hasSyncBtn && (
            <Tooltip title="Publish">
              <SyncModal data={data} onSync={onSync} />
            </Tooltip>
          )}
          {hasDownBtn && <ActionsAttachs url={data.url} />}
          {hasDelBtn && (
            <TooltipBtn
              message="Do you want to delete this attachment?"
              btnVariant="text"
              hasIcon
              iconName="DeleteOutlined"
              onConfirm={() => onDelete(data as IAttachment)}
            />
          )}
        </>
      )
    }
  }

  let filteredList = [...dataList]
  const { name = '', description = '', SAPCodes = '' } = filters
  filteredList = filteredList.filter((attachment) => {
    const nameInLower = name.toLowerCase()
    const filterByName =
      !nameInLower || attachment.name?.toLowerCase().includes(nameInLower)
    const descInLower = description.toLowerCase()
    const filterByDesc =
      !descInLower || attachment.description?.toLowerCase().includes(descInLower)
    const sapCodesInLower = attachment.SAPCodes
      ? attachment.SAPCodes?.map((sapCode) => sapCode.toLowerCase())
      : []
    const filterBySap = !SAPCodes || sapCodesInLower.includes(SAPCodes.toLowerCase())
    return filterByName && filterByDesc && filterBySap
  })

  const hasAddTop = hasAdd && !!filteredList.length
  const hasAddBottom = hasAdd && !filteredList.length
  const hasActionCol = filteredList.length && hasAnyAction

  const EmptyBody = (
    <EmptyWrapper>
      <Text text="There are no attachments" variant="h4" />
      {hasAddBottom && (
        <AddModal
          key={'add-new-attach-bottom'}
          modalKey={'add-new-attach-bottom'}
          subtitle='Please select the Information and add the new Attachment"'
          btnName={addBtnLabel}
          btnType="ghost"
          onConfirm={onAddAttachment}
          hasStatus={addHasStatus}
          confirmLabel={addConfirmStatus}
          uploadTopMess={addUploadTopMess}
          isContentTypeEnable
          hasCancel={addHasCancel}
          disableNameByDefault={isNameDisableByDefault}
          requiredFields={addRequiredFields}
        />
      )}
    </EmptyWrapper>
  )

  return (
    <>
      <Text text={titleValue} variant={titleVariant} bold={titleIsBold} />
      <Divider />
      <ButtonsWrapper>
        {hasFilterBtn && (
          <AttachFilterModal
            filtersData={filters}
            onApplyFilter={onUpdateFilter}
            modalKey="attachments-filters"
          />
        )}
        <TagsWrapper hasAdd={hasAddTop}>
          <TagsContainer
            tags={filters}
            inner={false}
            reducer={'filters'}
            flowName={'filters'}
            allowNoFilters
            basePath={'filters'}
            onRemoveTag={removeTagHandler}
          />
        </TagsWrapper>
        <AddBtnWrapper>
          {hasAddTop && (
            <AddModal
              key={'add-new-attach-top'}
              modalKey={'add-new-attach-top'}
              subtitle="Please select the Information and add the new Attachment"
              btnIcon={topAddHasIcon ? 'PlusCircleOutlined' : ''}
              btnName="Add"
              onConfirm={onAddAttachment}
              hasStatus={addHasStatus}
              confirmLabel={addConfirmStatus}
              uploadTopMess={addUploadTopMess}
              isContentTypeEnable
              hasCancel={addHasCancel}
              disableNameByDefault={isNameDisableByDefault}
              requiredFields={addRequiredFields}
            />
          )}
        </AddBtnWrapper>
      </ButtonsWrapper>
      {!showEmptyOnTable && !filteredList.length ? (
        EmptyBody
      ) : (
        <AttachmentsTable
          tableConfig={tableConfig}
          actionConfig={hasActionCol ? actionConfig : undefined}
          dataList={filteredList as IAttachment[]}
          emptyBody={EmptyBody}
        />
      )}
    </>
  )
}

export default AttachSection
