import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { Button, InfoCell, SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { EmptyState } from '../../../components/EmptyState'
import { FlexSection } from '../../../components/FlexSection'
import PackagingTable from '../../../containers/Packaging/PackagingTable'
import ToolingTable from '../../../containers/ToolingTable'
import { TOOLING_TABLE_CONFIG } from '../../../containers/ToolingTable/constants'
import VariantsTable from '../../../containers/VariantsTable'
import { PRODUCT_VARIANTS_TABLE_CONFIG } from '../../../containers/VariantsTable/constants'
import { CATEGORY, PACKAGE_TYPE } from '../../../features/packaging/model'
import AddPackagingModal from './AddPackagingModal'
import AddToolingModal from './AddToolingModal'
import { Asterisk } from './styled'
import { IDataPackagingProps } from './types'

const DataPackaging: FC<IDataPackagingProps> = ({
  onRemovePack = () => {},
  onRemoveTool = () => {},
  onSelectVariant = () => {},
  isByPack = false,
  isByTool = false,
  isPackSelected = false,
  isToolSelected = false,
  selectedPackage = {},
  selectedTooling = [],
  selectedVariants = [],
  onPackSelect = () => {},
  onToolSelect = () => {},
  isByPackRef = false,
  isByToolRef = false,
  isPackRefEnable = false,
  isToolRefEnable = false,
  searchPackBtnLabel = 'SEARCH PACKAGING',
  searchToolBtnLabel = 'SEARCH TOOLING',
  data = {},
  onUpdate = () => {},
  onPackRefClick,
  onToolRefClick
}) => {
  const isMulti = get(selectedPackage, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
  const packageFamilies = get(selectedPackage, 'packageFamilies', [])

  const deletePackHandler = () => {
    onRemovePack()
  }

  const deleteToolHandler = () => {
    onRemoveTool()
  }

  const selectHandler = (sideIdx: number, selected: any[]) => {
    onSelectVariant(sideIdx, selected)
  }

  const checkSlectedVariantValidation: (i: number) => boolean = (sideIdx) => {
    const variantSlectedById = selectedVariants[sideIdx]
    return !!variantSlectedById.id
  }

  const packagingCode = get(data, 'packagingCode', '')
  // TODO - toolingCode is temporary still not sure which ref to use
  const toolingCode = get(data, 'toolingCode', '')

  return (
    <>
      {!isPackSelected && !isToolSelected && !isPackRefEnable && !isToolRefEnable && (
        <>
          <SectionTitle value="Packaging" />
          <Divider />

          <EmptyState title="No data" />
        </>
      )}

      {isPackSelected && (
        <>
          <SectionTitle
            value="Selected Package"
            addon={
              <Button
                label="Delete"
                iconName="DeleteOutlined"
                onClick={deletePackHandler}
                variant="text"
              />
            }
          />
          <PackagingTable dataList={[selectedPackage]} />
          <Divider disableBorder />
        </>
      )}

      {isToolSelected && (
        <>
          <SectionTitle
            value="Selected Tooling"
            addon={
              <Button
                label="Delete"
                iconName="DeleteOutlined"
                onClick={deleteToolHandler}
                variant="text"
              />
            }
          />
          <ToolingTable
            enableActions={false}
            dataList={selectedTooling}
            tableConfig={TOOLING_TABLE_CONFIG}
          />
        </>
      )}

      <br />
      <FlexSection justifyContent="center" flexDirection={undefined}>
        {isByPack && !isPackSelected && (
          <AddPackagingModal onAdd={onPackSelect} btnLabel={searchPackBtnLabel} />
        )}
        {isByPackRef && <Button label="ADD PACKAGING" onClick={onPackRefClick} />}
        {isByTool && !isToolSelected && (
          <AddToolingModal onAdd={onToolSelect} btnLabel={searchToolBtnLabel} />
        )}
        {isByToolRef && <Button label="ADD PANS ET ALIA" onClick={onToolRefClick} />}
      </FlexSection>

      {packageFamilies.map((family: Record<string, any>, idx: number) => {
        const isVials = get(family, 'category', '').toLowerCase() === CATEGORY.VIALS
        const variantData = get(family, 'packageVariants', []).map(
          ({ vialDetails, variantCode, id }: any) => ({
            ...vialDetails,
            variantCode,
            idPackaging: id
          })
        )

        return (
          <>
            {isPackSelected && isVials && (
              <div key={`${family?.id}-${idx}`}>
                {isMulti && (
                  <SectionTitle
                    color={checkSlectedVariantValidation(idx) ? 'black' : 'red'}
                    value={
                      <>
                        <Asterisk>*</Asterisk>
                        {`Side ${idx + 1}`}
                      </>
                    }
                  />
                )}
                <VariantsTable
                  dataList={variantData}
                  tableConfig={PRODUCT_VARIANTS_TABLE_CONFIG}
                  enableSelect
                  selectedRows={[selectedVariants[idx]]}
                  onSelect={(selectedKeys) => selectHandler(idx, selectedKeys)}
                  isSingleSelect
                />
                <Divider disableBorder />
              </div>
            )}
          </>
        )
      })}
      {isPackRefEnable && (
        <Form layout="vertical">
          <BoxWrapper>
            <InfoCell
              label="PK Intercos Code"
              value={packagingCode}
              isEditing
              inputConfig={{
                onChange: (value: string) => onUpdate('packagingCode', value)
              }}
            />
          </BoxWrapper>
        </Form>
      )}
      {isToolRefEnable && (
        <Form layout="vertical">
          <BoxWrapper>
            <InfoCell
              label="Tooling Code"
              value={toolingCode}
              isEditing
              inputConfig={{
                onChange: (value: string) => onUpdate('toolingCode', value)
              }}
            />
          </BoxWrapper>
        </Form>
      )}
    </>
  )
}

export default DataPackaging
