import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { Text } from '../../../components/Text'
import { DisclaimerContainer } from './styled'
import { IDetailInformationProps } from './types'

const DetailInformation: FC<IDetailInformationProps> = ({ data, isMulti, isReady }) => {
  const bussinessSegment: string = get(data, 'businessSegment', '')
  const isMascara = bussinessSegment.toLowerCase() === 'mascara'
  const url = `${window.location.href}`
  const wikipack = url.includes('wikipack')

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        {!isMulti && (
          <InfoCell label={'Business Unit'} value={get(data, 'businessUnit', '')} />
        )}
        {!isMulti && (
          <InfoCell label={'Business Segment'} value={get(data, 'businessSegment', '')} />
        )}

        <InfoCell
          label={'Application Area'}
          value={get(data, 'applicationArea', '')}
          tooltipConfig={{
            enable: true,
            message:
              'Section refers to the different areas where a product is used and applied',
            position: 'topLeft'
          }}
        />
        <InfoCell
          label={'Product Category'}
          value={get(data, 'productCategory', '')}
          tooltipConfig={{
            enable: true,
            message:
              'Section refers to a specific group of products grouped by specific features and/or elements',
            position: 'topLeft'
          }}
        />
        <InfoCell label={'Product Form'} value={get(data, 'productForm', '')} />

        {isMulti && (
          <>
            <InfoCell
              label={'SPF DevEx'}
              value={get(data, 'SPF', '') || get(data, 'fSchema.fsSpf', '')}
            />
            <InfoCell
              label={'PD Launch Year'}
              value={
                get(data, 'pdLaunchYear') ||
                get(data, 'productSides[0].pdLaunchYear') ||
                get(data, 'productSides[0].formulaSchema.launchYear') ||
                get(data, 'sides[0].fSchema.launchYear')
              }
            />
            <InfoCell
              label={'PD Collection'}
              value={
                get(data, 'pdCollection') ||
                get(data, 'productSides[0].pdCollection') ||
                get(data, 'productSides[0].formulaSchema.collection') ||
                get(data, 'sides[0].fSchema.collection')
              }
            />
          </>
        )}

        <InfoCell
          label={'Filling Method Cycle'}
          value={get(data, 'fillingMethodCycle', '')}
        />
        <InfoCell
          label={'Internal Lasting Positioning'}
          value={get(data, 'internalLastingPosition', '')}
          tooltipConfig={{
            enable: true,
            message: 'Last internal test refers to the wear - when available'
          }}
        />
        <InfoCell
          label={'Intercos Inside'}
          value={get(data, 'intercosInside', null)}
          tooltipConfig={{
            enable: true,
            message: 'Already sold to customers/clients',
            position: 'topLeft'
          }}
        />
        <InfoCell
          label={'Intercos Inside Positioning'}
          value={get(data, 'intercosInsidePositioning', '')}
        />
        <InfoCell label={'Market Bench'} value={get(data, 'marketBench', '')} />
        <InfoCell
          label={'First Production Origin'}
          value={get(data, 'firstProductionOrigin', '')}
          tooltipConfig={{
            enable: true,
            message:
              'This refers to the county in which the first Industrial code presented was originally produced'
          }}
        />
        <InfoCell
          label={'Formula Transfer To'}
          value={get(data, 'formulaTransferTo', '')}
        />
        {!isMulti && (
          <InfoCell
            label={'Filling Available In'}
            value={get(data, 'fillingAvailableIn', '')}
            tooltipConfig={{
              enable: true,
              message:
                'This field refers to filling machinery availability, not to tooling that has to be verified by case'
            }}
          />
        )}
        {!isMulti && (
          <InfoCell label={'SAP Offer Code'} value={get(data, 'sapOfferCode', '')} />
        )}
        {isMascara && (
          <InfoCell
            label={'Mascara Positioning'}
            value={get(data, 'mascaraPositioning', '')}
          />
        )}
        <InfoCell label={'Opaque Packaging'} value={get(data, 'opaquePackaging', null)} />
        <InfoCell label={'Note'} value={get(data, 'note', '')} />
      </BoxWrapper>

      {/* NOTE - Temporary hidden */}
      {/* {isMulti && <InfoCell label={'Coverage'} value={get(data, 'coverage', '')} />} */}

      {!isReady && (
        <DisclaimerContainer>
          <Divider />
          <Text
            text="Innovation level scores going from A meaning innovative to AAA extremely innovative. Bulk, Packaging, Compatibility and Industrial level scores going from 1 meaning Ready to Go to 3 meaning explorative phase"
            italic
          />
        </DisclaimerContainer>
      )}
      <BoxWrapper enablePadding>
        {!isReady && !wikipack && (
          <>
            <InfoCell
              label={'Stability Start Date'}
              dataType="date"
              value={get(data, 'stabilityStartDate', null)}
            />
            <InfoCell
              label={'Innovation Level'}
              value={get(data, 'innovationLevel', null)}
            />
            <InfoCell label={'Bulk Level'} value={get(data, 'bulkLevel', null)} />
            <InfoCell
              label={'Compatibility Level'}
              value={get(data, 'compatibility', null)}
            />
            <InfoCell
              label={'Industrial Level'}
              value={get(data, 'industrialLevel', null)}
            />
            <InfoCell
              label={'Packaging Level'}
              value={get(data, 'packagingLevel', null)}
            />
          </>
        )}
      </BoxWrapper>
    </Form>
  )
}

export default DetailInformation
