import { Collapse } from 'antd'
import styled from 'styled-components'
import { largeSizeWidth } from '../../../assets/mixins'

export const SelectTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SelectTypeContainer = styled.div`
  display: flex;
  h4 {
    margin: 0px;
  }
  div {
    margin: 0px;
    margin-left: 10px;
  }
`
export const StyledAccordion = styled(Collapse)`
  & .ant-collapse-header,
  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${largeSizeWidth}px) {
    padding: 0 8px;
    flex-wrap: nowrap;
    .tartget-price-search-container {
      padding: 0 2% !important;
    }
  }
`
