import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { footerHeight, headerHeight } from '../../../assets/mixins'
import { PageContent } from '../../../containers'
import ToolingResult from '../../../containers/Tooling/ToolingResult'
import { toolingActions, toolingSliceActions } from '../../../features/tooling'
import { selectToolingError } from '../../../features/tooling/selectors'
import { GenericLayout } from '../../../layouts'

const ToolingResultPage: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const error = useSelector(selectToolingError)

  useEffect(() => {
    dispatch(toolingActions.getTooling())
    return () => {
      dispatch(toolingSliceActions.clearError())
    }
  }, [dispatch, navigate])

  return (
    <GenericLayout error={error}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <ToolingResult />
      </PageContent>
    </GenericLayout>
  )
}

export default ToolingResultPage
