import styled from 'styled-components'

export const TargetPriceSearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-form-item {
    width: 33%;
  }
  .ant-form-item {
    display: flex;
    flex-direction: row !important;
  }
  .ant-form-item-control {
    flex: 1;
    margin-right: 16px;
  }
  label {
    padding-right: 8px;
  }
  .ant-form-item-label {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
  }
`

export const TargetPriceLabel = styled.label`
  padding-bottom: 8px;
`
