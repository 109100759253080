import { CATEGORY, OTHER_PACKAGES } from './model'

export const NEW_PACKAGE_IMAGE_KEY = 'new-package-image'

export const PACKAGING_GET_DETAIL = 'packaging/detail/PACKAGING_GET_DETAIL'
export const PACKAGING_REMOVE_PACKAGE = 'packaging/REMOVE_PACKAGE'
export const PACKAGING_COPY_PACKAGE = 'packaging/copyPackage'
export const PACKAGING_UPDATE_PACKAGE = 'packaging/PACKAGING_UPDATE_PACKAGE'
export const PACKAGING_GET_PACKAGES = 'packaging/PACKAGING_GET_PACKAGES'
export const PACKAGING_CREATE = 'packaging/createPackage'
export const PACKAGING_DELETE_ATTACHMENT = 'packaging/deleteAttachment'
export const PACKAGING_REMOVE_ATTACHMENT = 'packaging/PACKAGING_REMOVE_ATTACHMENT'
export const PACKAGING_UPDATE_VARIANT_ATTACHMENT =
  'packaging/PACKAGING_UPDATE_VARIANT_ATTACHMENT'
export const PACKAGING_SAVE_NEW_VARIANT_VERSION =
  'packaging/PACKAGING_SAVE_NEW_VARIANT_VERSION'
export const PACKAGING_CREATE_VARIANT_IN_MONO =
  'packaging/PACKAGING_CREATE_VARIANT_IN_MONO'
export const PACKAGING_CREATE_VARIANT_IN_MULTI =
  'packaging/PACKAGING_CREATE_VARIANT_IN_MULTI'
export const PACKAGING_FETCH_ATTACHMENT_TYPES =
  'packaging/PACKAGING_FETCH_ATTACHMENT_TYPES'
export const PACKAGING_FURTHER_VERSION_DELETE =
  'packaging/furtherVersion/PACKAGING_FURTHER_VERSION_DELETE'
export const PACKAGING_FURTHER_VERSION_ADD =
  'packaging/furtherVersion/PACKAGING_FURTHER_VERSION_ADD'
export const PACKAGING_RELATED_DELETE =
  'packaging/furtherVersion/PACKAGING_RELATED_DELETE'
export const PACKAGING_RELATED_ADD = 'packaging/furtherVersion/PACKAGING_RELATED_ADD'
export const PACKAGING_CHECK_SUPPLIER_REF =
  'packaging/createPackage/PACKAGING_CHECK_SUPPLIER_REF'
export const PACKAGING_VARIANT_REMOVE = 'packaging/detail/PACKAGING_VARIANT_REMOVE'
export const PACKAGING_VARIANT_REMOVE_FROM_PACKAGE =
  'packaging/detail/PACKAGING_VARIANT_REMOVE_FROM_PACKAGE'
export const PACKAGING_VARIANT_UPDATE = 'packaging/detail/PACKAGING_VARIANT_UPDATE'
export const PACKAGING_VARIANT_GET = 'packaging/detail/PACKAGING_VARIANT_GET'
export const PACKAGING_VARIANT_ADD = 'packaging/detail/PACKAGING_VARIANT_ADD'
export const PACKAGING_COPY_ATTACHMENTS_FILTERS = 'packaging/copyAttachmentsFilters'
export const PACKAGING_SEARCH_WITH_RELATIONS = 'packaging/PACKAGING_SEARCH_WITH_RELATIONS'
export const PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN =
  'packaging/PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN'
export const PACKAGING_FILTER_TO_TEMP = 'packagingSaga/filterToTemp'
export const PACKAGING_TEMP_TO_FILTER = 'packagingSaga/tempToFilter'
export const PACKAGING_CHECK_STATUS_ON_DELETE = 'packaging/checkStatusOnDelete'
export const PACKAGING_CHECK_STATUS = 'packaging/PACKAGING_CHECK_STATUS'
export const PACKAGING_EXPORT_MACRO = 'packaging/PACKAGING_EXPORT_MACRO'
export const PACKAGING_EXPORT_MICRO = 'packaging/PACKAGING_EXPORT_MICRO'
export const PACKAGING_EXPORT_TARGET_PRICE = 'packaging/PACKAGING_EXPORT_TARGET_PRICE'
export const GET_COUNTRIES_OF_ORIGIN_DROPDOWN = 'packaging/getCountriesOfOriginDropdown'
export const SUPPLIERS_SEARCH = 'packaging/SUPPLIERS_SEARCH'

export const SUBCATEGORY_DISABLE_LIST = [
  CATEGORY.AEROSOL.toString(),
  CATEGORY.MASK_FABRIC.toString(),
  CATEGORY.MONODOSE.toString(),
  CATEGORY.SACHET.toString()
]

export const OTHER_PACKAGING_TABS = [
  {
    label: 'Other Packaging Versions',
    value: OTHER_PACKAGES.FURTHER
  },
  {
    label: 'Equivalent Packaging',
    value: OTHER_PACKAGES.RELATED
  }
]

export enum PACKAGING_STATUS {
  DRAFT = 'DRAFT',
  HOMOLOGATED = 'HOMOLOGATED',
  AVAILABLE = 'AVAILABLE',
  DISCONTINUED = 'DISCONTINUED'
}
export type ATTACHMENT_CONTENT_TYPE =
  | 'technical drawing'
  | 'areadeco'
  | 'why clean'
  | 'master specification'
  | 'project specification'
  | 'regulatory'
  | 'other'
  | 'homologation report'
  | 'Tested Claim'

export const PACKAGING_STATUS_COLOR = {
  [PACKAGING_STATUS.DRAFT]: 'grey',
  [PACKAGING_STATUS.HOMOLOGATED]: 'orange',
  [PACKAGING_STATUS.AVAILABLE]: 'green',
  [PACKAGING_STATUS.DISCONTINUED]: 'red'
}

export const ALLOWED_ATTACHMENTS = [
  'areadeco',
  'homologation report',
  'master specification',
  'project specification',
  'regulatory',
  'technical drawing',
  'why clean',
  'other'
]

export const contentTypes = [
  { label: 'Technical Drawing', value: '0c0ef5c2-d900-4441-8b2a-0e80a29573a6' },
  { label: 'AreaDeco', value: 'AreaDeco' },
  { label: 'Master Specification', value: 'Master Specification' },
  { label: 'Project Specification', value: 'Project Specification' },
  { label: 'Homologation Report', value: 'Homologation Report' },
  { label: 'Regulatory', value: 'Regulatory' },
  { label: 'Why Clean', value: 'Why Clean' },
  { label: 'Industrialization Report', value: 'Industrialization Report' },
  { label: 'Weight Loss', value: 'Weight Loss' },
  { label: 'Other', value: 'Other' }
]
