import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy, unset } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import {
  ToolingDto,
  ToolingState,
  ToolingSuccessInfoInterface,
  ToolingTaxonomy
} from './model'

const initialState: ToolingState = {
  items: [],
  itemsByIds: {},
  filters: {},
  tempFilters: {},
  dropDown: {},
  pagination: {
    totalPages: 0,
    page: 1,
    pageSize: 10,
    total: 0
  },
  isLoading: false,
  isToolingSection: false,
  error: {},
  successInfo: undefined
}

const toolingSlice = createSlice({
  name: 'tooling',
  initialState: initialState,
  reducers: {
    setTooling: (
      state: ToolingState,
      { payload }: PayloadAction<{ data: ToolingDto[] }>
    ) => {
      const { data } = payload
      state.items = data
      state.itemsByIds = keyBy(state.items, 'id')
    },
    setDropdown: (state, { payload }: any) => {
      state.dropDown = payload
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination
    },
    setPagination: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number
        pageSize?: number
        totalPages?: number
        total?: number
      }>
    ) => {
      state.pagination = { ...state.pagination, ...payload }
    },
    setToolingSection: (state: ToolingState, { payload }: any) => {
      state.isToolingSection = payload
    },
    setToolingDetailData: (state: ToolingState, { payload }: any) => {
      state.toolingDetail = payload
    },
    setEditData: (state: ToolingState, { payload }: any) => {
      state.toolingEditingData = payload
    },
    updateEditingData: (
      state: ToolingState,
      { payload }: PayloadAction<{ key: any; value: any }>
    ) => {
      const { key, value } = payload

      state.toolingEditingData = {
        ...(state.toolingEditingData ? state.toolingEditingData : {}),
        [key]: value
      }
    },
    removeFilter: (state: ToolingState, { payload }: any) => {
      unset(state.filters, payload)
    },
    updateFilter: (state: ToolingState, { payload }: PayloadAction<ToolingTaxonomy>) => {
      state.filters = payload
    },
    updateTempFilter: (
      state: ToolingState,
      { payload }: PayloadAction<{ key: keyof ToolingTaxonomy; value: any }>
    ) => {
      const { key, value } = payload
      state.tempFilters[key] = value
    },
    setTempFilters: (state: ToolingState) => {
      state.tempFilters = state.filters
    },
    applyFilters: (state: ToolingState) => {
      state.filters = state.tempFilters
      state.tempFilters = initialState.tempFilters
    },
    clearFilters: (state: ToolingState) => {
      state.filters = initialState.filters
      state.tempFilters = initialState.tempFilters
      state.pagination = initialState.pagination
    },
    setIsLoading: (state: ToolingState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: ToolingState, { payload }: PayloadAction<IError>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: ToolingState) => {
      state.error = initialState.error
    },
    setSuccessInfo: (
      state: ToolingState,
      { payload }: PayloadAction<ToolingSuccessInfoInterface>
    ) => {
      state.successInfo = {
        message: payload.message,
        code: payload.code
      }
    },
    clearSuccess: (state: ToolingState) => {
      state.successInfo = initialState.successInfo
    },
    clearUpdated: (state: ToolingState) => {
      state.successInfo = initialState.successInfo
    }
  }
})

export const toolingSliceActions = toolingSlice.actions

export default toolingSlice.reducer
