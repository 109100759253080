import { Divider } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button, SectionTitle } from '../../components'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Title } from '../../components/Title'
import { PageContent } from '../../containers'
import { ButtonsWrapper } from '../../containers/Packaging/AddMasterSpecificationModal/styled'
import { ButtonWrapper } from '../../containers/Packaging/Summary/styled'
import { SuccessContainer } from '../../containers/Success/styled'
import ToolingInfo from '../../containers/ToolingInfo'
import { toolingActions, toolingSliceActions } from '../../features/tooling'
import {
  getToolingEditingData,
  selectToolingError,
  selectToolingLoader,
  selectToolingSuccess
} from '../../features/tooling/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'

export const ToolingCreation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const successInfo = useSelector(selectToolingSuccess)
  const editingData = useSelector(getToolingEditingData)

  useEffect(() => {
    return () => {
      dispatch(toolingSliceActions.clearSuccess())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toolingError = useSelector(selectToolingError)
  const newTooling = useSelector(getToolingEditingData)
  const isLoading = useSelector(selectToolingLoader)

  const saveHandler = () => {
    if (newTooling) dispatch(toolingActions.createTooling(newTooling))
  }

  const cancelHandler = () => {
    navigate(-1)
  }

  let BodyComponent = <Loader />

  if (!isLoading && successInfo) {
    BodyComponent = (
      <SuccessContainer>
        <Text
          text={`The new tooling was succesfully created with the code ${successInfo.code}!`}
          variant="h2"
        />
        <Text text={'You can find the new tooling in "PANS ET ALIA DB"'} variant="h4" />
        <ButtonWrapper>
          <Button
            label="Go to the Tooling search"
            onClick={() => {
              navigate(AppRoutes.VAULT_TOOLING_SEARCH)
            }}
            variant="ghost"
          />
          <Button
            label="Back to Home"
            onClick={() => {
              navigate(AppRoutes.HOME)
            }}
            variant="text"
            iconName="HomeOutlined"
          />
        </ButtonWrapper>
      </SuccessContainer>
    )
  } else if (!isLoading)
    BodyComponent = (
      <>
        {/* <Breadcrumb items={breadcrumbs} /> */}
        <Title title="Tooling Creation" variantTitle="h2" />
        <>
          <SectionTitle
            value={''}
            isBold
            addon={
              <ButtonsWrapper>
                <Button variant="ghost" label="Cancel" onClick={cancelHandler} />
                <Button label="Save" onClick={saveHandler} />
              </ButtonsWrapper>
            }
          />
          <Divider />
          <ToolingInfo isCreating={true} isEditing data={editingData || {}} />
        </>
      </>
    )
  return (
    <GenericLayout error={toolingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {BodyComponent}
      </PageContent>
    </GenericLayout>
  )
}
