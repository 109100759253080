import { Form, InputNumber as InputNumberAnt } from 'antd'
import { useState, KeyboardEvent, FC, useEffect } from 'react'
import { preventNonNumericalInput } from '../../../utils/inputNumberHelpers'
import Button from '../../Button'
import { Text } from '../../Text'
import { StyledInputNumber } from './styled'
import { IInputNumberProps } from './types'

export const InputNumber: FC<IInputNumberProps> = ({
  label,
  onChange,
  defaultValue,
  minNumber,
  maxNumber,
  value,
  placeholder = '',
  required = false,
  disabled,
  helperText,
  controls = false,
  hideSideButton = false,
  errorPosition = 'top',
  errorMessage = '',
  step = 1
}) => {
  const [error, setError] = useState(false)

  const onChangeValue: (n: number) => void = (value = 0) => {
    onChange(value)
  }

  const handleMaxValue = (e: KeyboardEvent<HTMLInputElement>) => {
    setError(false)
    const value = Number(e.currentTarget.value)
    if (maxNumber && value > maxNumber) {
      setError(true)
    }
  }

  useEffect(() => {
    if (error && maxNumber && (value || 0) <= maxNumber) {
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const hasError = error && errorMessage

  const incrementHandler = () => {
    const newValue = (value || 0) + step
    onChangeValue(newValue)
  }

  const decrementHandler = () => {
    const newValue = (value || 0) - step
    onChangeValue(newValue)
  }

  const size = hideSideButton ? 'middle' : 'large'

  return (
    <Form.Item required={required} label={label}>
      {errorPosition === 'top' && hasError && <Text text={errorMessage} color="red" />}
      <StyledInputNumber hideSideButton={hideSideButton}>
        {!hideSideButton && (
          <Button
            variant="ghost"
            iconPosition="left"
            iconName="LeftOutlined"
            size="large"
            onClick={decrementHandler}
            disabled={disabled || (value || 0) <= (minNumber || 0)}
          />
        )}

        <InputNumberAnt
          onKeyUp={handleMaxValue}
          min={minNumber}
          max={maxNumber}
          defaultValue={defaultValue}
          onChange={onChangeValue}
          value={value}
          controls={controls}
          size={size}
          className="input"
          disabled={disabled}
          onKeyDown={preventNonNumericalInput}
          placeholder={placeholder}
          step={step}
        />

        {!hideSideButton && (
          <Button
            variant="ghost"
            iconPosition="left"
            iconName="RightOutlined"
            size="large"
            onClick={incrementHandler}
            disabled={disabled || (value || 0) >= (maxNumber || 0)}
          />
        )}
      </StyledInputNumber>
      {errorPosition === 'bottom' && hasError && <Text text={errorMessage} color="red" />}
      {helperText && <Text alert text={helperText} />}
    </Form.Item>
  )
}
