import { isObject } from 'lodash'

import { MAPPED_DROPDOWN } from '../../features/product/constants'

export const mapChipsValues = {
  products: {
    status: MAPPED_DROPDOWN.status,
    packageType: MAPPED_DROPDOWN.packageType
    // this let show the chip with the label of the radio button and not its value, eg SPF: Yes instead of SPF: true
    // but is very cumbersome, it should be refactored in all parts.
    // SPF: [
    //   {
    //     name: 'Yes',
    //     value: true
    //   },
    //   {
    //     name: 'No',
    //     value: false
    //   },
    //   {
    //     name: 'Null',
    //     value: null
    //   }
    // ]
  }
}

export const mapChipsLabel = {
  products: {
    productCode: 'Product Code',
    name: 'Final Name',
    businessUnit: 'Business Unit',
    businessSegment: 'Business Segment',
    applicationArea: 'Application Area',
    productCategory: 'Product Category',
    productForm: 'Product Form',
    format: 'Packaging Category',
    industrialCodeFinish: 'IC Finish',
    formulaSchemaCode: 'Formula Schema Code',
    industrialCode: 'Industrial Code',
    packagingCode: 'PK Intercos Code',
    launchYear: 'PD Launch Year',
    collection: 'PD Collection',
    sapOfferCode: 'SAP Offer Code',
    fsSpf: 'SPF DevEx',
    cleanAtSephora: 'Clean At Sephora',
    cleanAtUlta: 'Clean At Ulta',
    microplasticFree: 'Clean Of Microplastic',
    siliconesFree: 'Clean Of Silicones',
    talcFree: 'Clean Of Talc',
    pegFree: 'Clean Of PEG',
    d5Free: 'Clean Of D5',
    cleanOfPreservatives: 'Clean Of Preservatives',
    cleanOfMineralOils: 'Clean Of Mineral Oils',
    silicaFree: 'Clean Of Silica',
    GMO: 'Clean Of GMO',
    paraben: 'Clean Of Parabens',
    packageType: 'Packaging Type',
    category: 'Category',
    finish: 'IC Finish',
    status: 'Status'
  },
  // NOTE - probably deprecated
  packageSearch: {
    _all: 'Full Search',
    packagingCode: 'PK Intercos Code',
    supplierRef: 'Supplier Pkg Code',
    name: 'Packaging Supplier',
    testedSupplier: 'Packaging Supplier',
    panNumber: 'Pan Number',
    SAPCode: 'SAP Code',
    category: 'Category',
    subcategory: 'Subcategory',
    shape: 'Shape',
    capacityRange: 'Capacity Range',
    technology: 'Technology',
    panShape: 'Pan Shape',
    panSize: 'Pan Size',
    applicatorAndAccessories: 'Applicator and Accessories',
    closure: 'Closure',
    material: 'Bulk-Contact Material',
    filling: 'Filling',
    bottle: 'Bottle',
    dipstick: 'Stem',
    wiper: 'Wiper',
    applicator: 'Applicator',
    applicatorNumber: 'Applicator Number',
    cleanTypes: 'Clean Type',
    clean: 'Clean',
    airtight: 'Airtight',
    mirror: 'Mirror'
  },
  packaging: {
    _all: 'Full Search',
    packagingCode: 'PK Intercos Code',
    status: 'Status',
    supplierRef: 'Supplier Pkg Code',
    name: 'Packaging Supplier',
    testedSupplier: 'Packaging Supplier',
    panNumber: 'Pan Number',
    SAPCode: 'SAP Code',
    category: 'Category',
    subcategory: 'Subcategory',
    shape: 'Shape',
    capacityRange: 'Capacity Range',
    technology: 'Technology',
    panShape: 'Pan Shape',
    panSize: 'Pan Size',
    applicatorAndAccessories: 'Applicator and Accessories',
    closure: 'Closure',
    material: 'Bulk-Contact Material',
    filling: 'Filling',
    bottle: 'Bottle',
    dipstick: 'Stem',
    wiper: 'Wiper',
    applicator: 'Applicator',
    applicatorNumber: 'Applicator Number',
    cleanTypes: 'Clean Type',
    clean: 'Clean',
    freeOfMaterial: 'Free-Of Material',
    airtight: 'Airtight',
    mirror: 'Mirror',
    isValidNote: 'Note',
    tipShape: 'Tip Shape',
    nullPricesOnly: 'Target Price',
    targetPriceMin: 'Target Price Min',
    targetPriceMax: 'Target Price Max',
    neutroName: 'Neutro',
    countryOfOriginCodes: 'Country Of Origin'
  },
  schemaSearch: {
    formulaSchemaCode: 'Formula Schema Code',
    name: 'Formula Schema Name',
    launchYear: 'FS Launch Year',
    fsFinish: 'FS Finish',
    icPao: 'IC Pao',
    devexApplicationArea: 'DevEx Application Area',
    icShelfLife: 'IC Shelf Life',
    collection: 'FS Collection',
    fsSpf: 'FS SPF',
    otc: 'OTC'
  },
  formulaschema: {
    formulaSchemaCode: 'Formula Schema Code',
    industrialCode: 'Industrial Code',
    labReferenceCode: 'Lab Reference Code',
    name: 'Formula Schema Name',
    launchYear: 'FS Launch Year',
    fsFinish: 'FS Finish',
    icPao: 'IC PAO',
    devexApplicationArea: 'DevEx Application Area',
    icShelfLife: 'IC Shelf Life',
    collection: 'FS Collection',
    fsSpf: 'FS SPF',
    otc: 'OTC'
  },
  storeOrders: {
    fullfillmentDateFrom: 'F.ment Date From',
    fullfillmentDateTo: 'F.ment Date To',
    createdDateFrom: 'Creation Date From',
    createdDateTo: 'Creation Date To',
    addressedTo: 'Addressed To',
    ownerFullName: 'Requested By',
    notes: 'Destination Note',
    orderCode: 'Order Code'
  },
  storeProducts: {
    finalName: 'Product Name',
    productCategory: 'Product Category',
    color: 'Color',
    labCode: 'Lab Code',
    finalCode: 'Final Code',
    itemCode: 'Item Code',
    finish: 'Finish',
    applicationArea: 'Application Area',
    usage: 'Usage',
    packagingSupplier: 'Packagaging Supplier',
    packagingRef: 'PK Intercos Code',
    specialEffect: 'Special Effect',
    formulaSchemaCode: 'Formula Schema',
    launchYear: 'Launch Year',
    collection: 'Collection',
    family: 'Family',
    productCode: 'Product Code',
    productForm: 'Product Form',
    grupCode: 'Multipack Code'
  },
  tooling: {
    industrialCodeMold: 'Industrial Code Mold',
    length: 'Tooling Length',
    height: 'Tooling Height',
    width: 'Tooling Width',
    diameter: 'Tooling Diameter',
    material: 'Tooling Material',
    toolingShape: 'Tooling Shape',
    code: 'Tooling Code',
    backInjection: 'Back Injection',
    type: 'Tooling Type'
  }
}

export const checkIsClosable = (normalizedTags, reducer, k, allowNoFilters) => {
  return allowNoFilters
    ? true
    : reducer !== 'packaging'
      ? normalizedTags.length !== 1
      : checkPackaging(normalizedTags, k)
}

export const normalizeTags = (tags, path) => {
  return Object.entries(tags).reduce(
    (acc, [k, v]) =>
      isObject(v) && !Array.isArray(v)
        ? { ...acc, ...normalizeTags(v, `${path}.${k}`) }
        : v !== '' && { ...acc, [k]: { value: v, path: `${path}.${k}` } },
    {}
  )
}

const checkPackaging = (normalizedTags, k) => {
  const keysList = normalizedTags.reduce((acc, [k, v]) => {
    return [...acc, k]
  }, [])
  if (keysList.includes('category')) {
    if (k === 'category') {
      if (keysList.some((key) => categoryFilters.includes(key))) {
        return false
      }
      return normalizedTags.length !== 1
    }
    return true
  }
  return normalizedTags.length !== 1
}

const categoryFilters = [
  'subcategory',
  'shape',
  'capacityRange',
  'technology',
  'panShape',
  'panSize',
  'applicatorAndAccessories',
  'closure',
  'material',
  'filling',
  'bottle',
  'dipstick',
  'wiper',
  'applicator',
  'applicatorNumber',
  'cleanTypes',
  'freeOfMaterial',
  'clean',
  'airtight',
  'mirror'
]

export const getChipsValueLabel = (flowName, key, chipValue) => {
  if (!mapChipsValues[flowName] || !mapChipsValues[flowName][key]) {
    return ''
  }
  const selectValue = mapChipsValues[flowName][key].find(
    ({ value }) => value === chipValue
  )

  return selectValue ? selectValue.name : ''
}
