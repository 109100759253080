import { FC } from 'react'

import { Avatar as AvatarAnt } from 'antd'

import { StyledAvatar } from './styled'
import { IAvatarProps } from './types'

export const Avatar: FC<IAvatarProps> = ({ name, surname = '' }) => {
  const getInitial = () => {
    if (!name && !surname) return
    return `${name[0]}${surname && surname[0]}`.toUpperCase()
  }
  return (
    <StyledAvatar>
      <AvatarAnt className="avatar" size={28}>
        {getInitial()}
      </AvatarAnt>
    </StyledAvatar>
  )
}
