import styled from 'styled-components'

export const Label = styled.span`
  font-size: medium;
  font-weight: bold;
`

export const Value = styled.span`
  font-size: medium;
  word-break: break-all;
`

export const InputWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .ant-picker {
    width: 100%;
  }
`

export const StyledBox = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  .label {
    font-size: medium;
    font-weight: 600;
    margin-bottom: 0;
  }
  .value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 10px 0 0;

    & > * {
      font-size: medium;
      font-weight: lighter;
      margin: 0;
    }
    > span {
      margin-left: 10px;
    }
  }
`
