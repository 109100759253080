import { apiClient } from '@/api/client'

import {
  BaseSpecificationCharacteristic,
  IMasterSpec,
  IMasterSpecDetail,
  IMasterSpecSearchFields
} from './model'

export const searchMasterSpec = (data: IMasterSpecSearchFields) => {
  return apiClient.get<IMasterSpec[]>('master-specifications', { params: data })
}

export const searchProjectSpec = (data: IMasterSpecSearchFields) => {
  return apiClient.get<IMasterSpec[]>('project-specifications', { params: data })
}

export const searchCharacteristics = () => {
  return apiClient.get<BaseSpecificationCharacteristic[]>('specification-characteristics')
}

export const createMasterSpec = (data: { [key: string]: any }) => {
  return apiClient.post<any>('master-specifications', data)
}

export const createProjectSpec = (data: { [key: string]: any }) => {
  return apiClient.post<any>('project-specifications', data)
}

export const updateMasterSpec = (data: { [key: string]: any }) => {
  return apiClient.patch<any>(`master-specifications/${data.id}`, data)
}

export const uploadMasterSpecThumbnail = (data: FormData) => {
  return apiClient.post<any>('master-specifications/upload-thumbnail-image', data)
}

export const uploadCharImage = (data: FormData) => {
  return apiClient.post<any>('master-specifications/upload-characteristic-image', data)
}

export const uploadProjectSpecThumbnail = (data: FormData) => {
  return apiClient.post<any>('project-specifications/upload-thumbnail-image', data)
}

export const updateProjectSpec = (data: { [key: string]: any }) => {
  return apiClient.patch<any>(
    `project-specifications/${data.id}?associateDuplicatedSapCodes=false`,
    data
  )
}

export const checkSapCodes = (id: string, data: any) => {
  return apiClient.post<any>(`project-specifications/${id}/check-sap-codes`, data)
}

export const deleteMasterSpec = (data: { specId: string }) => {
  return apiClient.delete<any>(`master-specifications/${data.specId}`)
}

export const deleteProjectSpec = (data: { specId: string }) => {
  return apiClient.delete<any>(`project-specifications/${data.specId}`)
}

export const getMasterSpecDetail = (specId: string) => {
  return apiClient.get<IMasterSpecDetail>(`master-specifications/${specId}`)
}

export const getProjectSpecDetail = (specId: string) => {
  return apiClient.get<IMasterSpecDetail>(`project-specifications/${specId}`)
}

export const getSpecificationSections = () => {
  return apiClient.get<any>('specification-sections/')
}

export const sendToSap = (specId: string, associateDuplicatedSapCodes: boolean) => {
  return apiClient.post<IMasterSpecDetail>(
    `project-specifications/${specId}/send-to-sap?associateDuplicatedSapCodes=${associateDuplicatedSapCodes}`
  )
}

export const exportMasterSpec = (id: string) => {
  return apiClient.get(`master-specifications/export/${id}`, {
    withHeaders: true,
    responseType: 'blob'
  })
}

export const exportProjectSpec = (id: string) => {
  return apiClient.get(`project-specifications/export/${id}`, {
    withHeaders: true,
    responseType: 'blob'
  })
}
