import { FC, useEffect, useState } from 'react'

import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { footerHeight, headerHeight } from '../../../assets/mixins'
import { Button } from '../../../components'
import { Breadcrumb } from '../../../components/Breadcrumb'
import { Loader } from '../../../components/Loader'
import { AZ_VAULT_TOOLING_RW_GROUP } from '../../../constants/users'
import { PageContent } from '../../../containers'
import Success from '../../../containers/Success'
import ToolingInfo from '../../../containers/ToolingInfo'
import { appSliceActions } from '../../../features/app'
import { toolingActions, toolingSliceActions } from '../../../features/tooling'
import { ToolingDto } from '../../../features/tooling/model'
import {
  getToolingDetail,
  getToolingEditingData,
  selectToolingError,
  selectToolingLoader
} from '../../../features/tooling/selectors'
import { selectUserRole } from '../../../features/users/selectors'
import { GenericLayout } from '../../../layouts'
import { AppRoutes } from '../../constants'

import DeleteToolingMessage from './DeleteToolingMessage'
import { ButtonsWrapper } from './DeleteToolingMessage/styled'

const ToolingDetail: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userRoles = useSelector(selectUserRole)
  const error = useSelector(selectToolingError)
  const toolingDetail = useSelector(getToolingDetail)
  const successInfo = false // useSelector(selectSuccessInfo)
  const tempDetail = useSelector(getToolingEditingData)
  const isLoading = useSelector(selectToolingLoader)
  const { id } = useParams()
  const [isOnEdit, setIsOnEdit] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(toolingActions.getToolingDetails({ id }))
    }
    return () => {
      dispatch(toolingSliceActions.clearError())
      dispatch(toolingSliceActions.clearSuccess())
    }
  }, [dispatch, id])

  const cancelHandler = () => {
    setIsOnEdit(false)
    dispatch(toolingSliceActions.setEditData({}))
  }

  const saveHandler = () => {
    setIsOnEdit(false)
    dispatch(toolingActions.updateTooling(tempDetail || {}))
  }

  const editHandler = () => {
    setIsOnEdit(true)
    dispatch(toolingSliceActions.setEditData(toolingDetail))
  }

  const openDeleteWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: '',
        key: 'popup-delete-warning',
        message: <DeleteToolingMessage />,

        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              dispatch(toolingActions.deleteTooling({ id: get(toolingDetail, 'id', '') }))
              navigate(AppRoutes.VAULT_TOOLING_SEARCH)
            }}
          />
        ]
      })
    )
  }

  const openSaveWarning = () => {
    dispatch(
      appSliceActions.openPopUp({
        title: 'Are you sure you want to save the changes?',
        key: 'popup-save-warning',
        footer: [
          <Button
            key="cancel"
            label="Cancel"
            onClick={() => dispatch(appSliceActions.closePopUp())}
            variant="ghost"
          />,
          <Button
            key="confirm"
            label="Confirm"
            onClick={() => {
              dispatch(appSliceActions.closePopUp())
              saveHandler()
            }}
          />
        ]
      })
    )
  }

  // const isSaveDisable = !tempDetail?.status

  const canEdit = userRoles.some((role) => AZ_VAULT_TOOLING_RW_GROUP.includes(role))

  return (
    <GenericLayout error={error}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        <Breadcrumb
          items={[
            {
              name: 'Tooling',
              href: AppRoutes.VAULT_TOOLING_RESULTS
            },
            {
              name: `Tooling detail ${toolingDetail?.code || '-'}`
            }
          ]}
        />
        {isLoading && <Loader />}
        {!isLoading && !successInfo && (
          <>
            {toolingDetail && !isOnEdit && (
              <>
                <ButtonsWrapper>
                  {canEdit && <Button label="Edit" onClick={editHandler} />}
                </ButtonsWrapper>
                <ToolingInfo data={toolingDetail as Record<string, any>} />
              </>
            )}
            {tempDetail && isOnEdit && (
              <>
                <ButtonsWrapper>
                  <Button
                    key="cancel"
                    label="Cancel"
                    onClick={cancelHandler}
                    variant="ghost"
                  />
                  <Button
                    key="save"
                    label="Save"
                    onClick={openSaveWarning}
                    // disabled={isSaveDisable}
                  />
                  <Button
                    key="delete"
                    label="Delete"
                    onClick={openDeleteWarning}
                    variant="text"
                    iconName="DeleteOutlined"
                  />
                </ButtonsWrapper>
                <ToolingInfo
                  data={tempDetail as ToolingDto}
                  isEditing={isOnEdit}
                  // onUpdate={updateHandler}
                />
              </>
            )}
          </>
        )}
        {!isLoading && successInfo && (
          <Success
            data={successInfo}
            buttons={[
              {
                label: 'Back to the toolings',
                onClick: () => navigate(AppRoutes.VAULT_TOOLING_RESULTS)
              },
              {
                label: 'Back to home',
                onClick: () => navigate(AppRoutes.HOME),
                iconName: 'HomeOutlined',
                variant: 'text'
              }
            ]}
          />
        )}
      </PageContent>
    </GenericLayout>
  )
}

export default ToolingDetail
