import { palette } from '../assets/colors'

export const theme = {
  fontSizes: {
    extraLarge: 44,
    large: 32,
    medium: 24,
    base: 18,
    small: 14,
    extraSmall: 10
  },
  sizes: {
    extraSmall: 20,
    small: 28,
    medium: 34,
    large: 48
  },
  palette
}
