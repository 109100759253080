import { useEffect, useState } from 'react'

import { Tabs } from 'antd'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import {
  canUserSeeOtherTestedPackagingForProduct,
  canUserSeeTestedClaimForProduct
} from '@/utils/permissionsHelpers'

import { InfoCell, SectionTitle, Table } from '../../../components'
import { Accordion } from '../../../components/Accordion'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { DATE_FORMAT } from '../../../components/Form/RangeDatePicker/contants'
import { TOP_RIGHT } from '../../../components/Table/constants'
import { Text } from '../../../components/Text'
import AttachmentsTable from '../../../containers/AttachmentsTable'
import {
  PRODUCT_ATTACHMENTS_TABLE_CONFIG,
  PRODUCT_MARKETING_TABLE_CONFIG
} from '../../../containers/AttachmentsTable/constants'
import FormulaSchemaTable from '../../../containers/FormulaSchemaTable'
import { PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG } from '../../../containers/FormulaSchemaTable/constants'
import DetailInformation from '../../../containers/Product/DetailInformation'
import FormulaSchemaData from '../../../containers/Product/FormulaSchemaData'
import ProdGeneralData from '../../../containers/Product/GeneralData'
import PackagingData from '../../../containers/Product/PackagingData'
import PackagingSideData from '../../../containers/Product/PackagingSideData'
import RegulatoryCheckInfo from '../../../containers/RegulatoryCheckInfo'
import ToolingInfo from '../../../containers/ToolingInfo'
import { getSchemaId } from '../../../features/formulaSchema/api'
import { PACKAGE_TYPE } from '../../../features/packaging/model'
import {
  getProductCreate,
  selectSelectedPackage,
  selectSelectedTooling
} from '../../../features/product/selectors'
import { productsSliceActions } from '../../../features/products'
import { selectPermissions, selectUserRole } from '../../../features/users/selectors'
import {
  columnsGeneratorHelper,
  getRecentDate,
  productFSMonoRefactor,
  productRegulatoryGenData
} from '../../../utils'
import { filterAttachmentsByPermissions } from '../../../utils/attachmentsHelper'
import { refactorProductOtherTestedPackagingRows } from '../../../utils/productHelpers'
import {
  OTHER_TESTED_PACKAGING_COLUMNS,
  TESTED_CLAIM_COLUMNS
} from '../../FSchemaDetail/constants'

import { IVariantInfo } from './types'

const Summary = () => {
  const productData = useSelector(getProductCreate)
  const selectedPackages = useSelector(selectSelectedPackage)
  const permissions = useSelector(selectPermissions)
  const selectedTooling = useSelector(selectSelectedTooling)
  const dispatch = useDispatch()
  const [toolingData] = selectedTooling || []
  const isMulti = get(selectedPackages, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI

  const sideList = get(productData, 'sides', [])
  const regulatoryDates = get(productData, 'regulatoryUpdateDates', {})
  const [sideMonoInfo = {}] = sideList
  const fSchemaMonoData = sideMonoInfo?.fSchema || {}

  const fSchemaList = sideList.map(({ fSchema }) => fSchema || {})
  const refactorRegulatoryData = productRegulatoryGenData(fSchemaList)

  const columnsOtherTested = columnsGeneratorHelper(OTHER_TESTED_PACKAGING_COLUMNS)
  const columnsTestedClaim = columnsGeneratorHelper(TESTED_CLAIM_COLUMNS)

  const [otherTested, setOtherTested] = useState([])
  const [testedClaim, setTestedClaim] = useState([])

  const userRoles = useSelector(selectUserRole)

  const fetchOtherTested = async (fSchemaList: any) => {
    try {
      dispatch(productsSliceActions.setLoader(true))
      const otherTested: any = []
      const testedClaim: any = []
      for (const fschema of fSchemaList) {
        const fetchResult = await getSchemaId(fschema.id)
        const otherTestedPackaging = get(fetchResult, 'otherTestedPackaging', [])
        const refactoredOtherTested = refactorProductOtherTestedPackagingRows({
          dataList: otherTestedPackaging
        })
        otherTested.push(...refactoredOtherTested)
        testedClaim.push(...get(fetchResult, 'testedExtensClaims', []))
      }
      setOtherTested(otherTested)
      setTestedClaim(testedClaim)
      dispatch(productsSliceActions.setLoader(false))
    } catch (err) {
      // temporary unhandle the error
    }
  }

  useEffect(() => {
    fetchOtherTested(fSchemaList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVariantInfo: (sideIdx: number) => IVariantInfo = (sideIdx) => {
    const sideInfo = sideList[sideIdx]
    const { packageVariantId } = sideInfo
    let variantSelected
    get(selectedPackages, 'packageFamilies', []).forEach((family: any) => {
      variantSelected = get(family, 'packageVariants', []).find(
        ({ id }: any) => id === packageVariantId
      )
    })
    return {
      bottle: get(variantSelected, 'vialDetails.bottle', []),
      dipstick: get(variantSelected, 'vialDetails.dipstick', ''),
      wiper: get(variantSelected, 'vialDetails.wiper', ''),
      applicator: get(variantSelected, 'vialDetails.applicator', ''),
      variantId: get(variantSelected, 'id', ''),
      variantCode: get(variantSelected, 'variantCode', ''),
      variantSupplierCode: get(variantSelected, 'vialDetails.variantSupplierCode', ''),
      attachments: get(variantSelected, 'attachments', [])
    }
  }

  const marketingProfileData = get(productData, 'marketingProfile', {})
  const hasMarketingProfile = !isEmpty(marketingProfileData)

  const attachmentList = getVariantInfo(0).attachments
  const filteredAttachments = filterAttachmentsByPermissions({
    attachments: attachmentList,
    permissions,
    section: 'vaultProduct',
    crud: 'onRead'
  })

  return (
    <>
      {/* NOTE - possible optimization */}
      {/* this portion of code is similar to ProductDetail component
    with the write structure of data it will be possible to use the same component */}
      <SectionTitle value={get(productData, 'name', '')} isBold />
      <Divider />
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="product-image"
          value={get(productData, 'image.value', '')}
          dataType="image"
        />
      </BoxWrapper>
      <Text
        text="*Picture might not be a reference for color and finish: please refer to information below"
        italic
      />
      <Accordion
        title={'General Data'}
        variant={'Title'}
        open={false}
        isDate={false}
        subtitleValue={''}
        subtitleLabel={''}
      >
        <ProdGeneralData
          data={{ ...productData, status: 'WIP_INFORMATION' }}
          isMulti={isMulti}
        />
      </Accordion>
      {!isMulti && (
        <Accordion
          title={'Formula Schema Data'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <FormulaSchemaData data={productFSMonoRefactor(fSchemaMonoData)} />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Detailed Information'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <DetailInformation data={{ ...productData, ...sideMonoInfo }} />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Regulatory Check FS'}
          variant={'Title'}
          open={false}
          subtitleLabel={'Last Update: '}
          subtitleValue={getRecentDate(regulatoryDates.general)}
          isDate
        >
          <RegulatoryCheckInfo
            data={fSchemaMonoData as Record<string, any>}
            hasDisclaimer
          />
        </Accordion>
      )}
      {!isMulti && (
        <Accordion
          title={'Industrial/Trial Code Information'}
          variant={'Title'}
          open={false}
          isDate={false}
          subtitleValue={''}
          subtitleLabel={''}
        >
          <FormulaSchemaTable
            tableConfig={PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG}
            dataList={get(fSchemaMonoData, 'formula', [])}
          />
        </Accordion>
      )}
      <Accordion
        title={'Packaging Data'}
        variant={'Title'}
        subtitleValue={''}
        subtitleLabel={''}
        open={false}
        isDate={false}
      >
        <PackagingData
          data={{
            ...selectedPackages,
            ...getVariantInfo(0),
            packageId: get(selectedPackages, 'id', '')
          }}
          isMulti={isMulti}
        />
      </Accordion>
      {isMulti && (
        <Accordion
          title={'Regulatory General Data'}
          variant={'Title'}
          open={false}
          subtitleLabel={'Last Update: '}
          subtitleValue={getRecentDate(regulatoryDates.general)}
          isDate
        >
          <RegulatoryCheckInfo data={refactorRegulatoryData} isMulti hasDisclaimer />
        </Accordion>
      )}
      {isMulti && (
        <Accordion
          title={'Sides'}
          variant={'Title'}
          open={false}
          subtitleValue={''}
          subtitleLabel={''}
          isDate={false}
        >
          <Tabs>
            {sideList.map((sideData, sideIdx) => (
              <Tabs.TabPane tab={`Side ${sideIdx + 1}`} key={sideIdx}>
                <SectionTitle value="Formula Schema Data" />
                <Divider />
                <FormulaSchemaData
                  data={productFSMonoRefactor(sideData.fSchema as Record<string, any>)}
                />
                <Divider disableBorder />
                <SectionTitle value="Detail Information" />
                <Divider />
                <DetailInformation
                  data={{ ...productData, ...sideData }}
                  isMulti={isMulti}
                />
                <Divider disableBorder />
                <SectionTitle value="Packaging Side Data" />
                <Divider />
                <PackagingSideData
                  data={{
                    ...get(selectedPackages, `packageFamilies.[${sideIdx}]`, {}),
                    ...getVariantInfo(sideIdx)
                  }}
                />
                <Divider disableBorder />
                <SectionTitle
                  value="Side Regulatory"
                  addon={`Last Update:  ${
                    getRecentDate(regulatoryDates[sideIdx])
                      ? moment(getRecentDate(regulatoryDates[sideIdx])).format(
                          DATE_FORMAT
                        )
                      : '-'
                  }`}
                />
                <Divider />
                <RegulatoryCheckInfo data={get(sideData, 'fSchema', {})} hasDisclaimer />
                <Divider disableBorder />
                <SectionTitle value="Industrial/Trial Code Information" />
                <Divider />
                <FormulaSchemaTable
                  tableConfig={PRODUCT_IC_SUMMARY_MONO_TABLE_CONFIG}
                  dataList={sideData.fSchema?.formula || []}
                />
                {/* NOTE - temporary hidden */}
                {/* <Divider disableBorder />
                  <SectionTitle value="Attachments" />
                  <Divider />
                  <AttachmentsTable
                    dataList={getVariantInfo(sideIdx).attachments as IAttachment[]}
                    tableConfig={PRODUCT_ATTACHMENTS_TABLE_CONFIG}
                  /> */}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Accordion>
      )}
      {!!selectedTooling && (
        <Accordion
          title={'Tooling Information'}
          variant={'Title'}
          subtitleValue={''}
          subtitleLabel={''}
          open={false}
          isDate={false}
        >
          <ToolingInfo data={toolingData} />
          <Divider disableBorder />
          <Divider disableBorder />
        </Accordion>
      )}

      {canUserSeeOtherTestedPackagingForProduct({ userRoles }) && (
        <>
          <Accordion
            title={'Other Tested Packaging'}
            variant={'Title'}
            open={false}
            subtitleValue={''}
            subtitleLabel={''}
            isDate={false}
          >
            <Table
              enablePagination={false}
              paginationPosition={TOP_RIGHT}
              rowKey="id"
              columns={columnsOtherTested}
              items={otherTested || []}
            />
          </Accordion>
        </>
      )}
      {canUserSeeTestedClaimForProduct({ userRoles }) && (
        <>
          <Accordion
            title={'Tested Claim'}
            variant={'Title'}
            open={false}
            subtitleValue={''}
            subtitleLabel={''}
            isDate={false}
          >
            <Table
              enablePagination={false}
              paginationPosition={TOP_RIGHT}
              rowKey="id"
              columns={columnsTestedClaim}
              items={testedClaim || []}
            />
          </Accordion>
        </>
      )}

      <Accordion
        title={'Attachments'}
        variant={'Title'}
        open={false}
        subtitleValue={''}
        subtitleLabel={''}
        isDate={false}
      >
        <Text
          text={
            '*Marketing Fiche is intended to be a source of qualitative information only, before sending it to clients please double check that it is updated. As tested claims are concerned, please note that different IC belonging to same FS might report conflicting results. Particularly, before considering a result valid for the whole FS, please verify with BU that the tested IC did not contain any specific active not included in the FS'
          }
          italic
        />
        <Divider disableBorder />
        <SectionTitle value="Marketing Profile" />
        <Divider />
        <AttachmentsTable
          dataList={hasMarketingProfile ? [marketingProfileData] : []}
          tableConfig={PRODUCT_MARKETING_TABLE_CONFIG}
        />
        <Divider disableBorder />
        <Divider disableBorder />
        {!isMulti && (
          <>
            <SectionTitle value="Attachments" />
            <AttachmentsTable
              dataList={filteredAttachments.concat(
                testedClaim.map((t: any) => ({
                  ...t.attachment,
                  attachmentContentType: { name: t.type }
                }))
              )}
              tableConfig={PRODUCT_ATTACHMENTS_TABLE_CONFIG}
              childActionConfig={{
                hasDelete: true
              }}
            />
          </>
        )}
      </Accordion>
    </>
  )
}

export default Summary
