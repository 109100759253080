import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { set, unset } from 'lodash'
import { IError } from '../../containers/Error/types'
import { getCleanError } from '../../utils'
import { IPagination } from '../orderList/models'
import {
  AdministrationState,
  AdministrationType,
  ISuccessInfo,
  ISupplierFilters
} from './model'

const initialState: AdministrationState = {
  type: undefined,
  lastImported: {},
  attachmentContentTypes: [],
  currencyRates: [],
  suppliers: {
    filters: {},
    items: undefined,
    pagination: {
      page: 1,
      pageSize: 10
    }
  },
  attachments: {
    detail: {}
  },
  isLoading: false,
  error: {},
  successInfo: undefined,
  groupNamesDropdown: []
}

const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    setType: (
      state: AdministrationState,
      { payload }: PayloadAction<AdministrationType>
    ) => {
      state.type = payload
    },
    clearType: (state: AdministrationState) => {
      state.type = initialState.type
    },
    setImportedFiles: (
      state: AdministrationState,
      { payload }: PayloadAction<AdministrationState>
    ) => {
      state.importedSupplier = {}
      state.lastImported = payload
    },
    setGroupNamesDropdown: (
      state: AdministrationState,
      { payload }: PayloadAction<any[]>
    ) => {
      state.groupNamesDropdown = payload
    },
    resetLastImport: (state: AdministrationState) => {
      state.lastImported = initialState.lastImported
    },
    setImportedAttachments: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) => {
      state.attachmentContentTypes = payload
    },
    setSuppliers: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) => {
      state.suppliers.items = payload
    },
    setCurrencyRates: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) => {
      state.currencyRates = payload
    },
    clearSuppliers: (state: AdministrationState) => {
      state.suppliers.items = initialState.suppliers.items
    },
    setSupplierPagination: (
      state: AdministrationState,
      { payload }: PayloadAction<IPagination>
    ) => {
      state.suppliers.pagination = payload
    },
    updateSupplierPagination: (
      state: AdministrationState,
      { payload }: PayloadAction<{ key: keyof IPagination; value: number }>
    ) => {
      const { key, value } = payload
      state.suppliers.pagination[key] = value
    },
    clearSupplierPagination: (state: AdministrationState) => {
      state.suppliers.pagination = initialState.suppliers.pagination
    },
    setSupplierDetail: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.suppliers.detail = payload
    },
    resetSupplier: (state: AdministrationState) => {
      state.suppliers = initialState.suppliers
    },
    resetNewSupplierData: (state: AdministrationState) => {
      unset(state.suppliers, 'newSupplier')
    },
    setAttachmentDetail: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any>>
    ) => {
      state.attachments.detail = payload
    },
    setSapCodeWarning: (
      state: AdministrationState,
      { payload }: PayloadAction<Record<string, any> | undefined>
    ) => {
      const isUsed = payload ? true : false
      set(state.suppliers, 'sapCodeUsed', isUsed)
    },
    setIsLoading: (state: AdministrationState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setError: (state: AdministrationState, { payload }: PayloadAction<IError | {}>) => {
      state.error = getCleanError(payload)
    },
    clearError: (state: AdministrationState) => {
      state.error = initialState.error
    },
    setSuccess: (
      state: AdministrationState,
      { payload }: PayloadAction<ISuccessInfo>
    ) => {
      state.successInfo = payload
    },
    clearSuccess: (state: AdministrationState) => {
      state.successInfo = initialState.successInfo
    },
    setSupplierFilters: (
      state: AdministrationState,
      { payload }: PayloadAction<ISupplierFilters>
    ) => {
      state.suppliers.filters = payload
    }
  }
})

export default administrationSlice.reducer
export const administrationSliceActions = administrationSlice.actions
