import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, Loader } from '../../../components'
import { FlexSection } from '../../../components/FlexSection'
import { appActions } from '../../../features/app'
import { toolingActions, toolingSliceActions } from '../../../features/tooling'
import { ToolingTaxonomy } from '../../../features/tooling/model'
import {
  getToolingFilters,
  getToolingItems,
  selectToolingLoader,
  selectToolingSection
} from '../../../features/tooling/selectors'
import { TagsContainer } from '../../TagsContainer'
import ToolingTable from '../../ToolingTable'
import { TOOLING_TABLE_CONFIG } from '../../ToolingTable/constants'
import ToolingSearchForm from '../ToolingSearchForm'
import { ButtonWrapper, FilterBtnWrapper, FlexGap, TagsWrapper } from './styled'
import { IToolingResultProps } from './types'

const ToolingResult: FC<IToolingResultProps> = ({
  enableSelect = false,
  enableActions = true,
  enableExport = true,
  selectedRows,
  onSelect,
  isSingleSelect,
  isLoading
}) => {
  const dispatch = useDispatch()
  const modalKey = 'tooling-filter'
  const filters = useSelector(getToolingFilters)
  const toolingList = useSelector(getToolingItems)
  const isFormLoading = useSelector(selectToolingLoader)
  const isToolingSection = useSelector(selectToolingSection)

  const removeTagHandler = (path: string) => {
    const filterName = path.split('.')[1]
    const newFilters = Object.keys(filters).reduce((acc: any, curr: string) => {
      if (curr !== filterName) acc[curr] = filters[curr as keyof ToolingTaxonomy]
      return acc
    }, {})
    dispatch(toolingSliceActions.updateFilter(newFilters))
    dispatch(toolingActions.getTooling())
  }

  const searchHandler = () => {
    dispatch(appActions.closeModal({ modalKey: 'tooling-filter' }))
    dispatch(toolingSliceActions.applyFilters())
    dispatch(toolingActions.getTooling())
  }

  return (
    <>
      <FlexSection flexDirection="row" justifyContent={'space-between'}>
        <FlexGap>
          {isToolingSection && (
            <FilterBtnWrapper>
              <Modal
                btnName="Filter"
                title="Filter Tooling"
                modalKey={modalKey}
                size="large"
                footer={[
                  <ButtonWrapper>
                    <Button
                      key="search"
                      label="Search"
                      onClick={searchHandler}
                      enableKeyPress
                    />
                  </ButtonWrapper>
                ]}
                btnClicked={() => dispatch(toolingSliceActions.setTempFilters())}
              >
                {isFormLoading ? <Loader /> : <ToolingSearchForm />}
              </Modal>
            </FilterBtnWrapper>
          )}
          <TagsWrapper>
            {!isEmpty(filters) && (
              <TagsContainer
                tags={filters}
                onRemoveTag={removeTagHandler}
                inner={false}
                reducer={''}
                flowName={'tooling'}
              />
            )}
          </TagsWrapper>
        </FlexGap>
        {enableExport && (
          <Button
            label="Export Results"
            variant="ghost"
            disabled={isLoading}
            onClick={() => {
              dispatch(toolingActions.exportToolings())
            }}
          />
        )}
      </FlexSection>
      <ToolingTable
        enableActions={enableActions}
        dataList={toolingList}
        tableConfig={TOOLING_TABLE_CONFIG}
        enableSelect={enableSelect}
        selectedRows={selectedRows}
        onSelectRow={onSelect}
        isSingleSelect={isSingleSelect}
        isLoading={isLoading}
      />
    </>
  )
}

export default ToolingResult
