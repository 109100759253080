import { useEffect, useState } from 'react'

import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication } from '@azure/msal-react'
import _get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { axiosApi } from '@/api/client'
import { callMsGraph } from '@/api/msGraph'

import { Loader } from './components/Loader'
import { loginRequest } from './configs/auth-msal'
import { userGroupId } from './constants/users'
import { administrationSliceActions } from './features/administration'
import { appActions, appSliceActions } from './features/app'
import { cartActions, cartSliceActions } from './features/cart'
import { fSchemaSliceActions } from './features/formulaSchema'
import { orderListSliceActions } from './features/orderList/reducer'
import { packagingSliceActions } from './features/packaging'
import { prodSliceActions } from './features/product'
import { productsSliceActions } from './features/products'
import { storeSliceActions } from './features/storeProducts/reducer'
import { userSliceActions } from './features/users/reducer'
import { selectPermissions, selectUserEmail } from './features/users/selectors'
import {
  AdminManagement,
  AdminSelection,
  AttachmentDetail,
  CartPage,
  CreationPackage,
  FSchemaDetail,
  FSchemaResult,
  FSchemaSearch,
  Home,
  Logout,
  MasterSpecificationDetail,
  MasterSpecificationsCreate,
  MasterSpecificationsResult,
  NotAllowedPage,
  OrderDetails,
  PackagingDetails,
  PackagingResult,
  PackagingSearch,
  ProductCreation,
  ProductRTGDetail,
  ProductRTGResult,
  ProductRTGSearch,
  SelectPackageCreation,
  StoreOrderList,
  StoreProdChildDetail,
  StoreProductCreation,
  StoreProducts,
  StoreProductsDetail,
  StoreProductsSearch,
  SupplierDetail,
  SupplierResult,
  TemplateList,
  ToolingDetail,
  ToolingResults,
  ToolingSearch,
  VariantDetail
} from './pages'
import MasterSpecCharacteristicDetail from './pages/MasterSpecCharacteristicDetail'
import ProjectSpecificationCharacteristicDetail from './pages/ProjectSpecCharacteristicDetail'
import ProjectSpecificationDetail from './pages/ProjectSpecificationDetail'
import { ProjectSpecificationResult } from './pages/ProjectSpecificationResult'
import { ProjectSpecificationsEditor } from './pages/ProjectSpecificationsEditor'
import { ToolingCreation } from './pages/ToolingCreation'
import { AppRoutes } from './pages/constants'
import { GlobalStyle } from './style/global'
import { theme } from './style/theme'
import { routeVisibilityHelper, useCurPatternPath } from './utils'

function App() {
  const [graphData, setGraphData] = useState<any>(null)
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest)
  const dispatch = useDispatch()
  const userRoles = useSelector((state) => _get(state, 'user.roles', null))
  const userMail = useSelector(selectUserEmail)
  const { pathname } = useLocation()
  const userPermissions = useSelector(selectPermissions)
  const currentPathPattern = useCurPatternPath()
  const isAllowed = routeVisibilityHelper(currentPathPattern, userPermissions)
  useEffect(() => {
    if (!!graphData) {
      const [user, roles] = graphData
      dispatch(userSliceActions.setUserData(user))
      const rolesInString = roles.value.map((role: any) => userGroupId[role.id])
      dispatch(userSliceActions.setUserRoles(rolesInString))
      dispatch(userSliceActions.setPermissions({ roles: rolesInString }))
      dispatch(appActions.trackUserLogin())
      return
    }

    if (!!error) {
      return
    }

    if (result) {
      axiosApi.initInstance()
      callMsGraph().then((response) => setGraphData(response))
    }
  }, [error, result, graphData, dispatch])

  useEffect(() => {
    if (userMail && pathname.includes('store')) {
      dispatch(cartActions.getCart())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMail, pathname])

  useEffect(() => {
    if (result) {
      dispatch(appActions.init())
    }
  }, [dispatch, result])

  useEffect(() => {
    // reset FILTERS

    if (!pathname.includes('creation-product')) {
      window.tmpFiles = {}
      dispatch(prodSliceActions.resetProduct())
    }
    if (!pathname.includes('administration')) {
      dispatch(administrationSliceActions.resetSupplier())
    }
    if (!pathname.includes('cart')) {
      dispatch(cartSliceActions.resetOrderOptions())
    }

    if (!pathname.includes('products')) {
      dispatch(productsSliceActions.clearFilters())
    }

    if (!pathname.includes('packaging')) {
      dispatch(packagingSliceActions.clearFilters())
    }
    if (pathname === AppRoutes.WIKIPACK_PACKAGING) {
      dispatch(packagingSliceActions.resetRelatedProducts())
    }

    if (!pathname.includes('formulaschema')) {
      dispatch(fSchemaSliceActions.clearFilters())
      dispatch(fSchemaSliceActions.applyActionsAddon())
    }
    if (!pathname.includes('products-search')) {
      dispatch(storeSliceActions.resetTempFilters())
    }
    if (!pathname.includes('store/orders')) {
      dispatch(orderListSliceActions.resetSelectedTab())
    }
    dispatch(appSliceActions.clearError())
  }, [dispatch, pathname])

  if (error) {
    return <div>ERROR</div>
  }

  if (!userRoles) {
    return <Loader />
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path={AppRoutes.HOME} element={<Home />} />
        <Route path={AppRoutes.LOGOUT} element={<Logout />} />
        {!isAllowed && <Route path={pathname} element={<NotAllowedPage />} />}
        <Route path={AppRoutes.VAULT_ADMINISTRATION} element={<AdminSelection />} />
        <Route
          path={AppRoutes.VAULT_ADMINISTRATION_MANAGEMENT}
          element={<AdminManagement />}
        />
        <Route
          path={AppRoutes.VAULT_ADMINISTRATION_ATTACHMENT}
          element={<AttachmentDetail />}
        />
        <Route
          path={AppRoutes.VAULT_ADMINISTRATION_SUPPLIER}
          element={<SupplierDetail />}
        />
        <Route
          path={AppRoutes.VAULT_ADMINISTRATION_SUPPLIER_RESULT}
          element={<SupplierResult />}
        />
        <Route path={AppRoutes.VAULT_PRODUCTS_SEARCH} element={<ProductRTGSearch />} />
        <Route path={AppRoutes.VAULT_PRODUCTS_RESULTS} element={<ProductRTGResult />} />
        <Route path={AppRoutes.VAULT_PRODUCT_DETAILS} element={<ProductRTGDetail />} />
        <Route path={AppRoutes.VAULT_PRODUCT_CREATION} element={<ProductCreation />} />
        <Route path={AppRoutes.VAULT_TOOLING_SEARCH} element={<ToolingSearch />} />
        <Route path={AppRoutes.VAULT_TOOLING_RESULTS} element={<ToolingResults />} />
        <Route path={AppRoutes.VAULT_TOOLING_DETAILS} element={<ToolingDetail />} />
        <Route path={AppRoutes.VAULT_TOOLING_CREATION} element={<ToolingCreation />} />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGE_CREATION}
          element={<SelectPackageCreation />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGE_CREATION_FLOW}
          element={<CreationPackage />}
        />
        <Route path={AppRoutes.VAULT_FORMULA_SCHEMA} element={<FSchemaSearch />} />
        <Route path={AppRoutes.VAULT_FORMULA_SCHEMA_RESULT} element={<FSchemaResult />} />
        <Route
          path={AppRoutes.VAULT_FORMULA_SCHEMA_DETAILS}
          element={<FSchemaDetail />}
        />
        <Route path={AppRoutes.WIKIPACK_PACKAGING_RESULT} element={<PackagingResult />} />

        <Route path={AppRoutes.WIKIPACK_PACKAGING} element={<PackagingSearch />} />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_DETAILS}
          element={<PackagingDetails />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS}
          element={<MasterSpecificationsResult />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS}
          element={<MasterSpecificationsResult />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS}
          element={<ProjectSpecificationResult />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW}
          element={<ProjectSpecificationsEditor />}
        />
        <Route
          path={
            AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_PROJECT_SPECIFICATIONS_NEW_FROM_SEARCH
          }
          element={<ProjectSpecificationsEditor />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW}
          element={<MasterSpecificationsCreate />}
        />
        <Route
          path={
            AppRoutes.WIKIPACK_PACKAGING_VIAL_VARIANT_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH
          }
          element={<MasterSpecificationsCreate />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS}
          element={<ProjectSpecificationResult />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW}
          element={<MasterSpecificationsCreate />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_NEW_FROM_SEARCH}
          element={<MasterSpecificationsCreate />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_NEW}
          element={<ProjectSpecificationsEditor />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_DETAIL}
          element={<MasterSpecificationDetail />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_DETAIL}
          element={<ProjectSpecificationDetail />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_MASTER_SPECIFICATIONS_CHARACTERISTIC}
          element={<MasterSpecCharacteristicDetail />}
        />
        <Route
          path={AppRoutes.WIKIPACK_PACKAGING_PROJECT_SPECIFICATIONS_CHARACTERISTIC}
          element={<ProjectSpecificationCharacteristicDetail />}
        />
        <Route path={AppRoutes.WIKIPACK_PACKAGING_VARIANT} element={<VariantDetail />} />
        <Route path={AppRoutes.STORE_PRODUCTS_SEARCH} element={<StoreProductsSearch />} />
        <Route
          path={AppRoutes.STORE_PRODUCTS_DETAILS}
          element={<StoreProductsDetail />}
        />
        <Route path={AppRoutes.STORE_CART} element={<CartPage />} />
        <Route
          path={AppRoutes.STORE_ENTITY_CREATION}
          element={<StoreProductCreation />}
        />
        <Route path={AppRoutes.STORE_PRODUCTS} element={<StoreProducts />} />
        <Route path={AppRoutes.STORE_ORDERS} element={<StoreOrderList />} />
        <Route path={AppRoutes.STORE_ORDER_DETAILS} element={<OrderDetails />} />
        <Route path={AppRoutes.STORE_ENTITY_CHILD} element={<StoreProdChildDetail />} />
        <Route path={AppRoutes.STORE_ENTITY_CHILD} element={<StoreProdChildDetail />} />
        <Route path={AppRoutes.TEMPLATES} element={<TemplateList />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
