import { get, unset } from 'lodash'

import { apiClient } from '@/api/client'

import {
  FormulaResultSearch,
  FormulaSchemaDto,
  IAddOtherTestedPackagingPayload,
  IEditOtherTestedPackagingPayload,
  IPackagingSearchResult
} from './model'

export const getFormulaSchema = () => {
  return apiClient.get<FormulaSchemaDto>('formulaSchema')
}
export const searchSchema = (data: any) => {
  return apiClient.post<FormulaResultSearch>('v2/formulaSchema/search', data)
}

export const getSchemaId = (id: string) => {
  return apiClient.get<FormulaSchemaDto>(`formulaSchema/${id}`)
}

export const getFormulaSchemaTaxonomies = () => {
  return apiClient.get<Record<string, any>>('formulaSchema/taxonomies')
}
export const updateSchema = (formulaDetail: Record<string, any>) => {
  unset(formulaDetail, 'formula')
  const id = get(formulaDetail, 'id', '')
  return apiClient.patch<Record<string, any>>(`formulaSchema/${id}`, formulaDetail)
}

export const updateIndustrialCode = (record: Record<string, any>) => {
  const { id } = record
  return apiClient.patch<Record<string, any>>(`formula/${id}`, record)
}

export const addOtherTestedPackaging = (data: IAddOtherTestedPackagingPayload) => {
  return apiClient.post<Record<string, any>>('formulaSchema/otherTestedPackaging', data)
}

export const addTestedClaim = (data: Record<string, any>) => {
  return apiClient.post<Record<string, any>>('tested-and-extension-claims', data)
}

export const addTestedClaimAttachment = (data: any, id: string) => {
  return apiClient.post<any>(`tested-and-extension-claims/${id}/attachment`, data)
}

export const patchOtherTestedPackaging = (data: IEditOtherTestedPackagingPayload) => {
  const { id, ...cleanData } = data
  return apiClient.patch<Record<string, any>>(
    `formulaSchema/otherTestedPackaging/${id}`,
    cleanData
  )
}

export const patchTestedClaim = (data: Record<string, any>) => {
  const { id, ...cleanData } = data
  return apiClient.patch<Record<string, any>>(
    `tested-and-extension-claims/${id}`,
    cleanData
  )
}

export const deleteOtherTestedPackaging = (id: string) => {
  return apiClient.delete<string>(`formulaSchema/otherTestedPackaging/${id}`)
}

export const deleteTestedClaim = (id: string) => {
  return apiClient.delete<string>(`tested-and-extension-claims/${id}`)
}

export const getPKDropdown = (pkCode: string) => {
  return apiClient.get<IPackagingSearchResult[]>(`package/search/package-code/${pkCode}`)
}

export const getTestedClaimTaxonomy = () => {
  return apiClient.get<string>('tested-and-extension-claims/taxonomies')
}
