import { Spin } from 'antd'
import React from 'react'
import { LoaderStyle } from './styled'

export const Loader = React.memo(() => {
  return (
    <LoaderStyle>
      <Spin />
    </LoaderStyle>
  )
})

Loader.displayName = 'Loader'
