import { get } from 'lodash'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Divider } from '../../components/Divider'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../containers'
import { ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG } from '../../containers/AttachmentsTable/constants'
import { ProjectSpecificationList } from '../../containers/Packaging/ProjectSpecificationList'
import { masterSpecActions } from '../../features/masterSpec/actions'
import {
  selectIsLoading,
  selectMasterSpecList,
  selectProjectSpecList
} from '../../features/masterSpec/selectors'
import { packagingActions, packagingSliceActions } from '../../features/packaging'
import {
  getPackageDetail,
  selectPackagingError
} from '../../features/packaging/selectors'
import { selectUserRole } from '../../features/users/selectors'
import { GenericLayout } from '../../layouts'

export const ProjectSpecificationResult: FC = () => {
  const { packageId, packageVariantId } = useParams()
  const dispatch = useDispatch()

  const dataList = useSelector(selectProjectSpecList)
  const packagingError = useSelector(selectPackagingError)
  const masterSpecList = useSelector(selectMasterSpecList)
  const userRoles = useSelector(selectUserRole)
  const packageDetails = useSelector(getPackageDetail) as Record<string, any>
  const isLoading = useSelector(selectIsLoading)
  const sideCollectorId = packageDetails?.id
  const category = get(packageDetails, 'category', '')
  useEffect(() => {
    if (sideCollectorId) {
      dispatch(
        masterSpecActions.getList({
          filters: {
            sideCollectorId,
            category
          }
        })
      )
      dispatch(
        masterSpecActions.getProjectSpecificationList({
          filters: { sideCollectorId, packageVariantId, category },
          isAddNew: false
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sideCollectorId)
      dispatch(
        masterSpecActions.getList({
          filters: { sideCollectorId, category },
          isAddNew: false
        })
      )
    dispatch(packagingSliceActions.setFilteredStatus({ roles: userRoles || [] }))
    packageId &&
      dispatch(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    if (sideCollectorId)
      dispatch(
        masterSpecActions.getProjectSpecificationList({
          filters: { sideCollectorId, packageVariantId, category },
          isAddNew: false
        })
      )
    return () => {
      dispatch(packagingSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideCollectorId])

  const hrefPrefix = '/wikipack/packaging'
  const breadcrumbName = `detail packaging ${packageDetails?.packagingCode}`
  const breadcrumbs = [
    { name: 'Packaging', href: hrefPrefix },
    { name: breadcrumbName, href: `${hrefPrefix}/${packageId}` },
    { name: 'project specifications' }
  ]

  return (
    <GenericLayout error={packagingError}>
      <PageContent
        footerHeight={footerHeight}
        headerHeight={headerHeight}
        justifyContent="flex-start"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb items={breadcrumbs} />
            <Divider disableBorder />
            <ProjectSpecificationList
              masterSpecList={masterSpecList}
              dataList={dataList}
              packageId={packageId}
              tableConfig={ATTACHMENTS_IN_MASTER_SPECIFICATIONS_TABLE_CONFIG}
            />
          </>
        )}
      </PageContent>
    </GenericLayout>
  )
}
