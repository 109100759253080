import { FC } from 'react'

import { Form } from 'antd'
import { get, isString } from 'lodash'
import { Link } from 'react-router-dom'

import { BoxInfo } from '../BoxInfo'
import { Input } from '../Form/Input'
import { InputNumber } from '../Form/InputNumber'
import InputTags from '../Form/InputTags'
import RadioInput from '../Form/RadioInput'
import { IOption } from '../Form/RadioInput/types'
import Select from '../Form/Select'
import { Switch } from '../Form/Switch'
import ImagePreview from '../ImagePreview'
import { Tag } from '../Tag'
import UploadDoc from '../UploadDoc'

import { TagsContainer } from './styles'
import { IInfoCellProps } from './types'

const InfoCell: FC<IInfoCellProps> = ({
  label,
  value,
  dataType = 'string',
  inputType = 'input',
  isEditing = false,
  inputConfig,
  tooltipConfig,
  warningConfig,
  linkConfig
}) => {
  const getInputComponent = () => {
    if (inputType === 'select') {
      const mode = get(inputConfig, 'mode', undefined)
      let selectValue
      if (mode === 'multiple') {
        if (Array.isArray(value)) {
          selectValue = value.length ? value.map((value: any) => value.toString()) : []
        } else {
          selectValue = [value]
        }
      } else {
        selectValue = value ? value.toString() : ''
      }
      return (
        <Select
          items={get(inputConfig, 'items', [])}
          value={value === undefined ? value : selectValue}
          label={label}
          onChange={get(inputConfig, 'onChange', undefined)}
          onSearch={get(inputConfig, 'onSearch', undefined)}
          onClear={get(inputConfig, 'onClear', undefined)}
          disabled={get(inputConfig, 'disabled', false)}
          withLookup={get(inputConfig, 'withLookup', false)}
          required={get(inputConfig, 'required', false)}
          error={get(inputConfig, 'error', false)}
          errorMessage={get(inputConfig, 'errorMessage', false)}
          placeholder={get(inputConfig, 'placeholder')}
          mode={mode}
          orderItems={get(inputConfig, 'orderItems', false)}
          orderKey={get(inputConfig, 'orderKey', '')}
          message={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          filterOption={get(inputConfig, 'filterOption')}
        />
      )
    }
    if (inputType === 'input') {
      return (
        <Input
          label={label}
          value={value == null ? undefined : value.toString()}
          onChange={get(inputConfig, 'onChange', undefined)}
          placeholder={get(inputConfig, 'placeholder', 'Max 250 char')}
          required={get(inputConfig, 'required', false)}
          error={get(inputConfig, 'error', false)}
          typeOfSelect={dataType}
          disabled={get(inputConfig, 'disabled', false)}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          message={get(tooltipConfig, 'message', '')}
          maxLength={get(inputConfig, 'max')}
        />
      )
    }
    if (inputType === 'number') {
      return (
        <InputNumber
          label={label}
          value={value == null ? undefined : +value}
          onChange={get(inputConfig, 'onChange', () => {})}
          minNumber={get(inputConfig, 'min')}
          maxNumber={get(inputConfig, 'max')}
          controls={get(inputConfig, 'hideSideBtn', false)}
          hideSideButton={get(inputConfig, 'hideSideBtn', false)}
          step={get(inputConfig, 'step')}
          errorPosition="bottom"
          errorMessage={get(inputConfig, 'errorMessage', '')}
          placeholder={get(inputConfig, 'placeholder')}
          helperText={get(inputConfig, 'helperText')}
          disabled={get(inputConfig, 'disabled')}
        />
      )
    }
    if (inputType === 'switch') {
      return (
        <Switch
          label={label}
          onChange={get(inputConfig, 'onChange', undefined)}
          disabled={get(inputConfig, 'disabled', false)}
          value={typeof value === 'boolean' ? value : false}
        />
      )
    }
    if (inputType === 'options-button' && !Array.isArray(value)) {
      return (
        <RadioInput
          label={label}
          options={get(inputConfig, 'options', [])}
          direction={get(inputConfig, 'direction', 'horizontal')}
          value={value === undefined ? '' : value}
          onChange={get(inputConfig, 'onChange', undefined)}
          disabled={get(inputConfig, 'disabled', false)}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          tooltipMessage={get(tooltipConfig, 'message', '')}
        />
      )
    }
    if (inputType === 'upload') {
      return (
        <Form.Item>
          <UploadDoc
            value={value ? value.toString() : ''}
            fileKey={get(inputConfig, 'fileKey', 'image')}
            topMessage={get(inputConfig, 'topMessage', undefined)}
            placeholder={get(inputConfig, 'placeholder', undefined)}
            bottomMessage={get(inputConfig, 'bottomMessage', undefined)}
            onChange={get(inputConfig, 'onChange', undefined)}
            imageAlt={get(inputConfig, 'imageAlt', 'info')}
            fileType={get(inputConfig, 'fileType', undefined)}
            isUrl={get(inputConfig, 'isUrl', false)}
            enableCharRule={get(inputConfig, 'enableCharRule', false)}
          />
        </Form.Item>
      )
    }
    if (inputType === 'input-tag' && Array.isArray(value)) {
      let tagsValue
      if (Array.isArray(value)) {
        tagsValue = value.length ? value.map((value: any) => value.toString()) : []
      } else {
        tagsValue = [value]
      }
      return (
        <InputTags
          label={label}
          disabled={get(inputConfig, 'disabled', false)}
          value={tagsValue}
          placeholder={get(inputConfig, 'placeholder', undefined)}
          onChange={get(inputConfig, 'onChange', undefined)}
        />
      )
    }
    return null
  }

  const getInfoComponent = () => {
    if (dataType === 'options-value') {
      const optionsList = get(inputConfig, 'options', [])
      const option = optionsList.find((op: IOption) => op.value === value)
      let optionValue = ''
      if (option) {
        optionValue = option.label
      }
      if (optionValue.toLowerCase() === 'null') {
        optionValue = '-'
      }
      return (
        <BoxInfo
          label={label}
          value={optionValue}
          title={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          warnMessage={get(warningConfig, 'message', '')}
          position={get(tooltipConfig, 'position', undefined)}
        />
      )
    }
    if ((dataType === 'number' || dataType === 'string') && !Array.isArray(value)) {
      const processedValue = value && value === 'Null' ? '-' : value
      return (
        <BoxInfo
          label={label}
          value={processedValue || ''}
          title={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          warnMessage={get(warningConfig, 'message', '')}
          position={get(tooltipConfig, 'position', undefined)}
        />
      )
    }
    if (dataType === 'date') {
      return (
        <BoxInfo
          label={label}
          value={value ? value.toString() : value}
          isDate
          title={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          warnMessage={get(warningConfig, 'message', '')}
          position={get(tooltipConfig, 'position', undefined)}
        />
      )
    }
    if (Array.isArray(value)) {
      return (
        <BoxInfo
          label={label}
          value={value.join(', ')}
          title={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          warnMessage={get(warningConfig, 'message', '')}
          position={get(tooltipConfig, 'position', undefined)}
        />
      )
    }
    if (dataType === 'tags' && value != null) {
      let valueList
      if (!Array.isArray(value)) {
        valueList = value
          .toString()
          .trim()
          .split(/[,\s]+/)
      } else {
        valueList = value
      }
      return (
        <TagsContainer isEditing={isEditing}>
          {valueList.map((val, idx: number) => (
            <Tag
              key={`${val}-${idx}`}
              value={val.toString()}
              path={idx.toString()}
              closable={false}
            />
          ))}
        </TagsContainer>
      )
    }
    if (dataType === 'image' && value !== null) {
      return (
        <ImagePreview
          value={value ? value.toString() : ''}
          alt={isString(label) ? label : 'picture'}
          isUrl={get(inputConfig, 'isUrl', false)}
        />
      )
    }
    if (dataType === 'link') {
      return (
        <BoxInfo
          label={label}
          value={value || ''}
          title={get(tooltipConfig, 'message', '')}
          enableTooltip={get(tooltipConfig, 'enable', false)}
          warnMessage={get(warningConfig, 'message', '')}
          position={get(tooltipConfig, 'position', undefined)}
        >
          {value && (
            <Link
              to={get(linkConfig, 'path', '/')}
              target={get(linkConfig, 'target', '_blank')}
              state={get(linkConfig, 'state')}
            >
              {value}
            </Link>
          )}
        </BoxInfo>
      )
    }
    return null
  }

  return <>{!isEditing ? getInfoComponent() : getInputComponent()}</>
}

export default InfoCell
