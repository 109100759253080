import { matchRoutes, useLocation } from 'react-router-dom'

import { AppRoutes } from '../pages/constants'

export const useCurPatternPath = () => {
  const routes = Object.values(AppRoutes).map((value) => ({ path: value }))

  const location = useLocation()
  const [{ route }] = matchRoutes(routes, location)

  return route.path
}
