import { TABLE_DATA_TYPE } from '../../../components/Table/constants'
import { ITableConfig } from '../../../components/Table/types'

export const SUPPLIER_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  SAPCodes: {
    label: 'SAP Codes',
    dataPath: 'SAPCodes',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  name: {
    label: 'Name',
    dataPath: 'name',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  locality: {
    label: 'Locality',
    dataPath: 'locality',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  country: {
    label: 'Country',
    dataPath: 'country',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  groupName: {
    label: 'Group Supplier Name',
    dataPath: 'groupName',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  error: {
    label: 'Error',
    dataPath: 'errors',
    dataType: TABLE_DATA_TYPE.TEXT
  }
}

export const FSCHEMA_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  formulaSchemaCode: {
    label: 'Formula Schema Code',
    dataPath: 'body.formulaSchemaCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  error: {
    label: 'Error',
    dataPath: 'error',
    dataType: TABLE_DATA_TYPE.TEXT
  }
}

export const FORMULA_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  formulaSchemaCode: {
    label: 'Formula Code DevEx',
    dataPath: 'body.formulaCodeDEVEX',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  error: {
    label: 'Error',
    dataPath: 'error',
    dataType: TABLE_DATA_TYPE.TEXT
  }
}

export const ANAGRAPHIC_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  productForm: {
    label: 'Product Form',
    dataPath: 'productForm',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  formulaSchemaCode: {
    label: 'Formula Schema',
    dataPath: 'formulaSchemaCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  industrialCode: {
    label: 'Industrial Code',
    dataPath: 'industrialCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  labCode: {
    label: 'Lab Code',
    dataPath: 'labCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  groupCode: {
    label: 'Multipack Code',
    dataPath: 'groupCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  error: {
    label: 'Error',
    dataPath: 'error',
    dataType: TABLE_DATA_TYPE.TEXT
  }
}

export const RELATIONS_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataPath: 'packagingCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  relationshipType: {
    label: 'Relationship',
    dataPath: 'relationshipType',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  relatedPackagingCode: {
    label: 'Other/Equivalent Related PK Intercos Code',
    dataPath: 'relatedPackagingCode'
  },
  errors: {
    label: 'Errors',
    dataPath: 'errors',
    dataType: TABLE_DATA_TYPE.ARRAY
  }
}

export const PRICE_FAILED: ITableConfig = {
  id: {
    dataPath: 'id',
    hide: true
  },
  packagingCode: {
    label: 'PK Intercos Code',
    dataPath: 'packagingCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  targetPrice: {
    label: 'Target Price',
    dataPath: 'targetPrice',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  currencyCode: {
    label: 'Currency',
    dataPath: 'currencyCode',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  validityFrom: {
    label: 'Validity From',
    dataPath: 'validityFrom',
    dataType: TABLE_DATA_TYPE.DATE
  },
  validityTo: {
    label: 'Validity To',
    dataPath: 'validityTo',
    dataType: TABLE_DATA_TYPE.DATE
  },
  countryOfOrigin: {
    label: 'COO',
    dataPath: 'countryOfOrigin',
    dataType: TABLE_DATA_TYPE.TEXT
  },
  errors: {
    label: 'Errors',
    dataPath: 'errors',
    dataType: TABLE_DATA_TYPE.ARRAY
  }
}
