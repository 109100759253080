import styled from 'styled-components'

export const StyledCartItemCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  min-height: 220px;
  box-shadow: 1px 1px 5px 1px lightgrey;
  gap: 20px;
`
export const ImageWrapper = styled.div`
  width: 35%;
  div {
    width: 100%;
  }
  img {
    height: 170px;
    width: 100%;
    object-fit: cover;
    margin: 0px;
  }
`
