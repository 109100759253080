import { Form } from 'antd'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { FC } from 'react'

import InfoCell from '@/components/InfoCell'
import { IAttachmentFormProps } from './types'

const AttachmentForm: FC<IAttachmentFormProps> = ({ data, onUpdate }) => {
  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          label="Name"
          value={data.name || ''}
          isEditing
          inputConfig={{
            onChange: (value: any) => onUpdate('name', value)
          }}
        />
        {/* NOTE - temporary hidden */}
        {/* <InfoCell
          label="Is Image"
          inputType="switch"
          value={newAttachment.isImage || false}
          isEditing
          inputConfig={{
            onChange: (value: any) => updateNewAttachment('isImage', value)
          }}
        /> */}
        <InfoCell
          label="Sap Code Enabled"
          inputType="switch"
          value={data.sapCodeEnabled || false}
          isEditing
          inputConfig={{
            onChange: (value: any) => onUpdate('sapCodeEnabled', value)
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default AttachmentForm
