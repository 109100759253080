import styled from 'styled-components'

export const ReadOnlyWrapper = styled.div`
  padding-top: 8px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Value = styled.span`
  font-size: medium;
  word-break: break-all;
`
