import { ITableConfig } from '../../components/Table/types'
import { IError } from '../../containers/Error/types'
import { TAppLoaders } from '../app/model'

export interface ISuccessInfo {
  title: string
  message: string
}

export const ORDER_STATUS_LIST = {
  FORWARDED: 'FORWARDED',
  ACCEPTED: 'ACCEPTED',
  PROCESSED: 'PROCESSED',
  REFUSED: 'REFUSED'
} as const

export type TOrderStatus = keyof typeof ORDER_STATUS_LIST

export type TOrderListTablesConfig = {
  [key in TOrderStatus]: ITableConfig
}

export type TOrderListColors = {
  [key in TOrderStatus]: string
}

export type TOrderListTab = {
  label: string
  value: TOrderStatus
}
export const ORDER_ADDRESSED_TO = {
  CLIENT: 'CLIENT',
  EVENT: 'EVENT',
  OTHER: 'OTHER'
} as const

export type TOrderAddressedTo = keyof typeof ORDER_ADDRESSED_TO

export interface IOrderItem {
  id: string
  orderId?: string
  orderableItemId?: string
  productCode?: string | null
  quantity?: number
}
export interface IOrderData {
  id: string
  createdDate?: string
  fullfillmentDate?: string
  updatedDate?: string
  addressedTo?: TOrderAddressedTo
  ownerEmail?: string
  ownerName?: string
  ownerSurname?: string
  status?: TOrderStatus
  refuseReason?: string | null
  refuseNote?: string | null
  additionalNote?: string
  notes?: string
  required?: boolean
  shipTo?: string
  regionCountry?: string
  orderItems?: IOrderItem[]
  acceptNote?: string
  processNote?: string
  orderCode?: string
  ownerRoles?: string[]
}

export interface IPagination {
  totalPages?: number
  page?: number
  pageSize?: number
  total?: number
}

export interface IOrderListFilters {
  [k: string]: any
}

export interface IOrderListState {
  selectedTab: TOrderStatus
  filters: IOrderListFilters
  tempFilters: IOrderListFilters
  dropDown: Record<string, any>
  pagination: IPagination
  items: IOrderData[]
  orderSelected: Partial<IOrderData>
  latestForwarded: Record<string, any>
  latestRefused: Record<string, any>
  latestProcessed: Partial<IOrderData>
  tempOrder: Partial<IOrderData>
  successInfo?: ISuccessInfo
  error: IError
}

export interface IOrdersResultSearch {
  results: IOrderData[]
  page: number
  pageSize: number
  totalPages: number
  total: number
}

export interface IReasonApiData {
  refuseReason: string
  refuseNote: string
}

export interface ISearchOrdersActionArg {
  loader: TAppLoaders
}

export interface IGetOrderDetailsActionArg {
  id: string
  loader: TAppLoaders
}
