import { useSelector } from 'react-redux'
import { InfoCell, SectionTitle } from '../../components'
import { BoxWrapper } from '../../components/BoxWrapper'
import { Divider } from '../../components/Divider'
import StatusTag from '../../components/StatusTag'
import { ORDER_STATUS_COLOR } from '../../features/orderList/constants'
import { TOrderStatus } from '../../features/orderList/models'

import {
  selectOrderSelected,
  getOrderListDropdownByKey
} from '../../features/orderList/selectors'
import { orderDataExtractor } from '../../utils/orderHelpers'
import GeneralInfo from '../Order/GeneralInfo'
import ProductList from '../ProductList'
import { OrderDetailsContainer } from './styled'

const OrderDetails = () => {
  const orderData = useSelector(selectOrderSelected)
  const refuseReasonDropdown = useSelector(getOrderListDropdownByKey('refuseReason'))
  const { orderItems, status, refuseReason, refuseNote, acceptNote, processNote } =
    orderDataExtractor(orderData)
  const refuseReasonVal =
    refuseReasonDropdown?.reduce(
      (acc: string, { value, name }: { value: string; name: string }) => {
        if (value === refuseReason) acc = name
        return acc
      },
      ''
    ) || ''

  const isRefused = status === 'REFUSED'
  const isAccepted = status === 'ACCEPTED'
  const isProcessed = status === 'PROCESSED'
  const hasNotes = isRefused || isAccepted || isProcessed

  return (
    <OrderDetailsContainer>
      <StatusTag label={status} tagColor={ORDER_STATUS_COLOR[status as TOrderStatus]} />
      {hasNotes && (
        <>
          <SectionTitle value="Notes" />
          <Divider />
          <BoxWrapper enablePadding>
            {isRefused && (
              <>
                <InfoCell label="Refusal feedback" value={refuseReasonVal} />
                <InfoCell label="Refusal Motivation" value={refuseNote} />
              </>
            )}
            {isAccepted && <InfoCell label="Acceptance Notes" value={acceptNote} />}
            {isProcessed && <InfoCell label="Process Notes" value={processNote} />}
          </BoxWrapper>
        </>
      )}
      <GeneralInfo data={orderData} />
      <ProductList productList={orderItems} />
    </OrderDetailsContainer>
  )
}

export default OrderDetails
