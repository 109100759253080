// @ts-nocheck
import { FC } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { StyledActions } from './styled'
import { ILinkToProps } from './types'

export const flattenObj = (ob) => {
  // The object which contains the
  // final result
  const result = {}

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i])

      for (const j in temp) {
        // Store temp in result
        // if (temp[j] !== '') result[i + '' + j] = temp[j]
        result[i + '' + j] = temp[j]
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i]
      // if (ob[i] !== '') result[i] = ob[i]
    }
  }
  return result
}

export const LinkTo: FC<ILinkToProps> = ({
  pathname,
  value = {},
  text,
  target,
  state
}) => {
  const dataPathname = flattenObj(value)

  if (!pathname) return text

  const path = generatePath(pathname, dataPathname)

  return (
    <StyledActions>
      <Link to={path} target={target} state={state}>
        {text}
      </Link>
    </StyledActions>
  )
}
