import { FC } from 'react'

import { get } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { palette } from '../../../../assets/colors'
import { BoxInfo } from '../../../../components/BoxInfo'
import { BoxWrapper } from '../../../../components/BoxWrapper'
import { Divider } from '../../../../components/Divider'
import { DatePicker } from '../../../../components/Form/DatePicker'
import InfoCell from '../../../../components/InfoCell'
import { Text } from '../../../../components/Text'
import { selectCountryOfOriginDropDownItems } from '../../../../features/packaging/selectors'
import { selectUserRole } from '../../../../features/users/selectors'
import { checkFieldVisibilityByCategory } from '../../../../utils/packagingFormHelper'
import {
  checkFieldVisibilityByRoles,
  refactorTargetPrice
} from '../../../../utils/packagingHelpers'
import { canUserEditTargetPrice } from '../../../../utils/permissionsHelpers'

import { currencyDropdownValues } from './constants'
import { ITargetPriceProps, TIsFieldVisibleFunc } from './types'

const TargetPrice: FC<ITargetPriceProps> = ({
  data = {},
  isEditing = false,
  updateData = () => {}
}) => {
  const userRoles = useSelector(selectUserRole)
  const countryOfOriginDropDownItems = useSelector(selectCountryOfOriginDropDownItems)

  const categoryValue: string = get(data, 'category', '')
  const subCategoryValue: string = data.subcategory || ''

  const category = categoryValue.toLowerCase()
  const subCategory = subCategoryValue.toLowerCase()

  const canEditTargetPrice = canUserEditTargetPrice({ userRoles })
  const validityFromDateVal = get(data, 'validityFrom')

  const validityFromDate =
    validityFromDateVal && moment(validityFromDateVal).isValid()
      ? moment(validityFromDateVal).utc()
      : null

  const validityToDateVal = get(data, 'validityTo')
  const validityToDate =
    validityToDateVal && moment(validityToDateVal).utc().isValid()
      ? moment(validityToDateVal).utc()
      : null

  const targetPriceValueInRead =
    get(data, 'USDTargetPrice', '') &&
    refactorTargetPrice({ value: get(data, 'USDTargetPrice', ''), currency: null })

  const isFieldVisible: TIsFieldVisibleFunc = (input) => {
    const { key, userRoles } = input
    let visibility = true

    visibility =
      visibility &&
      checkFieldVisibilityByCategory({
        key,
        category: category,
        subcategory: subCategory
      })
    visibility =
      visibility &&
      checkFieldVisibilityByRoles({
        key,
        roles: userRoles
      })
    return visibility
  }

  const countryOfOriginCode = get(data, 'countryOfOriginCode', '')
  const countryOfOriginValue = countryOfOriginDropDownItems.find(
    ({ value }) => value === countryOfOriginCode
  )

  const countryOfOriginDescription = get(countryOfOriginValue, 'description')

  return (
    <>
      <Text text="Target Price" variant="h3" />
      <Text
        text="*Target Price is a proxy, based on injection + 1 SS or HS and on MOQ of 10000 pcs. For prices under the 10K q.ty or with complex deco please open a specific request as per standard flow"
        color={palette.primary.main}
        variant="h4"
      />
      <Divider />
      <>
        <BoxWrapper enablePadding>
          {isFieldVisible({
            key: 'USDTargetPrice',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Target Price"
              value={
                isEditing && canEditTargetPrice
                  ? get(data, 'targetPrice', '')
                  : targetPriceValueInRead
              }
              dataType="number"
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                placeholder: '',
                onChange: (value: string) => updateData('targetPrice', parseInt(value)),
                required: true,
                error:
                  'targetPrice' in data &&
                  (!data?.targetPrice || isNaN(data?.targetPrice))
              }}
              tooltipConfig={{
                enable: true,
                message: 'Type code for fixed quantity + 1 injection/glass + 1 deco'
              }}
            />
          )}
          {isFieldVisible({
            key: 'currency',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Currency"
              value={
                !isEditing || !canEditTargetPrice ? 'USD' : get(data, 'currencyCode', '')
              }
              inputType="select"
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                items: currencyDropdownValues,
                onChange: ({ value }: Record<string, any>) =>
                  updateData('currencyCode', value),
                orderItems: true,
                orderKey: 'name',
                required: true,
                error: 'currencyCode' in data && !data.currencyCode
              }}
            />
          )}

          {isFieldVisible({
            key: 'MOQ',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="MOQ"
              value={'10.000'}
              isEditing={false}
              inputConfig={{
                onChange: (value: string) => updateData('MOQ', value)
              }}
            />
          )}
          {isFieldVisible({
            key: 'validityFrom',
            userRoles: userRoles
          }) &&
            (isEditing ? (
              <DatePicker
                label="Validity From"
                onChange={(value: any) => {
                  const newValue = value && moment(value.utc().format())
                  updateData('validityFrom', newValue)
                }}
                value={validityFromDate}
                maxDate={validityToDate}
              />
            ) : (
              <BoxInfo label={'Validity From'} value={validityFromDateVal} isDate />
            ))}
          {isFieldVisible({
            key: 'validityTo',
            userRoles: userRoles
          }) &&
            (isEditing ? (
              <DatePicker
                label="Validity To"
                onChange={(value: any) => {
                  const newValue = value && moment(value.utc().format())
                  updateData('validityTo', newValue)
                }}
                value={validityToDate}
                minDate={validityFromDate}
              />
            ) : (
              <BoxInfo label={'Validity To'} value={validityToDateVal} isDate />
            ))}
          {isFieldVisible({
            key: 'countryOfOriginCode',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Country of Origin"
              inputType="select"
              value={countryOfOriginCode}
              isEditing={isEditing && canUserEditTargetPrice({ userRoles })}
              inputConfig={{
                items: countryOfOriginDropDownItems,
                onChange: ({ value }: Record<string, any>) =>
                  updateData('countryOfOriginCode', value),
                orderItems: true,
                orderKey: 'name',
                required: true,
                error: 'countryOfOriginCode' in data && !data.countryOfOriginCode
              }}
            />
          )}
          {isFieldVisible({
            key: 'countryOfOriginDescription',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="COO Description"
              value={countryOfOriginDescription}
              isEditing={false}
            />
          )}

          {isFieldVisible({
            key: 'priceNotes',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Pricing notes"
              value={get(data, 'priceNotes', '')}
              isEditing={isEditing && canEditTargetPrice}
              inputConfig={{
                onChange: (value: string) => updateData('priceNotes', value)
              }}
            />
          )}
          {/* NOTE - CR124 - these fields are temporary hidden
           the conditions for visbitlity by roles should be correct
          */}
          {/* {isFieldVisible({
            key: 'priceUpdatedBy',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Target Price Created By"
              value={get(data, 'priceUpdatedBy', '')}
              isEditing={false}
            />
          )}
          {isFieldVisible({
            key: 'priceUpdatedAt',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Target Price Creation Date"
              dataType="date"
              value={get(data, 'priceUpdatedAt', '')}
              isEditing={false}
            />
          )}
          {isFieldVisible({
            key: 'priceUpdatedByLegalEntity',
            userRoles: userRoles
          }) && (
            <InfoCell
              label="Created By Group Legal Entity"
              value={get(data, 'priceUpdatedByLegalEntity', '')}
              isEditing={false}
            />
          )} */}
        </BoxWrapper>
      </>
    </>
  )
}

export default TargetPrice
