import { get } from 'lodash'
import { FC } from 'react'
import Table from '../../components/Table'
import { columnsGeneratorHelper, rowsGeneratorHelper } from '../../utils'
import { IAttachmentsTable } from './types'

const AttachmentsTable: FC<IAttachmentsTable> = ({
  tableConfig,
  actionConfig,
  dataList,
  enableRowSelect,
  onSelectRow,
  isSingleSelect,
  emptyBody,
  childActionConfig = {},
  pagination,
  enablePagination,
  paginationPosition,
  overTableHeight = 0,
  forceHeight = false
}) => {
  const columnsKeyToHide = [
    'children',
    'url',
    'attachmentContentType',
    'attachmentContentTypeId'
  ]

  const columnGenerator = () => {
    const cols = columnsGeneratorHelper(tableConfig)
    const filteredColumns = cols.filter(({ key }) => !columnsKeyToHide.includes(key))
    return filteredColumns
  }

  const rowsGenerator = () => {
    const newList = rowsGeneratorHelper(dataList, tableConfig)
    return newList.map((data, index) => {
      const singleData = dataList[index]
      return {
        ...data,
        id: get(singleData, 'id', '')
      }
    })
  }

  const columns = columnGenerator()
  const dataRow = dataList.length ? rowsGenerator() : []

  return (
    <Table
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={actionConfig}
      enableRowSelect={enableRowSelect}
      onSelectRow={onSelectRow}
      isSingleSelect={isSingleSelect}
      childActionConfig={childActionConfig}
      emptyBody={emptyBody}
      pagination={pagination}
      enablePagination={enablePagination}
      paginationPosition={paginationPosition}
      contentOverTableHeight={overTableHeight}
      forceHeight={forceHeight}
    />
  )
}

export default AttachmentsTable
