import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table, TooltipBtn } from '../../../components'
import DetailBtn from '../../../components/Table/ActionsCol/DetailBtn'
import { ITActionColumnConfig } from '../../../components/Table/types'
import { packagingActions } from '../../../features/packaging'
import { CATEGORY, PackagingCreationType } from '../../../features/packaging/model'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import {
  packagingResultColumnGenerator,
  packagingResultRowGenerator
} from '../../../utils/packagingHelpers'
import { canCopyOrDeletePackaging } from '../../../utils/permissionsHelpers'
import { DeletePackaging } from '../DeletePackaging'
import { IPackagingTableProps } from './types'

const PackagingTable: FC<IPackagingTableProps> = ({
  dataList,
  enablePagination = false,
  onChangePage = () => {},
  isLoading,
  pageData = { page: 1, pageSize: 10, total: 10 },
  enableRowSelect = false,
  selectedRows = [],
  onSelectRow = () => {},
  isSingleSelect = false,
  hasAction = false
}) => {
  const dispatch = useDispatch()

  const { page, pageSize, total } = pageData
  const userRoles = useSelector(selectUserRole)
  const navigate = useNavigate()

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      if (data.isChild) {
        return null
      }
      const isMulti = get(data, 'children', []).length > 1
      const isVial = get(data, 'category', '').toLowerCase() === CATEGORY.VIALS
      const flowType = isMulti
        ? PackagingCreationType.MULTI
        : isVial
          ? PackagingCreationType.VIAL
          : PackagingCreationType.NO_VIAL
      return (
        <>
          <DetailBtn
            btnLabel={'Detail'}
            btnVariant={'text'}
            destinationPath={AppRoutes.WIKIPACK_PACKAGING_DETAILS}
            rec={data}
          />
          {canCopyOrDeletePackaging({ userRoles }) && (
            <>
              <TooltipBtn
                btnLabel={'Copy'}
                message={'Do you want to copy this packaging?'}
                onConfirm={() => {
                  dispatch(packagingActions.copyPackage({ id: data.id }))
                  navigate(`${AppRoutes.WIKIPACK_PACKAGE_CREATION}/${flowType}`)
                }}
                btnVariant={'text'}
              />

              <DeletePackaging
                rec={data}
                message={'Do you want to delete this packaging?'}
                btnType="link"
                succcesCb={() => {
                  dispatch(packagingActions.getPackages({ hasloader: false }))
                }}
              />
            </>
          )}
        </>
      )
    }
  }

  const columns = packagingResultColumnGenerator({ userRoles })
  const dataRow = dataList.length ? packagingResultRowGenerator({ dataList }) : []

  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={'TOP_RIGHT'}
      rowKey="id"
      columns={columns}
      items={dataRow}
      actionColumnConfig={hasAction ? actionConfig : undefined}
      forceHeight
      isLoading={isLoading}
      enableRowSelect={enableRowSelect}
      selectedRows={selectedRows}
      onSelectRow={onSelectRow}
      isSingleSelect={isSingleSelect}
      contentOverTableHeight={20}
    />
  )
}

export default PackagingTable
