import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  AZ_DBPRODOTTO_ADMIN,
  AZ_DBPRODOTTO_FS_READ,
  AZ_DBPRODOTTO_IMPORT_FS,
  AZ_DBPRODOTTO_IMPORT_PACKAGE,
  AZ_DBPRODOTTO_IMPORT_PRODUCT,
  AZ_DBPRODOTTO_PACKAGING_ADMIN,
  AZ_DBPRODOTTO_PACKAGING_CREATE,
  AZ_DBPRODOTTO_PACKAGING_EDIT,
  AZ_DBPRODOTTO_PACKAGING_READ,
  AZ_DBPRODOTTO_PRODUCT_CREAT,
  AZ_DBPRODOTTO_PRODUCT_EDIT,
  AZ_DBPRODOTTO_PRODUCT_READ,
  AZ_DBS_SALA_CAMPIONI_AGREGATE,
  AZ_DBS_SALES_MARKETING_BU_MERCATI,
  AZ_VAULT_TOOLING_READ,
  AZ_VAULT_TOOLING_WRITE,
  AZ_WIKIPACK_PKDEV,
  AZ_WIKIPACK_SRC,
  AZ_WIKIPACK_SRC_ADMIN
} from '../../constants/users'
import { getVaultProductCanSeeValues } from '../../utils'
import { canUserExportTargetPrice } from '../../utils/permissionsHelpers'
import {
  getStoreProductCanSeeField,
  getVaultFSchemaCanSeeField,
  getWikipackVariantCanSeeValues
} from '../../utils/rolesHelpers'

import { IPermissions, UserState } from './model'

const initialState: UserState = {
  roles: undefined,
  data: {},
  permissions: {}
}

// NOTE - PERMISSIONS VALUES:
// - false -> those keys are not used yet
// - true  -> those keys are used but there is no paricular conditions
const permissionsUpdate = (
  state: UserState,
  { payload }: PayloadAction<{ roles: string[] }>
) => {
  const { roles } = payload
  const permissions: IPermissions = {
    vaultProduct: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PRODUCT_CREAT),
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PRODUCT_READ) ||
        roles.includes(AZ_DBPRODOTTO_PRODUCT_CREAT) ||
        roles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT),
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_DBPRODOTTO_PRODUCT_EDIT),
      canDelete: false,
      canSeeField: {},
      canSeeValue: getVaultProductCanSeeValues(roles)
    },
    vaultTooling: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_VAULT_TOOLING_WRITE),
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_VAULT_TOOLING_WRITE) ||
        roles.includes(AZ_VAULT_TOOLING_READ),
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_VAULT_TOOLING_WRITE),
      canDelete:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_VAULT_TOOLING_WRITE),
      canSeeField: {},
      canSeeValue: {}
    },
    wikipackPackaging: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_CREATE),
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_READ),
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT),
      canDelete: roles.includes(AZ_DBPRODOTTO_ADMIN),
      canExportTargetPrice: canUserExportTargetPrice({ userRoles: roles }),
      canSeeField: {},
      canSeeValue: {}
    },
    wikipackVariant: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT) ||
        roles.includes(AZ_WIKIPACK_SRC),
      canRead: true,
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_EDIT),
      canDelete: true,
      canSeeField: {},
      canSeeValue: getWikipackVariantCanSeeValues(roles)
    },
    wikipackMaster: {
      canCreate: false,
      canRead: roles.includes(AZ_WIKIPACK_PKDEV) || roles.includes(AZ_DBPRODOTTO_ADMIN),
      canUpdate: false,
      canDelete: true,
      canSeeField: {},
      canSeeValue: {}
    },
    vaultFormulaSchema: {
      canCreate: false,
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_DBPRODOTTO_FS_READ),
      canUpdate: false,
      canDelete: false,
      canSeeField: getVaultFSchemaCanSeeField(roles),
      canSeeValue: {}
    },
    storeProduct: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI),
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      canDelete:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      canSeeField: getStoreProductCanSeeField(roles),
      canSeeValue: {}
    },
    storeOrders: {
      canCreate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI),
      canRead:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI),
      canUpdate:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI),
      canDelete: false,
      canSeeField: {},
      canSeeValue: {}
    },
    administration: {
      canReach:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_IMPORT_FS) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_IMPORT_PACKAGE) ||
        roles.includes(AZ_DBPRODOTTO_IMPORT_PRODUCT) ||
        roles.includes(AZ_WIKIPACK_SRC_ADMIN) ||
        roles.includes(AZ_WIKIPACK_SRC) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      canImportSupplier:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN),
      canManagegAttach:
        roles.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_ADMIN),
      canManageSupplier:
        roles.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_ADMIN),
      canImportAnagraphic:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      canImportPrice:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_WIKIPACK_SRC),
      canImportRelations:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBPRODOTTO_PACKAGING_ADMIN),
      canManagePricing:
        roles.includes(AZ_DBPRODOTTO_ADMIN) || roles.includes(AZ_WIKIPACK_SRC_ADMIN)
    },
    support: {
      vault: true,
      store:
        roles.includes(AZ_DBPRODOTTO_ADMIN) ||
        roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE) ||
        roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    },
    is: {
      admin: roles.includes(AZ_DBPRODOTTO_ADMIN),
      salaCampioni: roles.includes(AZ_DBS_SALA_CAMPIONI_AGREGATE),
      salesMarketBu: roles.includes(AZ_DBS_SALES_MARKETING_BU_MERCATI)
    }
  }
  state.permissions = permissions
}

const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeRole: (state: UserState, { payload }: PayloadAction<string>) => {
      state.roles = [payload]
    },
    setUserData: (state: UserState, { payload }: PayloadAction<string>) => {
      state.data = payload
    },
    setUserRoles: (state: UserState, { payload }: PayloadAction<string[]>) => {
      state.roles = payload
    },
    setPermissions: permissionsUpdate
  }
})

export const userSliceActions = usersSlice.actions

export default usersSlice.reducer
