import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Advice } from '../../../../components/Advice'
import Button from '../../../../components/Button'
import { Modal } from '../../../../components/Modal'
import FSchemaResultSection from '../../../../containers/FormulaSchema/FSchemaResultSection'
import { FSchemaSearchForm } from '../../../../containers/FormulaSchema/FSchemaSearchForm'
import { appActions } from '../../../../features/app'
import { fSchemaActions, fSchemaSliceActions } from '../../../../features/formulaSchema'
import {
  getSchemaTempFilters,
  selectFSchemaError
} from '../../../../features/formulaSchema/selectors'
import { ModalBodyContainer } from './styled'
import { IAddFSchemaModalProps } from './types'

const AddFSchemaModal: FC<IAddFSchemaModalProps> = ({
  onAddFSchema,
  modalKey,
  isSingleSelect = false
}) => {
  const dispatch = useDispatch()
  const tempFilter = useSelector(getSchemaTempFilters)

  const [isResult, setIsResult] = useState(false)
  const [selectedFSchema, setSelectedFSchema] = useState<any[]>([])

  const fSchemaError = useSelector(selectFSchemaError)

  const addFSchemaHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    onAddFSchema(selectedFSchema)
  }

  const resetModalHandler = () => {
    setIsResult(false)
    dispatch(fSchemaSliceActions.resetPagination())
    dispatch(fSchemaSliceActions.clearTempFilters())
    setSelectedFSchema([])
    if (!isEmpty(fSchemaError)) {
      dispatch(fSchemaSliceActions.clearError())
    }
  }

  const searchHandler = () => {
    setIsResult(!isResult)

    dispatch(fSchemaSliceActions.applyFilters(tempFilter))
    dispatch(fSchemaActions.getFormulaSchema())
  }

  const checkIfDisabled = () => {
    return isEmpty(tempFilter)
  }

  const selectHandler = (rows: any[]) => {
    setSelectedFSchema(rows)
  }

  const backHandler = () => {
    setIsResult(false)
    setSelectedFSchema([])
    dispatch(fSchemaSliceActions.resetPagination())
  }

  const footer = !isResult
    ? [
        <Button
          key="search"
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      ]
    : [
        <Button key="back" label="Back" onClick={backHandler} />,
        <Button
          key="add"
          label="Add"
          disabled={!selectedFSchema.length}
          onClick={addFSchemaHandler}
        />
      ]

  return (
    <Modal
      btnName="ADD FORMULA SCHEMA"
      modalKey={modalKey}
      title="Filter Formula Schema"
      paddingBody="0px"
      footer={footer}
      btnClicked={resetModalHandler}
      centered
      size="large"
    >
      <ModalBodyContainer>
        {!isEmpty(fSchemaError) ? (
          <Advice
            status={fSchemaError?.status || '500'}
            subTitle={fSchemaError?.subTitle}
            title={fSchemaError?.title}
          >
            {fSchemaError?.message}
          </Advice>
        ) : (
          <>
            {!isResult && <FSchemaSearchForm />}
            {isResult && (
              <FSchemaResultSection
                enableSelect
                selectedRows={selectedFSchema}
                onSelect={selectHandler}
                isSingleSelect={isSingleSelect}
              />
            )}
          </>
        )}
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddFSchemaModal
