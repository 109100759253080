import { Form } from 'antd'
import { get } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { DatePicker } from '../../../components/Form/DatePicker'
import { Input } from '../../../components/Form/Input'
import { cartSliceActions } from '../../../features/cart/reducer'
import {
  selectAddress,
  selectCountry,
  selectDate
} from '../../../features/cart/selectors'
import { selectOrderListDropdown } from '../../../features/orderList/selectors'

export const Step3Cart = () => {
  const dispatch = useDispatch()
  const country = useSelector(selectCountry)
  const address = useSelector(selectAddress)
  const date = useSelector(selectDate)
  const dropdown = useSelector(selectOrderListDropdown)
  const countries = get(dropdown, 'countries', [])
  const [minShipDays, setMinShipDays] = useState(null)
  const handleChange = (value: string, reduxKey: string | undefined) => {
    dispatch(
      cartSliceActions.setDetail({
        reduxKey,
        value
      })
    )
  }

  const handleChangeDate = (value: string) => {
    dispatch(
      cartSliceActions.setDetail({
        reduxKey: 'date',
        value
      })
    )
  }

  const changeRegionHandler = (selectValue: Record<string, any>) => {
    setMinShipDays(selectValue?.days ? selectValue.days : 0)
    handleChange(selectValue?.value || '', 'country')
  }
  return (
    <>
      <Form layout="vertical">
        <BoxWrapper  enablePadding>
          <Input
            label="SHIP TO"
            placeholder="Insert address"
            value={address}
            onChange={handleChange}
            reduxKey="address"
          />
          <Select
            label="REGION/COUNTRY"
            items={countries}
            onChange={(value: Record<string, any>) => changeRegionHandler(value)}
            value={country}
          />
        </BoxWrapper>
        <Divider disableBorder={true} />
        <BoxWrapper  enablePadding>
          <DatePicker
            label="REQUESTED DATE OF FULLFILLMENT"
            onChange={handleChangeDate}
            value={date}
            minDays={minShipDays || 0}
            disabled={minShipDays === null}
            excludeDays={['sunday', 'saturday']}
          />
        </BoxWrapper>
      </Form>
    </>
  )
}
