import { AnyAction, PayloadAction } from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'
import { get } from 'lodash'
import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects'

import { refactorSearchPackageFilter } from '../../utils/packagingFormHelper'
import { importAttachments } from '../administration/api'
import { appSliceActions } from '../app/reducer'
import { prodSliceActions } from '../product'
import { getUserData, selectUserEmail } from '../users/selectors'

import { packagingActions } from './actions'
import {
  addFurtherPackaging,
  addRelatedPackaging,
  checkIfAlreadyRelated,
  createNewVersion,
  createPackaging,
  deleteFurtherPackaging,
  deleteOneAttachment,
  deletePackaging,
  deleteRelatedPackaging,
  deleteRelatedVariant,
  exportMacro,
  exportMicro,
  exportTargetPrice,
  findProductsWithPKG,
  getCountriesOfOriginDropdown,
  getPackageId,
  getTestedSupplierValues,
  getVariantId,
  insertAttachmentMulti,
  insertOneAttachment,
  savePackaging,
  searchPackaging,
  searchPackagingWithRelations,
  searchSuppliers,
  updateOneAttachment,
  updateOnePackageVariant,
  updateRelatedVariant
} from './api'
import {
  GET_COUNTRIES_OF_ORIGIN_DROPDOWN,
  PACKAGING_CHECK_STATUS,
  PACKAGING_CHECK_STATUS_ON_DELETE,
  PACKAGING_CHECK_SUPPLIER_REF,
  PACKAGING_COPY_ATTACHMENTS_FILTERS,
  PACKAGING_COPY_PACKAGE,
  PACKAGING_CREATE,
  PACKAGING_CREATE_VARIANT_IN_MONO,
  PACKAGING_CREATE_VARIANT_IN_MULTI,
  PACKAGING_DELETE_ATTACHMENT,
  PACKAGING_EXPORT_MACRO,
  PACKAGING_EXPORT_MICRO,
  PACKAGING_EXPORT_TARGET_PRICE,
  PACKAGING_FETCH_ATTACHMENT_TYPES,
  PACKAGING_FILTER_TO_TEMP,
  PACKAGING_FURTHER_VERSION_ADD,
  PACKAGING_FURTHER_VERSION_DELETE,
  PACKAGING_GET_DETAIL,
  PACKAGING_GET_PACKAGES,
  PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN,
  PACKAGING_RELATED_ADD,
  PACKAGING_RELATED_DELETE,
  PACKAGING_REMOVE_ATTACHMENT,
  PACKAGING_REMOVE_PACKAGE,
  PACKAGING_SAVE_NEW_VARIANT_VERSION,
  PACKAGING_SEARCH_WITH_RELATIONS,
  PACKAGING_TEMP_TO_FILTER,
  PACKAGING_UPDATE_PACKAGE,
  PACKAGING_UPDATE_VARIANT_ATTACHMENT,
  PACKAGING_VARIANT_ADD,
  PACKAGING_VARIANT_GET,
  PACKAGING_VARIANT_REMOVE,
  PACKAGING_VARIANT_REMOVE_FROM_PACKAGE,
  PACKAGING_VARIANT_UPDATE,
  SUPPLIERS_SEARCH
} from './constants'
import {
  CATEGORY,
  DetailVariant,
  PACKAGE_TYPE,
  PackagingDto,
  PackagingResultSearch,
  Pagination,
  VialsDto
} from './model'
import { packagingSliceActions } from './reducer'
import {
  getDropdownFilteredStatus,
  getPackageDetail,
  getPackageDetailId,
  getPackagingFilters,
  getPackagingItems,
  getPackagingTempFilters,
  getTestedSupplierName,
  selectEditing,
  selectNewAttachment,
  selectNewPackaging,
  selectNewVersion,
  selectPackagePagination,
  selectVariantEditing,
  selectVariantId
} from './selectors'
import {
  prepareMonoPackageData,
  prepareMultiPackageData,
  prepareUpdatePackageData
} from './utils'

function* fetchPackages({ payload }: AnyAction | PayloadAction) {
  const hasLoader = get(payload, 'hasLoader', true)
  if (hasLoader) {
    yield put(packagingSliceActions.setIsLoading(true))
  }
  try {
    const filters: Record<string, any> = yield select(getPackagingFilters)
    const { _all, status, ...otherFilters } = filters
    const refactoredFilters = refactorSearchPackageFilter(otherFilters)
    const newFilters: Record<string, any> = _all ? { _all } : refactoredFilters

    const filteredStatus: Record<string, any>[] = yield select(getDropdownFilteredStatus)

    newFilters['statuses'] =
      status && status.length ? status : filteredStatus.map(({ value }) => value)

    const { page: selectedPage, pageSize: selectedPageSize }: Pagination = yield select(
      selectPackagePagination
    )

    const packages: PackagingResultSearch = yield call(searchPackaging, {
      ...newFilters,
      page: selectedPage,
      pageSize: selectedPageSize
    })
    const { results, totalPages, page, pageSize, total } = packages

    yield put(packagingSliceActions.setPagination({ totalPages, page, pageSize, total }))
    yield put(packagingSliceActions.setPackaging({ data: results }))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(packagingSliceActions.setIsLoading(false))
    }
  }
}

function* fetchPackageDetail({ payload }: AnyAction | PayloadAction) {
  const { id, hasLoader = true } = payload

  if (hasLoader) {
    yield put(packagingSliceActions.setIsLoading(true))
  }

  try {
    const filteredStatus: Record<string, any>[] = yield select(getDropdownFilteredStatus)
    const packageById: PackagingDto = yield call(getPackageId, id)
    const currentStatuses = filteredStatus.map(({ value }) => value)
    const { furtherVersions, relatedPackages } = packageById
    const filteredFurtherVersions = furtherVersions?.filter((version) =>
      currentStatuses.includes(version?.status)
    )
    const filteredRelatedPackages = relatedPackages?.filter((version) =>
      currentStatuses.includes(version?.status)
    )

    yield put(
      packagingSliceActions.setDetail({
        ...packageById,
        image: { ...packageById.thumbnail, value: packageById.thumbnail?.url || '' },
        furtherVersions: filteredFurtherVersions,
        relatedPackages: filteredRelatedPackages
      })
    )

    yield fetchAttachmentsType()
  } catch (err: any) {
    yield put(packagingSliceActions.setDetail({}))
    yield put(packagingSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(packagingSliceActions.setIsLoading(false))
    }
  }
  // }
}

function* fetchDetailVariant({ payload }: AnyAction | PayloadAction) {
  const { variantId, packagingId, hasLoader = true } = payload
  if (hasLoader) {
    yield put(packagingSliceActions.setIsLoading(true))
  }
  try {
    yield put(packagingSliceActions.setDetailVariant({}))
    const variantDetail: VialsDto = yield call(getVariantId, variantId)

    if (variantDetail) yield put(packagingSliceActions.setDetailVariant(variantDetail))

    const detail: PackagingDto = yield select(getPackageDetail)

    if (!detail) {
      const packageDetail: PackagingDto = yield call(getPackageId, packagingId)
      if (packageDetail) yield put(packagingSliceActions.setDetail(packageDetail))
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    if (hasLoader) {
      yield put(packagingSliceActions.setIsLoading(false))
    }
  }
}

function* confirmAddVariants({ payload }: AnyAction) {
  const { newVariant, packFamilyIdx = 0 } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    const { attachments, vialDetails } = newVariant
    const packageDetail: PackagingDto = yield select(getPackageDetail)
    const { id: packageFamilyId } = packageDetail.packageFamilies[packFamilyIdx]

    const refactoredAttachments = attachments.map(
      ({ id, contentTypeId, ...args }: any) => ({
        name: get(args, 'file.name', ''),
        formFieldName: id,
        attachmentContentTypeId: contentTypeId,
        ...args
      })
    )
    if (vialDetails.id) {
      delete vialDetails.id
    }
    const form_data = new FormData()
    const document = {
      author,
      packageFamilyId,
      vialDetails,
      attachments: refactoredAttachments
    }

    form_data.append('document', JSON.stringify(document))

    attachments.forEach((attach: any) => {
      form_data.append(attach.id, get(window, `tmpFiles.${attach.file.value}`, null))
    })
    const { id: packageId } = packageDetail
    yield call(updateRelatedVariant, form_data)

    yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* copyPackage({ payload }: Record<string, any>) {
  const { id: selectedPackId } = payload
  const packages: Record<string, any>[] = yield select(getPackagingItems)
  const selectedPackage = packages.find(({ id }) => selectedPackId === id)
  const packageFamilies = get(selectedPackage, 'packageFamilies', [])
  const isMulti = packageFamilies.length > 1
  yield put(
    packagingSliceActions.updateNewPackage({
      newPackage: {
        ...selectedPackage,
        attachments: isMulti && !!selectedPackage?.attachments ? [] : undefined,
        packageFamilies: packageFamilies.map((family: any) => ({
          ...family,
          packageVariants: []
        })),
        _type:
          get(selectedPackage, 'category', '').toLowerCase() === CATEGORY.VIALS
            ? 'vials'
            : 'noVial',
        _flowType: isMulti ? PACKAGE_TYPE.MULTI : PACKAGE_TYPE.MONO
      }
    })
  )
}

function* removePackage({ payload }: Record<string, any>) {
  yield put(packagingSliceActions.setIsLoading(true))
  const { value, successCallback } = payload
  const packageId = get(value, 'id', '')
  const author: string = yield select(selectUserEmail)
  try {
    yield call(deletePackaging, { id: packageId, author })
    if (successCallback) {
      yield successCallback()
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* updatePackage({ payload }: AnyAction): Record<string, any> {
  try {
    yield put(packagingSliceActions.setIsLoading(true))
    const updatedPackage = yield select(selectEditing)
    const author: string = yield select(selectUserEmail)
    const isMulti = get(updatedPackage, 'type', '').toLowerCase() === PACKAGE_TYPE.MULTI
    const { id: packageId } = updatedPackage

    const newPackage = prepareUpdatePackageData(updatedPackage, author, isMulti)
    const form_data = new FormData()
    form_data.append('image', newPackage.image)

    newPackage.document.packageFamilies.forEach((family: Record<string, any>) => {
      family.packageVariants.forEach((variant: any) => {
        const attachments = get(variant, 'attachments', [])
        if (attachments.length) {
          attachments.forEach((attach: any) => {
            const fileImage = get(window, `tmpFiles.${attach?.file?.value}`, null)
            if (fileImage) {
              form_data.append(
                attach.formFieldName,
                get(window, `tmpFiles.${attach.file.value}`, null)
              )
            }
          })
        }
      })
    })

    const variantsMulti = get(newPackage, 'document.packageVariants', [])
    if (isMulti && variantsMulti.length) {
      variantsMulti.forEach((variant: any) => {
        const attachments = get(variant, 'attachments', [])
        if (attachments.length) {
          attachments.forEach((attach: any) => {
            const fileImage = get(window, `tmpFiles.${attach?.file?.value}`, null)
            if (fileImage) {
              form_data.append(
                attach.formFieldName,
                get(window, `tmpFiles.${attach.file.value}`, null)
              )
            }
          })
        }
      })
    }
    form_data.append('document', JSON.stringify(newPackage.document))
    yield call(savePackaging, form_data, packageId)
    yield putResolve(
      packagingActions.getPackagingDetail({ id: packageId, hasLoader: false })
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* createPackage(): Record<string, any> {
  try {
    yield put(packagingSliceActions.setIsLoading(true))
    const data = yield select(selectNewPackaging)

    const isMulti = get(data, '_flowType', 'mono') === PACKAGE_TYPE.MULTI
    const userInfo = yield select(getUserData)
    const userMail = userInfo.mail || userInfo.userPrincipalName
    const newPackage = isMulti
      ? prepareMultiPackageData(data, userMail)
      : prepareMonoPackageData(data, userMail)
    const form_data = new FormData()
    form_data.append('image', newPackage.image)

    if (isMulti) {
      const attachmentList = get(newPackage, 'document.attachments', [])
      attachmentList.forEach((attach: any) => {
        form_data.append(
          attach.formFieldName,
          get(window, `tmpFiles.${attach.file.value}`, null)
        )
      })
    }

    newPackage.document.packageFamilies.forEach((family: Record<string, any>) => {
      family.packageVariants.forEach((variant: any) => {
        const attachments = get(variant, 'attachments', [])
        if (attachments.length) {
          attachments.forEach((attach: any) => {
            form_data.append(
              attach.formFieldName,
              get(window, `tmpFiles.${attach.file.value}`, null)
            )
          })
        }
      })
    })

    const variantsMulti = get(newPackage, 'document.packageVariants', [])
    if (isMulti && variantsMulti.length) {
      variantsMulti.forEach((variant: any) => {
        const attachments = get(variant, 'attachments', [])
        if (attachments.length) {
          attachments.forEach((attach: any) => {
            form_data.append(
              attach.formFieldName,
              get(window, `tmpFiles.${attach.file.value}`, null)
            )
          })
        }
      })
    }

    form_data.append('document', JSON.stringify(newPackage.document))

    const resp = yield call(createPackaging, form_data)
    const newPackageCode = get(resp, 'packagingCode', '')
    if (newPackageCode) {
      yield put(
        packagingSliceActions.setNewPackageCode({ packagingCode: newPackageCode })
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* fetchAttachmentsType(): Record<string, any> {
  try {
    const response = yield call(importAttachments)
    const attachmentContentTypes = get(response, 'attachmentContentTypes')
    const refactoredTypes = attachmentContentTypes.map(
      ({ name, ...args }: Record<string, any>) => ({
        ...args,
        name: name.toUpperCase(),
        value: name
      })
    )
    yield put(
      packagingSliceActions.updateDropdown({
        key: 'attachmentContentTypes',
        value: refactoredTypes
      })
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  }
}

function* deleteFurtherVersion({ payload }: Record<string, any>) {
  try {
    const { value } = payload
    const id: string = yield select(getPackageDetailId)
    yield call(deleteFurtherPackaging, { id: value.id, relatedId: id })
    yield call(fetchPackageDetail, {
      payload: { id },
      type: PACKAGING_GET_DETAIL
    })
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  }
}

function* addFurtherVersion({ payload }: Record<string, any>) {
  const { selectedPackages } = payload
  try {
    const selectedPackagesIds = selectedPackages.map(({ id }: any) => id)
    const id: string = yield select(getPackageDetailId)
    yield call(addFurtherPackaging, { packages: selectedPackagesIds }, id)

    yield put(packagingActions.getPackagingDetail({ id }))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  }
}

function* deleteRelated({ payload }: Record<string, any>) {
  try {
    const { value } = payload
    const id: string = yield select(getPackageDetailId)
    yield call(deleteRelatedPackaging, { id: value.id, relatedId: id })
    yield call(fetchPackageDetail, { payload: { id }, type: PACKAGING_GET_DETAIL })
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  }
}

function* addRelated({ payload }: Record<string, any>) {
  const { selectedPackages } = payload
  try {
    const selectedPackagesIds = selectedPackages.map(({ id }: any) => id)
    const id: string = yield select(getPackageDetailId)
    yield call(addRelatedPackaging, { packages: selectedPackagesIds }, id)

    yield put(packagingActions.getPackagingDetail({ id }))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  }
}

function* getPackagingWitRelations({ payload }: AnyAction) {
  const {
    withFurther = false,
    withRelated = false,
    hasLoader = true,
    successCallback = () => {},
    failCallback = () => {},
    pageData
  } = payload
  if (hasLoader) {
    yield put(packagingSliceActions.setIsLoading(true))
  }
  try {
    const filters: Record<string, any> = yield select(getPackagingFilters)
    const { page: selectedPage, pageSize: selectedPageSize } = pageData

    yield put(prodSliceActions.clearTemp())
    const resp: Record<string, any> = yield call(
      searchPackagingWithRelations,
      {
        ...filters,
        page: selectedPage,
        pageSize: selectedPageSize
      },
      { withFurther, withRelated }
    )
    const { results, page, pageSize, total } = resp

    yield put(packagingSliceActions.setPackaging({ data: results }))
    yield successCallback({ page, pageSize, total })
  } catch (err: any) {
    yield failCallback(err)
  } finally {
    if (hasLoader) {
      yield put(packagingSliceActions.setIsLoading(false))
    }
  }
}

function* getCountriesOfOriginDropdownSaga() {
  try {
    const resp: Record<string, any>[] = yield call(getCountriesOfOriginDropdown)
    const result = resp.map((country) => {
      return { name: country.code, value: country.code, description: country.name }
    })

    yield put(packagingSliceActions.setCountriesOfOriginDropdown(result))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
  }
}

function* searchSuppliersSaga({ payload }: AnyAction): any {
  try {
    if (!!payload) {
      const resp: any[] = yield call(searchSuppliers, { groupName: payload })
      const dropDownValues = get(resp, 'results', []).map((item: Record<string, any>) => {
        return { name: item.name, id: item.id, groupName: item.groupName }
      })
      yield put(packagingSliceActions.setTestedSupplierDropdown(dropDownValues))
    } else {
      yield call(fetchTestedSupplierDropdown)
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
  }
}

function* checkSupplier({ payload }: Record<string, any>) {
  const { supplierRef, nextStep, openWarning } = payload
  try {
    yield put(packagingSliceActions.setIsLoading(true))
    const resp: Record<string, any> = yield call(checkIfAlreadyRelated, { supplierRef })

    const { results } = resp
    if (results.length) {
      openWarning()
    } else {
      nextStep()
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* filterToTemp() {
  yield put(packagingSliceActions.filterToTemp())
}
function* tempToFilter() {
  const testedSupplierName: string = yield select(getTestedSupplierName)
  yield put(packagingSliceActions.tempToFilter({ name: testedSupplierName }))
}

function* copyAttachmentsFilters({ payload }: AnyAction) {
  yield put(packagingSliceActions.setAttachmentsFilters())
  yield put(appSliceActions.setModalKey())
}
function* closeAttachmentModal() {
  yield put(packagingSliceActions.resetNewAttachment())
  yield put(appSliceActions.setModalKey())
}

// TODO - FLOWER CLEAN UP - remove name control
function* saveNewAttachment({ payload }: AnyAction): any {
  const { name, id, isMulti } = payload
  if (!name) {
    yield put(packagingSliceActions.setIsLoading(true))
  } else {
    yield put(appSliceActions.setLoading(true))
  }
  try {
    yield put(appSliceActions.setModalKey())
    const form_data = new FormData()
    const newAttachment = yield select(selectNewAttachment)

    const variantId = yield select(selectVariantId)
    const fileKey = get(newAttachment, 'file.value', '')
    const author: string = yield select(selectUserEmail)
    const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)
    const document = {
      name: get(newAttachment, 'name', ''),
      description: get(newAttachment, 'description', ''),
      attachmentContentTypeId: get(newAttachment, 'contentTypeId', ''),
      SAPCodes: get(newAttachment, 'SAPCodes', []),
      author
    }
    form_data.append('document', JSON.stringify(document))
    if (attachmentFile) {
      form_data.append('file', attachmentFile)
    }
    yield isMulti
      ? call(insertAttachmentMulti, {
          form_data,
          packageId: id
        })
      : call(insertOneAttachment, {
          form_data,
          variantId: name === 'packagingVariant' ? id : variantId
        })

    if (fileKey && attachmentFile) {
      delete window.tmpFiles[fileKey]
    }
  } catch (e) {
    console.log(e)
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
    yield closeAttachmentModal()
    if (!name) {
      yield put(packagingSliceActions.setIsLoading(false))
      yield putResolve(packagingActions.getPackagingDetail({ id }))
    }
    if (name === 'packagingVariant') {
      yield put(appSliceActions.setLoading(false))
    }
  }
}

function* saveNewAttachmentInMono({ payload }: AnyAction) {
  const { attachData, variantId, packageId, isPackageDetail } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const form_data = new FormData()
    const fileKey = get(attachData, 'file.value', '')
    const author: string = yield select(selectUserEmail)
    const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)

    const document = {
      name: get(attachData, 'name', ''),
      description: get(attachData, 'description', ''),
      attachmentContentTypeId: get(attachData, 'contentTypeId', ''),
      SAPCodes: get(attachData, 'SAPCodes', []),
      author
    }
    form_data.append('document', JSON.stringify(document))
    if (attachmentFile) {
      form_data.append('file', attachmentFile)
    }

    yield call(insertOneAttachment, { form_data, variantId })

    if (fileKey && attachmentFile) {
      delete window.tmpFiles[fileKey]
    }
    if (isPackageDetail) {
      yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    } else {
      yield put(
        packagingActions.getVariantDetail({
          variantId: variantId,
          packagingId: packageId,
          hasLoader: false
        })
      )
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}
function* saveNewAttachmentInMulti({ payload }: AnyAction) {
  const { attachData, variantId, packageId, isPackageDetail } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const form_data = new FormData()
    const fileKey = get(attachData, 'file.value', '')
    const author: string = yield select(selectUserEmail)
    const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)

    const document = {
      name: get(attachData, 'name', ''),
      description: get(attachData, 'description', ''),
      attachmentContentTypeId: get(attachData, 'contentTypeId', ''),
      SAPCodes: get(attachData, 'SAPCodes', []),
      author
    }
    form_data.append('document', JSON.stringify(document))
    if (attachmentFile) {
      form_data.append('file', attachmentFile)
    }

    yield call(insertAttachmentMulti, {
      form_data,
      packageId
    })
    if (fileKey && attachmentFile) {
      delete window.tmpFiles[fileKey]
    }
    if (isPackageDetail) {
      yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    } else {
      yield put(
        packagingActions.getVariantDetail({
          variantId: variantId,
          packagingId: packageId,
          hasLoader: false
        })
      )
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}
function* deleteAttachment({ payload }: AnyAction) {
  const { value, id } = payload
  const author: string = yield select(selectUserEmail)
  const { id: packageId } = yield select(getPackageDetail)
  const itemId = get(value, 'id', '') || id
  try {
    yield call(deleteOneAttachment, { id: itemId, author })
  } catch (error) {
    console.log(error)
  } finally {
    yield call(fetchPackageDetail, {
      payload: { id: id ?? packageId, hasLoader: true },
      type: PACKAGING_GET_DETAIL
    })
  }
}

function* deleteAttachmentSaga({ payload }: AnyAction) {
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const { attachData, variantId, packageId } = payload
    const author: string = yield select(selectUserEmail)
    const id = get(attachData, 'id', '')
    yield call(deleteOneAttachment, { id, author })

    if (!variantId) {
      yield put(packagingActions.getPackagingDetail({ id: packageId }))
    } else {
      yield put(packagingActions.getVariantDetail({ variantId, packagingId: packageId }))
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* syncAttachment({
  payload
}: PayloadAction<Record<string, any>>): Record<string, any> {
  const { record, packageId } = payload
  try {
    const { attachmentContentTypeId, name, description, SAPCodes, id } = record
    const author = yield select(selectUserEmail)
    const requestBody = {
      author,
      attachmentContentTypeId,
      name,
      description,
      SAPCodes,
      status: 'PUBLISHED'
    }
    yield call(updateOneAttachment, { requestBody, id })
  } catch (e) {
    console.log(e)
  } finally {
    yield put(appSliceActions.setModalKey())
    if (packageId) {
      yield call(fetchPackageDetail, {
        payload: { id: packageId, hasLoader: true },
        type: PACKAGING_GET_DETAIL
      })
    }
  }
}

function* saveNewVersion({ payload }: PayloadAction<Record<string, any>>): any {
  const { record, packageId } = payload
  try {
    yield put(appSliceActions.setModalKey())
    yield put(appSliceActions.setLoading(true))
    const { attachmentContentTypeId, id } = record
    const versionData = yield select(selectNewVersion)
    const { name, description } = versionData
    const author = yield select(selectUserEmail)
    const SAPCodes = get(versionData, 'SAPCodes', [])
    const document = {
      name,
      description: description ? description : '',
      attachmentContentTypeId,
      SAPCodes
    }
    const form_data = new FormData()
    const fileKey = get(versionData, 'file.value', '')

    const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)
    form_data.append('document', JSON.stringify(document))
    if (attachmentFile) {
      form_data.append('file', attachmentFile)
    }

    yield call(createNewVersion, { form_data, author, id })
    if (fileKey && attachmentFile) {
      delete window.tmpFiles[fileKey]
    }
    yield put(packagingSliceActions.resetNewVersion())
  } catch (err) {
    console.log(err)
  } finally {
    yield put(appSliceActions.setLoading(false))
    if (packageId)
      yield call(fetchPackageDetail, {
        payload: { id: packageId, hasLoader: true },
        type: PACKAGING_GET_DETAIL
      })
  }
}

function* saveNewVersionSaga({ payload }: AnyAction) {
  const { attachData, packageId, variantId } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const author: string = yield select(selectUserEmail)
    const document = {
      name: get(attachData, 'name', ''),
      description: get(attachData, 'description', ''),
      attachmentContentTypeId: get(attachData, 'contentTypeId', ''),
      SAPCodes: get(attachData, 'SAPCodes', []),
      author
    }
    const form_data = new FormData()
    const fileKey = get(attachData, 'file.value', '')

    const attachmentFile = get(window, `tmpFiles.${fileKey}`, null)
    form_data.append('document', JSON.stringify(document))
    if (attachmentFile) {
      form_data.append('file', attachmentFile)
    }

    const attachId = get(attachData, 'id', '')

    yield call(createNewVersion, { form_data, author, id: attachId })

    if (fileKey && attachmentFile) {
      delete window.tmpFiles[fileKey]
    }
    if (!variantId) {
      yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    } else {
      yield put(
        packagingActions.getVariantDetail({
          variantId: variantId,
          packagingId: packageId,
          hasLoader: false
        })
      )
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}
function* updateAttachment({
  payload
}: PayloadAction<Record<string, any>>): Record<string, any> {
  const { record, id, attachmentContentTypeId, packageId } = payload
  const { name, description, SAPCodes } = record
  try {
    const author = yield select(selectUserEmail)
    const requestBody = {
      author,
      attachmentContentTypeId,
      name,
      description,
      SAPCodes
    }
    yield call(updateOneAttachment, { requestBody, id })
  } catch (err) {
    console.log(err)
  } finally {
    yield put(appSliceActions.setModalKey())
    yield put(packagingSliceActions.resetNewVersion())
    if (packageId)
      yield call(fetchPackageDetail, {
        payload: { id: packageId, hasLoader: false },
        type: PACKAGING_GET_DETAIL
      })
  }
}

function* updateAttachmentSaga({ payload }: AnyAction) {
  const { attachData, packageId, variantId } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  const id = get(attachData, 'id', '')
  try {
    const author: string = yield select(selectUserEmail)
    const requestBody = {
      author,
      attachmentContentTypeId: get(attachData, 'attachmentContentTypeId', ''),
      name: get(attachData, 'name', ''),
      description: get(attachData, 'description', ''),
      SAPCodes: get(attachData, 'SAPCodes', []),
      status: get(attachData, 'status', undefined)
    }

    yield call(updateOneAttachment, { requestBody, id })
    if (!variantId) {
      yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
    } else {
      yield put(
        packagingActions.getVariantDetail({
          variantId: variantId,
          packagingId: packageId,
          hasLoader: false
        })
      )
    }
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* deleteVariantFromPackageSaga({ payload }: Record<string, any>) {
  const { value } = payload
  try {
    yield put(packagingSliceActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const packageDetail: PackagingDto = yield select(getPackageDetail)
    const { id: packageId } = packageDetail
    const variantId = get(value, 'id', '')
    yield call(deleteRelatedVariant, { id: variantId, author })
    yield put(packagingActions.getPackagingDetail({ id: packageId, hasLoader: false }))
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}
function* deleteVariantSaga({ payload }: Record<string, any>) {
  const { value, successCallback = () => {} } = payload
  try {
    yield put(packagingSliceActions.setIsLoading(true))
    const author: string = yield select(selectUserEmail)
    const variantId = get(value, 'id', '')
    yield call(deleteRelatedVariant, { id: variantId, author })
    yield successCallback()
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* modifyVariant() {
  yield put(packagingSliceActions.editVariant())
}

function* checkPackageStatusSaga({ payload }: AnyAction): any {
  const { value, openPopupCallback } = payload
  const { id, status } = yield select(getPackageDetail)
  try {
    if (status === 'AVAILABLE' && value !== status) {
      const response = yield call(findProductsWithPKG, id)
      if (response.length) {
        const relatedProducts = response.length
          ? response.map(({ name }: Record<string, string>) => name)
          : undefined
        openPopupCallback({
          value,
          related: relatedProducts
        })
      } else {
        yield put(packagingSliceActions.updateTempDetailsByKey({ key: 'status', value }))
      }
    } else {
      yield put(packagingSliceActions.updateTempDetailsByKey({ key: 'status', value }))
    }
  } catch (err) {
    console.log(err)
  }
}
function* checkStatusOnDelete({ payload }: AnyAction | PayloadAction) {
  const { value } = payload
  const { id, status } = value
  yield put(appSliceActions.setLoading(true))
  try {
    if (status === 'AVAILABLE') {
      const response: Record<string, any> = yield call(findProductsWithPKG, id)
      const relatedProducts = response.map(({ name }: Record<string, string>) => name)
      yield put(packagingSliceActions.setRelatedProducts(relatedProducts))
    }
  } catch (err: any) {
    yield put(appSliceActions.setError(err))
  } finally {
    yield put(appSliceActions.setLoading(false))
  }
}

function* fetchTestedSupplierDropdown() {
  try {
    const result: Record<string, any> = yield call(getTestedSupplierValues)
    yield put(packagingSliceActions.setTestedSupplierDropdown(result))
  } catch (error: any) {
    yield put(packagingSliceActions.setError(error))
  }
}

function* updateVariant({ payload }: AnyAction | PayloadAction) {
  const { packagingId } = payload
  yield put(packagingSliceActions.setIsLoading(true))
  try {
    const variantEditing: DetailVariant = yield select(selectVariantEditing)
    const { vialDetails, id } = variantEditing
    const { code, dipstick, wiper, applicator, applicatorNumber, variantSupplierCode } =
      vialDetails
    const requestBody = {
      vialDetails: {
        code,
        dipstick,
        wiper,
        applicator,
        applicatorNumber,
        variantSupplierCode
      }
    }
    yield call(updateOnePackageVariant, { requestBody, id })

    yield put(
      packagingActions.getVariantDetail({
        variantId: id,
        packagingId,
        hasLoader: false
      })
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* exportMacroSaga() {
  yield put(packagingSliceActions.setIsLoading(true))
  const filters: Record<string, any> = yield select(getPackagingTempFilters)
  try {
    const response: Blob = yield call(exportMacro, filters)
    saveAs(
      new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'macroExport.xlsx'
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* exportMicroSaga({ payload }: AnyAction | PayloadAction) {
  yield put(packagingSliceActions.setIsLoading(true))
  const filters: Record<string, any> = yield select(getPackagingTempFilters)
  try {
    const response: Blob = yield call(exportMicro, filters)
    saveAs(
      new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'microExport.xlsx'
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(packagingSliceActions.setIsLoading(false))
  }
}

function* exportTargetPriceSaga() {
  yield put(appSliceActions.setLoader({ type: 'section-pacakaging-result', value: true }))
  const filters: Record<string, any> = yield select(getPackagingFilters)

  const { _all, status, ...otherFilters } = filters
  const refactoredFilters = refactorSearchPackageFilter(otherFilters)
  const newFilters: Record<string, any> = _all ? { _all } : refactoredFilters

  try {
    const response: Blob = yield call(exportTargetPrice, newFilters)
    saveAs(
      new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'Export Target price – for sourcing internal use only.xlsx'
    )
  } catch (err: any) {
    yield put(packagingSliceActions.setError(err))
  } finally {
    yield put(
      appSliceActions.setLoader({ type: 'section-pacakaging-result', value: false })
    )
  }
}

export default function* packagingsSaga() {
  yield takeLatest(PACKAGING_GET_DETAIL, fetchPackageDetail)
  yield takeLatest(PACKAGING_VARIANT_GET, fetchDetailVariant)
  yield takeLatest(PACKAGING_GET_PACKAGES, fetchPackages)
  yield takeLatest(PACKAGING_SEARCH_WITH_RELATIONS, getPackagingWitRelations)
  yield takeLatest(PACKAGING_FILTER_TO_TEMP, filterToTemp)
  yield takeLatest(PACKAGING_TEMP_TO_FILTER, tempToFilter)
  yield takeLatest(PACKAGING_COPY_PACKAGE, copyPackage)
  yield takeLatest(PACKAGING_REMOVE_PACKAGE, removePackage)
  yield takeLatest('packaging/modifyVariant', modifyVariant)
  yield takeLatest(PACKAGING_UPDATE_PACKAGE, updatePackage)
  yield takeLatest('packaging/saveNewAttachment', saveNewAttachment)
  yield takeLatest(PACKAGING_CREATE_VARIANT_IN_MONO, saveNewAttachmentInMono)
  yield takeLatest(PACKAGING_CREATE_VARIANT_IN_MULTI, saveNewAttachmentInMulti)
  yield takeLatest(PACKAGING_DELETE_ATTACHMENT, deleteAttachment)
  yield takeLatest(PACKAGING_REMOVE_ATTACHMENT, deleteAttachmentSaga)
  yield takeLatest('packaging/syncAttachment', syncAttachment)
  yield takeLatest('packaging/saveNewVersion', saveNewVersion)
  yield takeLatest(PACKAGING_SAVE_NEW_VARIANT_VERSION, saveNewVersionSaga)
  yield takeLatest('packaging/uploadAttachment', updateAttachment)
  yield takeLatest(PACKAGING_UPDATE_VARIANT_ATTACHMENT, updateAttachmentSaga)
  yield takeLatest(PACKAGING_CHECK_STATUS, checkPackageStatusSaga)
  yield takeLatest(PACKAGING_VARIANT_UPDATE, updateVariant)
  yield takeLatest(PACKAGING_CHECK_STATUS_ON_DELETE, checkStatusOnDelete)
  yield takeLatest(PACKAGING_CREATE, createPackage)
  yield takeLatest(PACKAGING_FURTHER_VERSION_DELETE, deleteFurtherVersion)
  yield takeLatest(PACKAGING_FETCH_ATTACHMENT_TYPES, fetchAttachmentsType)
  yield takeLatest(PACKAGING_FURTHER_VERSION_ADD, addFurtherVersion)
  yield takeLatest(PACKAGING_RELATED_DELETE, deleteRelated)
  yield takeLatest(PACKAGING_RELATED_ADD, addRelated)
  yield takeLatest(PACKAGING_CHECK_SUPPLIER_REF, checkSupplier)
  yield takeLatest(PACKAGING_COPY_ATTACHMENTS_FILTERS, copyAttachmentsFilters)
  yield takeLatest(PACKAGING_VARIANT_REMOVE_FROM_PACKAGE, deleteVariantFromPackageSaga)
  yield takeLatest(PACKAGING_VARIANT_REMOVE, deleteVariantSaga)
  yield takeLatest(PACKAGING_VARIANT_ADD, confirmAddVariants)
  yield takeLatest(PACKAGING_GET_TESTED_SUPPLIER_DROPDOWN, fetchTestedSupplierDropdown)
  yield takeLatest(PACKAGING_EXPORT_MACRO, exportMacroSaga)
  yield takeLatest(PACKAGING_EXPORT_MICRO, exportMicroSaga)
  yield takeLatest(PACKAGING_EXPORT_TARGET_PRICE, exportTargetPriceSaga)
  yield takeLatest(GET_COUNTRIES_OF_ORIGIN_DROPDOWN, getCountriesOfOriginDropdownSaga)
  yield takeLatest(SUPPLIERS_SEARCH, searchSuppliersSaga)
}
