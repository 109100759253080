import { findIndex } from 'lodash'

export const replaceObjectById = (
  arrayOfObjects: Record<string, any>[],
  id: string,
  newObj: Record<string, any>
) => {
  const index = findIndex(arrayOfObjects, { id: id })
  if (index !== -1) {
    const newArray = [
      ...arrayOfObjects.slice(0, index),
      newObj,
      ...arrayOfObjects.slice(index + 1)
    ]
    return newArray
  } else {
    console.log(`Object with id ${id} not found`)
  }
}
