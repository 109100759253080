import { createAction } from '@reduxjs/toolkit'
import { toolingActionTypes } from './constants'
import { ToolingDto } from './model'

export const toolingActions = {
  getTooling: createAction(toolingActionTypes.TOOLING_GET),
  exportToolings: createAction(toolingActionTypes.TOOLING_EXPORT),
  getToolingDetails: createAction<{ id: string }>(toolingActionTypes.TOOLING_GET_DETAILS),
  deleteTooling: createAction<{ id: string | '' }>(toolingActionTypes.TOOLING_DELETE),
  updateTooling: createAction<ToolingDto | {}>(toolingActionTypes.TOOLING_UPDATE),
  createTooling: createAction<ToolingDto | {}>(toolingActionTypes.TOOLING_CREATE)
}
