import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import StatusTooltip from '../../../components/StatusTooltip'
import {
  MAPPED_DROPDOWN,
  productStatusDropdown
} from '../../../features/product/constants'
import { selectPermissions } from '../../../features/users/selectors'
import { warningMessageGenerator } from '../../../utils'
import { IProductGenDataProps } from './types'

const ProdGeneralData: FC<IProductGenDataProps> = ({ data, isMulti }) => {
  const productStatus = get(data, 'status', '')

  const permissions = useSelector(selectPermissions)

  const statusValue =
    MAPPED_DROPDOWN['status'].find(({ value }) => value === productStatus)?.name || ''

  const statusPermissions = permissions.vaultProduct?.canSeeValue.productStatus || []
  const allowedStatus = statusPermissions
    .filter((value) => value['onEdit'])
    .map(({ value }) => value)
  const filteredStatus = productStatusDropdown.filter(({ value }) =>
    allowedStatus.includes(value)
  )

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell
          label={'Product Code'}
          value={get(data, 'productCode', '')}
          warningConfig={{
            message: warningMessageGenerator(
              get(data, 'warnings', []),
              get(data, 'warningsJSON', [])
            )
          }}
        />
        <InfoCell
          label={'Final Name'}
          value={get(data, 'name', '')}
          tooltipConfig={{
            enable: true,
            message:
              'Final name selected after the development of the formula - different than the working name'
          }}
        />
        <InfoCell
          tooltipConfig={{
            enable: !!filteredStatus.length,
            message: <StatusTooltip statusList={filteredStatus} />
          }}
          label={'Product Status'}
          value={statusValue}
        />

        {/* Multi Package only */}
        {isMulti && (
          <>
            <InfoCell label={'Business Unit'} value={get(data, 'businessUnit', '')} />
            <InfoCell
              label={'Business Segment'}
              value={get(data, 'businessSegment', '')}
            />
            <InfoCell
              label={'Filling Available In'}
              value={get(data, 'fillingAvailableIn', '')}
              tooltipConfig={{
                enable: true,
                message:
                  'This field refers to filling machinery availability, not to tooling that has to be verified by case'
              }}
            />
            <InfoCell label={'SAP Offer Code'} value={get(data, 'sapOfferCode', '')} />
          </>
        )}

        {/* Single Package Only */}
        {!isMulti && (
          <>
            <InfoCell
              label={'SPF DevEx'}
              value={
                get(data, 'productSides[0].formulaSchema.fsSpf', '') ||
                get(data, 'sides[0].fSchema.fsSpf', '')
              }
            />
            <InfoCell
              label={'PD Launch Year'}
              value={
                get(data, 'pdLaunchYear') ||
                get(data, 'productSides[0].pdLaunchYear') ||
                get(data, 'sides[0].fSchema.launchYear') ||
                get(data, 'productSides[0].formulaSchema.launchYear')
              }
            />
            <InfoCell
              label={'PD Collection'}
              value={
                get(data, 'pdCollection') || // summary singlepackage overwritten / edit singlepackage overwritten
                get(data, 'productSides[0].pdCollection') || // edit singlepackage overwritten from BE
                get(data, 'sides[0].fSchema.collection') || // summary singlepackage not overwritten
                get(data, 'productSides[0].formulaSchema.collection') // edit singlepackage never overwritten
              }
            />
          </>
        )}
      </BoxWrapper>
    </Form>
  )
}

export default ProdGeneralData
