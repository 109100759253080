import { isEmpty } from 'lodash'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Advice } from '../../../../components/Advice'
import Button from '../../../../components/Button'
import { Modal } from '../../../../components/Modal'
import ToolingResult from '../../../../containers/Tooling/ToolingResult'
import ToolingSearchForm from '../../../../containers/Tooling/ToolingSearchForm'
import { appActions } from '../../../../features/app'
import { toolingActions, toolingSliceActions } from '../../../../features/tooling'
import { ToolingTaxonomy } from '../../../../features/tooling/model'
import {
  getToolingFilters,
  selectToolingError,
  selectToolingLoader
} from '../../../../features/tooling/selectors'
import { ModalBodyContainer } from './styled'
import { IAddToolingModalProps } from './types'

const AddToolingModal: FC<IAddToolingModalProps> = ({ onAdd = () => {}, btnLabel }) => {
  const dispatch = useDispatch()
  const modalKey = 'product-add-tooling'
  const filters = useSelector(getToolingFilters)
  const isLoading = useSelector(selectToolingLoader)

  const [hasResult, setHasResult] = useState(false)
  const [selectedTooling, setSelectedPakages] = useState<any[]>([])

  const toolingError = useSelector(selectToolingError)

  const addToolingHandler = () => {
    dispatch(appActions.closeModal({ modalKey }))
    onAdd(selectedTooling)
  }

  const resetModalHandler = () => {
    setHasResult(false)
    dispatch(toolingSliceActions.clearFilters())
    setSelectedPakages([])
    if (!isEmpty(toolingError)) {
      dispatch(toolingSliceActions.clearError())
    }
  }

  const updateHandler = (key: string, value: any) => {
    const newValue: ToolingTaxonomy = { ...filters }
    newValue[key as keyof ToolingTaxonomy] = value
    dispatch(toolingSliceActions.updateFilter(newValue))
  }

  const searchHandler = () => {
    setHasResult(!hasResult)
    dispatch(toolingSliceActions.applyFilters())
    dispatch(toolingActions.getTooling())
  }

  const checkIfDisabled = () => {
    return false
  }

  const selectHandler = (rows: any[]) => {
    setSelectedPakages(rows)
  }

  const backHandler = () => {
    setHasResult(false)
    setSelectedPakages([])
    dispatch(toolingSliceActions.clearFilters())
  }

  const footer = !hasResult
    ? [
        <Button
          key="search"
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      ]
    : [
        <Button key="back" label="Back" onClick={backHandler} />,
        <Button
          key="add"
          label="Add"
          disabled={!selectedTooling.length}
          onClick={addToolingHandler}
        />
      ]

  return (
    <Modal
      btnName={btnLabel}
      modalKey={modalKey}
      title="Filter Package Tooling"
      footer={footer}
      btnClicked={resetModalHandler}
      centered
      size="large"
    >
      <ModalBodyContainer>
        {!isEmpty(toolingError) ? (
          <Advice
            status={toolingError?.status || '500'}
            subTitle={toolingError?.subTitle}
            title={toolingError?.title}
          >
            {toolingError?.message}
          </Advice>
        ) : (
          <>
            {!hasResult && (
              <ToolingSearchForm
                data={filters}
                onUpdateData={(key, value) => updateHandler(key, value)}
              />
            )}
            {hasResult && (
              <ToolingResult
                enableSelect
                enableExport={false}
                enableActions={false}
                selectedRows={selectedTooling}
                onSelect={selectHandler}
                isSingleSelect
                isLoading={isLoading}
              />
            )}
          </>
        )}
      </ModalBodyContainer>
    </Modal>
  )
}

export default AddToolingModal
