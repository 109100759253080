import { CloseOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd'
import { get, isEmpty } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductSearchForm } from '../..'
import { Button, SectionTitle } from '../../../components'
import { Divider } from '../../../components/Divider'
import { FlexSection } from '../../../components/FlexSection'
import { Loader } from '../../../components/Loader'
import { Modal } from '../../../components/Modal'
import { ISortData } from '../../../components/Table/types'
import { Tag } from '../../../components/Tag'
import { Title } from '../../../components/Title'
import { Tooltip } from '../../../components/Tooltip'
import { appActions } from '../../../features/app'
import { prodActions } from '../../../features/product'
import { DYNAMIC_TAXONOMIES } from '../../../features/product/constants'
import { selectProdIsLoading } from '../../../features/product/selectors'
import { productsActions, productsSliceActions } from '../../../features/products'
import { ProductDto } from '../../../features/products/model'
import {
  getFilters,
  selectLoader,
  selectProductPagination,
  selectProductsItem,
  selectTempFilter
} from '../../../features/products/selectors'
import { selectPermissions } from '../../../features/users/selectors'
import { unsetKeyByPath } from '../../../utils'
import {
  checkIfSameBussinessSegmentAndCategory,
  filterProdListByStatus
} from '../../../utils/productHelpers'
import { TagsContainer } from '../../TagsContainer'
import ProductSpiderChart from '../ProductSpiderChart'
import ProductScatterPlotModalContent from './ProductScatterPlotModalContent'
import ProductsTable from './ProductsTable'
import ScatterAndSpiderModalTitle from './ScatterAndSpiderModalTitle'
import {
  BackButton,
  ButtonWrapper,
  ChipsContainer,
  CloseButtonWrapper,
  ContentWrapper,
  DrawerCollector,
  FilterBtnWrapper,
  IconWrapper,
  ProductResultContainer,
  TagsWrapper,
  TagWrapper
} from './styled'

const ResultSection: FC = () => {
  const dispatch = useDispatch()
  const filterModalKey = 'products-filter'
  const permissions = useSelector(selectPermissions)
  const tempFilter = useSelector(selectTempFilter)
  const productList = useSelector(selectProductsItem)
  const pageData = useSelector(selectProductPagination)
  const isLoading = useSelector(selectLoader)
  const isFormLoading = useSelector(selectProdIsLoading)
  const filters = useSelector(getFilters)

  const [isBackHovered, setIsBackHovered] = useState(false)
  const [selectedProdList, setSelectedProdList] = useState<Record<string, any>[]>([])
  const [isSpiderChartActive, setIsSpiderChartActive] = useState(false)
  const [isCollectorOpen, setIsCollectorOpen] = useState(false)

  useEffect(() => {
    setSelectedProdList([])
  }, [filters])

  const allCharacteristics = selectedProdList
    ? Object.keys(get(selectedProdList, '[0].characteristics', {}))
    : []

  const removeTagHandler = (path: string) => {
    dispatch(productsSliceActions.cancelFilter({ path }))
    dispatch(productsActions.searchProducts())
    const isDynamicTaxonomies = DYNAMIC_TAXONOMIES.some((val) => path.includes(val))
    if (isDynamicTaxonomies) {
      const refactorPath = path.replace('filters.', '')
      const newFilters = unsetKeyByPath(filters, refactorPath)
      dispatch(prodActions.filterTaxonomies({ filters: newFilters }))
    }
  }

  const changePageHandler = (pageData: TablePaginationConfig, sortData?: ISortData) => {
    dispatch(
      productsActions.changePage({
        page: pageData.current || 1,
        field: sortData?.field || '',
        order: sortData?.order || 'ascend'
      })
    )
  }

  const searchHandler = () => {
    dispatch(appActions.closeModal({ modalKey: filterModalKey }))
    dispatch(productsSliceActions.applyFilters())
    dispatch(productsActions.searchProducts())
  }

  const getSpiderCharacteristicsHandler = () => {
    dispatch(
      productsActions.getSpiderChartCharacteristics({
        businessSegment: get(selectedProdList, '[0].businessSegment', ''),
        productCategory: get(selectedProdList, '[0].productCategory.[0]', []),
        loader: 'modal'
      })
    )
  }

  const checkIfDisabled = () =>
    Object.values(tempFilter).every(
      (value) => value === null || value === '' || value === undefined
    ) || isLoading

  const filteredList = filterProdListByStatus(productList, permissions)

  const hasExport =
    !!filteredList.length &&
    (permissions.vaultProduct?.canRead || false) &&
    !isSpiderChartActive

  // NOTE - CR100 - temporary hidden
  // const hasSpiderChart = !!filteredList.length && !isSpiderChartActive
  const hasSpiderChart = false
  // NOTE - CR100 - temporary hidden
  // const hasScatterPlot = !!filteredList.length
  const hasScatterPlot = false

  const isScatterPlotEnable =
    get(filters, 'businessSegment', []).length === 1 &&
    get(filters, 'productCategory', []).length === 1

  const isSpiderChartEnable =
    !!selectedProdList.length && checkIfSameBussinessSegmentAndCategory(selectedProdList)

  const getScatterCharacteristicsHandler = () => {
    dispatch(productsActions.getScatterCharacteristics())
  }

  // NOTE - this is a temporary workaround to hide white space in table header
  // when switching in select mode
  const scrollTableHandler = () => {
    const element = document.getElementsByClassName('ant-table-body')[0]
    if (element) {
      if (element.scrollLeft <= 0) {
        element.scrollLeft = 1
      }
    }
  }

  const activeSpiderChartHandler = () => {
    scrollTableHandler()
    setIsSpiderChartActive(true)
  }

  const deactiveSpiderChartHandler = () => {
    scrollTableHandler()
    setSelectedProdList([])
    setIsBackHovered(false)
    setIsCollectorOpen(false)
    setIsSpiderChartActive(false)
  }

  const updateSelectHandlder = (data: Record<string, any>[]) => {
    setSelectedProdList(data)
    setIsCollectorOpen(!!data.length)
  }

  const removeSelectHandler = (id: string) => {
    const selectedProd = selectedProdList.filter(({ id: prodId }) => prodId !== id)
    updateSelectHandlder(selectedProd)
  }

  return (
    <ProductResultContainer>
      <ContentWrapper isCollectorOpen={isCollectorOpen}>
        <SectionTitle value="Products" isBold />
        <Divider disableBorder />
        <FlexSection
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <FilterBtnWrapper>
            <Modal
              btnName="Filter"
              title="Filter Products"
              modalKey={filterModalKey}
              size="large"
              footer={[
                <ButtonWrapper>
                  <Button
                    label="Search"
                    onClick={searchHandler}
                    enableKeyPress
                    disabled={isFormLoading || checkIfDisabled()}
                  />
                </ButtonWrapper>
              ]}
              btnClicked={() => dispatch(productsSliceActions.filterToTemp())}
            >
              {isFormLoading ? <Loader /> : <ProductSearchForm />}
            </Modal>
          </FilterBtnWrapper>
          <TagsWrapper>
            {!isEmpty(filters) && (
              <TagsContainer
                tags={filters}
                flowName={'products'}
                reducer={'products'}
                prefix="products"
                basePath="filters"
                onRemoveTag={removeTagHandler}
                allowNoFilters
                inner={false}
              />
            )}
          </TagsWrapper>
          {hasSpiderChart && (
            <Tooltip
              title={
                <span>
                  Click here to select products <br />
                  (max 10 and no MULTIPACKAGE) <br />
                  and generate Spider Chart
                </span>
              }
            >
              <Button
                label="Spider Chart"
                onClick={activeSpiderChartHandler}
                variant="ghost"
              />
            </Tooltip>
          )}
          {isSpiderChartActive && (
            <BackButton isHover={isBackHovered} childDisabled={!isSpiderChartEnable}>
              <Modal
                modalKey={'spider-chart'}
                btnType="ghost"
                btnName="Generate Spider Chart"
                size={allCharacteristics.length ? 'xLarge' : 'small'}
                isSmall={!allCharacteristics.length}
                title={
                  allCharacteristics.length ? (
                    <ScatterAndSpiderModalTitle filters={filters} isSiderChart />
                  ) : (
                    ''
                  )
                }
                centered
                btnClicked={getSpiderCharacteristicsHandler}
                disabled={!isSpiderChartEnable}
                btnTooltipConfig={{
                  title:
                    'To generate a Spider Chart, select products within the same Business Segment and Product Category with available characteristics'
                }}
              >
                <ProductSpiderChart dataList={selectedProdList as ProductDto[]} />
              </Modal>
              <IconWrapper
                onClick={deactiveSpiderChartHandler}
                onMouseMove={() => setIsBackHovered(true)}
                onMouseLeave={() => setIsBackHovered(false)}
              >
                <CloseOutlined />
              </IconWrapper>
            </BackButton>
          )}
          {hasScatterPlot && !isSpiderChartActive && (
            <Modal
              modalKey={'scatter-plot'}
              btnType="ghost"
              btnName="Scatter Plot"
              size="xLarge"
              title={<ScatterAndSpiderModalTitle filters={filters} />}
              centered
              disabled={!isScatterPlotEnable}
              btnClicked={getScatterCharacteristicsHandler}
              btnTooltipConfig={{
                title: !isScatterPlotEnable
                  ? 'To generate Scatter Plot select a Bussiness Segment and Product Category with assigned characteristics'
                  : 'Generate Scatter Plot of all products with assigned characteristics'
              }}
            >
              <ProductScatterPlotModalContent />
            </Modal>
          )}
          {hasExport && (
            <Button
              label="Export Results"
              variant="ghost"
              disabled={isLoading}
              onClick={() => dispatch(productsActions.exportExcel())}
            />
          )}
        </FlexSection>
        <ProductsTable
          dataList={filteredList}
          enablePagination
          isLoading={isLoading}
          pageData={pageData}
          onChangePage={changePageHandler}
          onSelect={updateSelectHandlder}
          selectedRow={selectedProdList}
          exclusionConfig={{ type: ['MULTI'], hasCharacteristics: [false] }}
          enableRowSelect={isSpiderChartActive}
          labelOnSelect={isSpiderChartActive ? 'Select For SpiderChart' : ''}
          disableHeaderSelect
          selectLimit={10}
          paginationPosition={'TOP_RIGHT'}
        />
      </ContentWrapper>
      <DrawerCollector isOpen={isCollectorOpen}>
        <CloseButtonWrapper>
          <Button
            iconName="CloseOutlined"
            variant="text"
            color="black"
            onClick={() => setIsCollectorOpen(false)}
          />
        </CloseButtonWrapper>
        <Title variantTitle="h4" title="Selected:" />
        <Divider />
        <ChipsContainer>
          {selectedProdList.map(({ name, id }) => (
            <TagWrapper key={id}>
              <Tag
                value={name}
                path=""
                closable
                onRemove={() => removeSelectHandler(id)}
              />
            </TagWrapper>
          ))}
        </ChipsContainer>
      </DrawerCollector>
    </ProductResultContainer>
  )
}

export default ResultSection
