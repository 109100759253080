import { CSSProperties, memo, ReactNode } from 'react'
import { StyledFlexSection } from './styled'

type Props = {
  children?: ReactNode
  flexDirection: CSSProperties['flexDirection']
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']
  gap?: number
}

export const FlexSection = memo(
  ({
    children,
    flexDirection,
    justifyContent = 'start',
    alignItems = 'start',
    gap
  }: Props) => {
    return (
      <StyledFlexSection
        flexDirection={flexDirection}
        alignItems={alignItems}
        justifyContent={justifyContent}
        gap={gap}
      >
        {children}
      </StyledFlexSection>
    )
  }
)

FlexSection.displayName = 'FlexSection'
