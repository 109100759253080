import axios, { AxiosInstance } from 'axios'
import { get } from 'lodash'

import { getAccessToken } from '@/configs/auth-msal'

const baseURL = process.env.REACT_APP_BASE_URL

if (!process.env.REACT_APP_BASE_URL) {
  throw new Error('Missing environment variable!')
}

export const axiosApi = (() => {
  let axiosInstance: AxiosInstance
  return {
    call: async (url: string, method?: string, options?: any) => {
      try {
        if (!axiosInstance) {
          const accessToken = await getAccessToken()
          axiosInstance = axios.create({
            baseURL,
            headers: { Authorization: `Bearer ${accessToken}` }
          })
        }
        const resp = await axiosInstance({
          url,
          method,
          ...options
        })
        checkResponse(resp?.status)
        return resp
      } catch (err) {
        if (get(err, 'response.status') === 401) {
          window.location.reload()
        } else {
          throw err
        }
      }
    },
    initInstance: async () => {
      try {
        const accessToken = await getAccessToken()
        axiosInstance = axios.create({
          baseURL,
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      } catch (err) {
        throw err
      }
    }
  }
})()

const checkResponse = (status: number) => {
  if (status !== 200 && status !== 201) throw Error('Something went wrong')
  return true
}

const createApiClient = {
  get: async <T>(resource: string, params?: Record<string, any>) => {
    try {
      const { withHeaders = false, ...options } = params || {}
      const response = await axiosApi.call(`/${resource}`, 'GET', options)
      if (response) {
        if (withHeaders) {
          return {
            data: response.data,
            headers: response.headers
          }
        }
        return response.data as T
      }
    } catch (err) {
      throw err
    }
  },
  post: async <T>(
    resource: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => {
    try {
      const response = await axiosApi.call(`/${resource}`, 'POST', {
        data,
        ...options
      })
      if (response) {
        return response.data as T
      }
    } catch (err) {
      throw err
    }
  },
  patch: async <T>(
    resource: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => {
    try {
      const response = await axiosApi.call(`/${resource}`, 'PATCH', {
        data,
        ...options
      })
      if (response) {
        return response.data as T
      }
    } catch (err) {
      throw err
    }
  },
  delete: async <T>(
    resource: string,
    data?: Record<string, any>,
    options?: Record<string, any>
  ) => {
    try {
      const response = await axiosApi.call(`/${resource}`, 'DELETE', {
        data,
        ...options
      })
      if (response) {
        return response.data as T
      }
    } catch (err) {
      throw err
    }
  }
}

export const apiClient = createApiClient
