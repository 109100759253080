import { Form } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { InfoCell } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { PACKAGING_STATUS } from '../../../features/packaging/constants'
import { CATEGORY } from '../../../features/packaging/model'
import { getAllStatus } from '../../../features/packaging/selectors'
import { statusesByRole } from '../../../features/packaging/utils'
import { selectUserRole } from '../../../features/users/selectors'
import { AppRoutes } from '../../../pages/constants'
import { checkFieldVisibilityByRoles } from '../../../utils'
import { canUserReadPackagingData } from '../../../utils/permissionsHelpers'
import { IPackagingSideDataProps } from './types'

const PackagingSideData: FC<IPackagingSideDataProps> = ({ data }) => {
  const userRoles = useSelector(selectUserRole)
  const packageStatus = get(data, 'sideCollector.status')

  const statusesDropdown = useSelector(getAllStatus)
    .filter(({ name }: { name: PACKAGING_STATUS }) =>
      userRoles.some((role: any) => statusesByRole[name].includes(role))
    )
    .map(({ name }: { name: PACKAGING_STATUS }) => name)

  const canReadVariant =
    canUserReadPackagingData({ userRoles }) && statusesDropdown.includes(packageStatus)

  const category: string = get(data, 'category', '')
  const isVials = category.toLowerCase() === CATEGORY.VIALS
  const packageId = get(data, 'sideCollectorId', '')
  const variantId = get(data, 'variantId', '')

  const isVisible = checkFieldVisibilityByRoles({
    key: 'variantSupplierCode',
    isOnRead: true,
    roles: userRoles
  })

  return (
    <Form layout="vertical">
      <BoxWrapper enablePadding>
        <InfoCell label="Side Category" value={category} />
        <InfoCell label="Side Subcategory" value={get(data, 'subcategory', '')} />
        {isVials && (
          <InfoCell
            label={'Side Variant Code'}
            value={get(data, 'variantCode', '')}
            dataType={canReadVariant ? 'link' : 'string'}
            linkConfig={{
              path: `${AppRoutes.WIKIPACK_PACKAGING}/${packageId}/variant/${variantId}`
            }}
          />
        )}
        {isVials && isVisible && (
          <InfoCell
            label="Side Variant Supplier Code"
            value={get(data, 'variantSupplierCode', '')}
          />
        )}
        {isVials && (
          <>
            {/* NOTE - temporary hidden */}
            {/* <InfoCell label="Mono" value={get(data, 'mono', '')} /> */}
            <InfoCell label="Bottle" value={get(data, 'bottle', '')} />
            <InfoCell label="Stem" value={get(data, 'dipstick', '')} />
            <InfoCell label="Wiper" value={get(data, 'wiper', '')} />
            <InfoCell label="Applicator" value={get(data, 'applicator', '')} />
          </>
        )}
        <InfoCell label="Format" value={get(data, 'category', '')} />
        <InfoCell label="AT Packaging Need" value={get(data, 'airtight', '')} />
        {!isVials && (
          <InfoCell
            label="Main material in contact with Formula"
            value={get(data, 'material', '')}
          />
        )}
      </BoxWrapper>
    </Form>
  )
}

export default PackagingSideData
