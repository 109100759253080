import { TABLE_DATA_TYPE } from '../../components/Table/constants'
import { ITableConfig } from '../../components/Table/types'
import { columnsGeneratorHelper } from '../tableHelpers'
import { TAdminColumnGeneratorFunc } from './types'

export const adminColumnGenerator: TAdminColumnGeneratorFunc = ({
  exchangeRateUSDRenderer
}) => {
  const columnsConfig: ITableConfig = {
    currency: {
      label: 'Currency',
      dataType: TABLE_DATA_TYPE.TEXT,
      dataPath: 'currency'
    },
    USDExchangeRate: {
      label: 'USD Exchange Rate',
      dataType: TABLE_DATA_TYPE.NODE,
      dataPath: 'USDExchangeRate',
      renderNode: exchangeRateUSDRenderer
    }
  }

  const columnList = columnsGeneratorHelper(columnsConfig)

  return columnList
}
