import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Table } from '../../../../components'
import { TOP_RIGHT } from '../../../../components/Table/constants'
import { selectAppLoaderBySectionKey } from '../../../../features/app/selectors'
import { packagingSliceActions } from '../../../../features/packaging'
import { productsSliceActions } from '../../../../features/products'
import {
  getProductsDetail,
  selectOtherProducts
} from '../../../../features/products/selectors'
import { AppRoutes } from '../../../../pages/constants'
import { columnsGeneratorHelper } from '../../../../utils'
import {
  refactorOtherTestedColumnTableConfig,
  refactorProductForOtherTestedPackSection,
  refactorProductOtherTestedPackagingRows
} from '../../../../utils/productHelpers'
import {
  TLaunchYearRowRendererFunc,
  TPackageCategoryRowRendererFunc,
  TPackagingCodeRowRendererFunc,
  TProductCodeRowRendererFunc,
  TProductCollectionRowRendererFunc
} from '../../../../utils/productHelpers/types'
import {
  TGoToPackageSearchResultHandlerFunc,
  TGoToProductDetailHandlerFunc,
  TGoToProductSearchResultHandlerFunc
} from './types'

const ProductOtherTestedPack: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productDetail = useSelector(getProductsDetail)
  const otherProducts = useSelector(selectOtherProducts)

  const productSides = get(productDetail, 'productSides', []) || []

  const isOtherTestedLoading = useSelector(
    selectAppLoaderBySectionKey('section-other-tested')
  )

  const otherTestedPackaging = productSides.reduce(
    (acc: any, curr: any) => [
      ...acc,
      ...get(curr, 'formulaSchema.otherTestedPackaging', [])
    ],
    []
  )

  const refactoredOtherTestedPackaging = refactorProductOtherTestedPackagingRows({
    dataList: otherTestedPackaging
  })

  const refactorOtherProducts = otherProducts
    ? refactorProductForOtherTestedPackSection({
        dataList: otherProducts
      })
    : []

  const allList = otherProducts
    ? [...refactoredOtherTestedPackaging, ...refactorOtherProducts]
    : [...refactoredOtherTestedPackaging]

  const goToPackageSearchResultHandler: TGoToPackageSearchResultHandlerFunc = ({
    categoryList,
    isMulti
  }) => {
    const filters = {
      packageFamilies: categoryList.map((category) => ({ category })),
      type: isMulti ? 'MULTI' : 'MONO',
      _all: ''
    }
    dispatch(packagingSliceActions.setFilters(filters))
    navigate(AppRoutes.WIKIPACK_PACKAGING_RESULT)
  }

  const goToProductSearchResultHandler: TGoToProductSearchResultHandlerFunc = ({
    collectionList,
    launchYearList
  }) => {
    const launchYearValue = launchYearList && [...new Set(launchYearList)]
    const collectionValue = collectionList && [...new Set(collectionList)]
    const filters = {
      collection: collectionValue,
      launchYear: launchYearValue,
      status: 'READY_TO_GO'
    }
    dispatch(productsSliceActions.setFilters(filters))
    navigate(AppRoutes.VAULT_PRODUCTS_RESULTS)
  }

  const goToProductDetailResultHandler: TGoToProductDetailHandlerFunc = (id) => {
    navigate(`/vault/products/${id}`)
  }

  const goToPackageDetailHandler: TGoToProductDetailHandlerFunc = (id) => {
    navigate(`/wikipack/packaging/${id}`)
  }

  const packagingCodeRowRenderer: TPackagingCodeRowRendererFunc = (
    packagingCode,
    data
  ) => {
    const isOtherTestedPack = !data.hasOwnProperty('productSides')
    const packageId = get(
      data,
      'productSides[0].packageVariant.packageFamily.sideCollectorId'
    )
    const packagingCodeVal = packagingCode || '-'
    return isOtherTestedPack ? (
      <div>{packagingCodeVal}</div>
    ) : (
      <Button
        label={packagingCodeVal}
        variant="link"
        onClick={() => goToPackageDetailHandler(packageId)}
      />
    )
  }

  const launchYearRowRenderer: TLaunchYearRowRendererFunc = (_, data) => {
    const isOtherTestedPack = !data.hasOwnProperty('productSides')
    const launchYear = get(data, 'pdLaunchYear')
    const pdCollection = get(data, 'pdCollection')
    const launchYearVal = launchYear && launchYear.length && launchYear.join(', ')
    return isOtherTestedPack || !launchYearVal ? (
      <div>{launchYearVal || '-'}</div>
    ) : (
      <Button
        label={launchYearVal}
        variant="link"
        onClick={() =>
          goToProductSearchResultHandler({
            collectionList: pdCollection,
            launchYearList: launchYear
          })
        }
      />
    )
  }

  const pacakageCategoryRowRenderer: TPackageCategoryRowRendererFunc = (
    category,
    data
  ) => {
    const isOtherTestedPack = !data.hasOwnProperty('productSides')
    const isMulti = data.type === 'MULTI'
    const categoryLabel = isOtherTestedPack
      ? category
      : isMulti
      ? 'MULTIPACKAGE'
      : category[0]
    return isOtherTestedPack ? (
      <div>{category || '-'}</div>
    ) : (
      <Button
        label={categoryLabel as string}
        variant="link"
        onClick={() =>
          goToPackageSearchResultHandler({ categoryList: category as string[], isMulti })
        }
      />
    )
  }

  const productCollectionRowRenderer: TProductCollectionRowRendererFunc = (_, data) => {
    const isOtherTestedPack = !data.hasOwnProperty('productSides')
    const launchYear = get(data, 'pdLaunchYear')
    const pdCollection = get(data, 'pdCollection')
    const pdCollectionVal = pdCollection && pdCollection.join(', ')
    return isOtherTestedPack || !pdCollectionVal ? (
      <div>{pdCollectionVal || '-'}</div>
    ) : (
      <Button
        label={pdCollectionVal}
        variant="link"
        onClick={() =>
          goToProductSearchResultHandler({
            collectionList: pdCollection,
            launchYearList: launchYear
          })
        }
      />
    )
  }
  const productCodeRowRenderer: TProductCodeRowRendererFunc = (productCode, data) => {
    const isOtherTestedPack = !data.hasOwnProperty('productSides')

    const productCodeValue = productCode || '-'
    return isOtherTestedPack ? (
      <div>{productCodeValue}</div>
    ) : (
      <Button
        label={productCodeValue}
        variant="link"
        onClick={() => goToProductDetailResultHandler(data.id)}
      />
    )
  }

  const columnsConfigs = refactorOtherTestedColumnTableConfig({
    pacakageCategoryRowRenderer,
    productCollectionRowRenderer,
    productCodeRowRenderer,
    packagingCodeRowRenderer,
    launchYearRowRenderer
  })
  const columns = columnsGeneratorHelper(columnsConfigs)

  return (
    <Table
      enablePagination={false}
      paginationPosition={TOP_RIGHT}
      rowKey="id"
      columns={columns}
      items={allList}
      isLoading={isOtherTestedLoading}
    />
  )
}

export default ProductOtherTestedPack
