import { FC, useMemo } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import { Text } from '../Text'
import { Tooltip } from '../Tooltip'
import { WarningInfo } from '../WarningInfo'

import { StyledInfoBox, StyledLabelWrapper } from './styled'
import { IBoxInfoProps } from './types'

export const BoxInfo: FC<IBoxInfoProps> = ({
  value,
  label,
  children,
  enableTooltip,
  title = '',
  position,
  isDate = false,
  isEditable = false,
  onClick = () => {},
  warnMessage = ''
}) => {
  const textValue = useMemo(() => {
    if (children && !Array.isArray(children)) return null
    if (typeof value === 'boolean') return value ? 'Yes' : 'No'
    if (Array.isArray(value)) return value.join(', ') || '-'
    if (typeof value === 'string' && isDate)
      return moment(value).utc().format('DD/MM/YYYY')
    if (!children || (Array.isArray(children) && !children.length)) return value || '-' // nel caso in cui arrivi un array vuoto
  }, [value, children, isDate])
  return (
    <StyledInfoBox isEditable={isEditable} onClick={onClick}>
      {enableTooltip ? (
        <StyledLabelWrapper>
          <Text className="label" text={label} variant="h5" />
          <Tooltip title={title} placement={position}>
            <InfoCircleOutlined />
          </Tooltip>
        </StyledLabelWrapper>
      ) : (
        <Text className="label" text={label} variant="h5" />
      )}
      <div className="value" data-cy={`field-${label}`}>
        {textValue ? <Text text={textValue} variant="h6" /> : null}
        {!!warnMessage && <WarningInfo message={warnMessage} />}
        {children}
      </div>
    </StyledInfoBox>
  )
}
