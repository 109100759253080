import { Form, Tabs } from 'antd'
import { get } from 'lodash'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import { appSliceActions } from '../../../features/app'
import { packagingSliceActions } from '../../../features/packaging'
import { PackagingCreationType } from '../../../features/packaging/model'
import { selectUserRole } from '../../../features/users/selectors'
import { canUserEditPackaging } from '../../../utils/permissionsHelpers'
import Alternative from '../Alternative'
import Variants from '../Variants'
import SideInfoForm from './SideInfoForm'
import { ISideInfoProps, TargetKey } from './types'

const SideInformation: FC<ISideInfoProps> = ({
  data,
  updateData = () => {},
  isEditing = false,
  isCreating = false,
  variantTableConfig,
  isSideDeletable = false
}) => {
  const dispatch = useDispatch()
  const packageFamilies = get(data, 'packageFamilies', [])
  const userRoles = useSelector(selectUserRole)

  const updateSideHandler = (key: string, value: Record<string, any>, idx: number) => {
    const newPackageFamilies = [...packageFamilies]
    newPackageFamilies[idx] = { ...newPackageFamilies[idx], [key]: value }
    if (key === 'clean') {
      newPackageFamilies[idx]['cleanTypes'] = []
    }
    if (key === 'category') {
      newPackageFamilies[idx] = {
        id: newPackageFamilies[idx].id,
        category: value
      }
    }
    if (key === 'subcategory') {
      newPackageFamilies[idx] = {
        id: newPackageFamilies[idx].id,
        category: newPackageFamilies[idx].category,
        subcategory: value
      }
    }
    updateData('packageFamilies', newPackageFamilies)
  }

  // NOTE Active shoud be internal state
  const onChangeTab = (tabIndex: string) => {
    dispatch(packagingSliceActions.setActiveSide(+tabIndex))
  }

  const addSideHandler = () => {
    const newPackageFamilies = [...packageFamilies]
    newPackageFamilies.push({})
    updateData('packageFamilies', newPackageFamilies)
  }

  const removeTabHandler = (targetIndex: number) => {
    const newPackageFamilies = [...packageFamilies]
    newPackageFamilies.splice(targetIndex, 1)
    updateData('packageFamilies', newPackageFamilies)
  }

  const onEditHandler = (targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'remove') {
      dispatch(
        appSliceActions.openPopUp({
          title: '',
          key: 'package-side-remove',
          message: 'Are you sure you want to delete this Side?',
          footer: [
            <Button
              key="cancel"
              label="Cancel"
              onClick={() => dispatch(appSliceActions.closePopUp())}
              variant="ghost"
            />,
            <Button
              key="ok"
              label="Ok"
              onClick={() => {
                removeTabHandler(+targetKey)
                dispatch(appSliceActions.closePopUp())
              }}
            />
          ]
        })
      )
    }
  }

  return (
    <Tabs
      onChange={onChangeTab}
      hideAdd
      type="editable-card"
      tabBarExtraContent={
        isEditing && <Button label="Add" onClick={addSideHandler} variant="text" />
      }
      onEdit={onEditHandler}
    >
      {packageFamilies.map((packageFamily: any, index: number) => (
        <Tabs.TabPane
          tab={`Side ${index + 1}`}
          key={`${index}`}
          closable={isSideDeletable && packageFamilies.length > 2}
        >
          <Form layout="vertical">
            <SideInfoForm
              updateData={(key: string, value: Record<string, any>) =>
                updateSideHandler(key, value, index)
              }
              data={packageFamily}
              isEditing={isEditing && canUserEditPackaging({ userRoles })}
              isCreating={isEditing}
            />
            {get(packageFamily, 'category', '').toLowerCase() ===
              PackagingCreationType.VIAL &&
              get(packageFamily, 'subcategory', '') && (
                <Variants
                  data={packageFamily}
                  isEditing={isEditing}
                  isCreating={isCreating}
                  onAddConfirm={(value: Record<string, any>) => {
                    const dataList = get(packageFamily, 'packageVariants', [])
                    updateSideHandler('packageVariants', dataList.concat(value), index)
                  }}
                  deleteVariant={
                    isCreating
                      ? packagingSliceActions.deleteSideVariant
                      : packagingSliceActions.deleteSideVariantEditing
                  }
                  tableConfig={variantTableConfig}
                  isMulti
                  category={get(packageFamily, 'category', '')}
                  subcategory={get(packageFamily, 'subcategory', '')}
                  modalKey={`package-variant-side-${index}`}
                  hasInfoModal={isCreating}
                  hasRedirect={!isCreating && !isEditing}
                  hasDelete
                />
              )}
            <Alternative
              data={packageFamily}
              isEditing={isEditing && canUserEditPackaging({ userRoles })}
              onAdd={
                isCreating
                  ? packagingSliceActions.addAlternativeNewPackageSide
                  : packagingSliceActions.addAlternativeInTempSide
              }
              onDelete={
                isCreating
                  ? packagingSliceActions.deleteAlternativeNewPackageSide
                  : packagingSliceActions.deleteAlternativeInTempSide
              }
              modalKey={`package-relation-add-alternative-${index}`}
            />
          </Form>
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default SideInformation
