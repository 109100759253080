import { Collapse } from 'antd'
import styled from 'styled-components'

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header-text > * {
    margin: 0;
  }
  .ant-collapse-header-text {
    flex: 1;
  }
  > div > div.ant-collapse-header {
    align-items: center !important;
  }
`
