import { Form } from 'antd'
import { get } from 'lodash'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import InfoCell from '../../../components/InfoCell'

import StatusTooltip from '../../../components/StatusTooltip'
import {
  BUSINESS_UNITS,
  businessSegmentDropdown,
  fillingAvailableInDropdown,
  productStatusDropdown,
  productStatusDropdownValues
} from '../../../features/product/constants'
import { getDropDownsByKey } from '../../../features/product/selectors'
import { selectPermissions, selectUserEmail } from '../../../features/users/selectors'
import { IAddDataGeneralFormProps } from './types'

const AdditionalDataForm: FC<IAddDataGeneralFormProps> = ({
  data,
  onUpdate,
  isCreating = false,
  isMulti
}) => {
  const permissions = useSelector(selectPermissions)
  const author = useSelector(selectUserEmail)
  const [isMarketTouched, setIsMarketTouched] = useState(false)
  const [isImageTouched, setIsImageTouched] = useState(false)

  const pdLaunchYearDropdown = useSelector(getDropDownsByKey('pdLaunchYear'))
  const pdCollectionDropdown = useSelector(getDropDownsByKey('pdCollection'))

  const updateHandler = (key: string, value: any) => {
    if (key === 'marketingProfile' && !isMarketTouched) {
      setIsMarketTouched(true)
    }
    if (key === 'image' && !isImageTouched) {
      setIsImageTouched(true)
    }
    onUpdate(key, value)
  }

  const updateMarketProfile = (docData: any) => {
    const newValue = { ...docData, version: 1, createdAt: Date.now(), createdBy: author }
    updateHandler('marketingProfile', newValue)
  }

  const statusPermissions = permissions.vaultProduct?.canSeeValue.productStatus || []
  const allowedStatus = statusPermissions
    .filter((value) => value['onEdit'])
    .map(({ value }) => value)
  const filteredStatus = productStatusDropdown.filter(({ value }) =>
    allowedStatus.includes(value)
  )

  return (
    <Form layout={'vertical'}>
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="product-image"
          value={get(data, 'image.value', '')}
          dataType="image"
          inputType="upload"
          isEditing
          inputConfig={{
            fileKey: `product-image-${Date.now()}`,
            topMessage: 'Drop or browse the product image',
            fileType: 'imageAndPdf',
            bottomMessage: 'Supported file: PNG, JPG and PDF',
            onChange: (value: any) => {
              if (!get(data, 'name', '')) {
                updateHandler('name', value.name)
              }
              updateHandler('image', value)
            },
            isUrl: !isCreating && !isImageTouched
          }}
        />
      </BoxWrapper>
      <Divider />
      <BoxWrapper enablePadding>
        <InfoCell
          label="Final Name"
          value={get(data, 'name', '')}
          isEditing
          inputConfig={{
            required: true,
            error: !get(data, 'name', ''),
            placeholder: 'Final Name',
            onChange: (value: any) => updateHandler('name', value)
          }}
        />

        <InfoCell
          label="SAP Offer Code"
          value={get(data, 'sapOfferCode', '')}
          isEditing
          inputConfig={{
            placeholder: 'SAP Offer Code',
            onChange: (value: any) => updateHandler('sapOfferCode', value)
          }}
        />

        {!isMulti && (
          <>
            <InfoCell
              label="PD Launch Year"
              value={
                get(data, 'pdLaunchYear', '') ||
                get(data, 'productSides[0].pdLaunchYear', '')
              }
              isEditing
              inputType="select"
              inputConfig={{
                items: pdLaunchYearDropdown,
                onChange: ({ value }: any) => updateHandler('pdLaunchYear', value)
              }}
            />

            <InfoCell
              label="PD Collection"
              value={
                get(data, 'pdCollection', '') ||
                get(data, 'productSides[0].pdCollection', '')
              }
              inputType="select"
              isEditing
              inputConfig={{
                items: pdCollectionDropdown,
                onChange: ({ value }: any) => updateHandler('pdCollection', value)
              }}
            />
          </>
        )}
      </BoxWrapper>
      <Divider />
      <BoxWrapper enablePadding>
        <InfoCell
          label="Product Status"
          value={get(
            data,
            'status',
            isCreating ? productStatusDropdownValues.WIP_INFORMATION : ''
          )}
          inputType="select"
          isEditing
          inputConfig={{
            items: filteredStatus,
            required: true,
            onChange: ({ value }: Record<string, any>) => updateHandler('status', value),
            disabled: isCreating
          }}
          tooltipConfig={{
            enable: !!filteredStatus.length,
            message: <StatusTooltip statusList={filteredStatus} />
          }}
        />
        <InfoCell
          label="Filling Available In"
          value={get(data, 'fillingAvailableIn', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'fillingAvailableIn', ''),
            items: fillingAvailableInDropdown,
            onChange: ({ value }: any) => updateHandler('fillingAvailableIn', value)
          }}
          tooltipConfig={{
            enable: true,
            message:
              'This field refers to filling machinery availability, not to tooling that has to be verified by case'
          }}
        />
        <InfoCell
          label="Business Segment"
          value={get(data, 'businessSegment', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'businessSegment', ''),
            items: businessSegmentDropdown,
            onChange: ({ value }: any) => updateHandler('businessSegment', value)
          }}
        />
        <InfoCell
          label="Business Unit"
          value={get(data, 'businessUnit', '')}
          isEditing
          inputType="select"
          inputConfig={{
            required: true,
            error: !get(data, 'businessUnit', ''),
            items: BUSINESS_UNITS,
            onChange: ({ value }: any) => updateHandler('businessUnit', value)
          }}
        />
      </BoxWrapper>
      <Divider />
      <BoxWrapper columnCount={3} enablePadding>
        <InfoCell
          label="market-profile"
          value={get(data, 'marketingProfile.value', '')}
          dataType="image"
          inputType="upload"
          isEditing
          inputConfig={{
            fileKey: `marketingProfile-${Date.now()}`,
            topMessage: 'Drop or browse the Marketing Profile file',
            fileType: 'any',
            bottomMessage: '',
            onChange: updateMarketProfile,
            isUrl: !isCreating && !isMarketTouched,
            enableCharRule: true
          }}
        />
      </BoxWrapper>
    </Form>
  )
}

export default AdditionalDataForm
