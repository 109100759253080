import { Form } from 'antd'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SectionTitle } from '../../../components'
import { BoxWrapper } from '../../../components/BoxWrapper'
import { Divider } from '../../../components/Divider'
import { Input } from '../../../components/Form/Input'
import { RadioList } from '../../../components/Form/RadioList'
import { Switch } from '../../../components/Form/Switch'
import { Text } from '../../../components/Text'
import { cartSliceActions } from '../../../features/cart/reducer'
import {
  selectAdditionalNote,
  selectSetRequired,
  selectType
} from '../../../features/cart/selectors'
import { selectOrderListDropdown } from '../../../features/orderList/selectors'

export const Step2Cart = () => {
  const dropdown = useSelector(selectOrderListDropdown)
  const customerOptions = get(dropdown, 'addressedTo', [])
  const dispatch = useDispatch()
  const clientType = useSelector(selectType)
  const setRequired = useSelector(selectSetRequired)
  const additionalNote = useSelector(selectAdditionalNote)

  const handleChange = useCallback(
    (value: string | boolean, reduxKey: string | undefined) => {
      dispatch(cartSliceActions.setCustomer({ reduxKey, value }))
    },
    [dispatch]
  )
  const changeClientType = useCallback(
    (value: string) => {
      dispatch(
        cartSliceActions.setCustomer({
          reduxKey: 'type',
          value
        })
      )
    },
    [dispatch]
  )
  return (
    <>
      <SectionTitle value="Additional information" isBold />
      <Text text="Select who the order is addressed to" variant="h5" />
      <RadioList
        items={customerOptions}
        value={clientType}
        defaultValue={clientType}
        onChange={changeClientType}
      />
      <Divider disableBorder={true} />
      <Form layout="vertical">
        <BoxWrapper>
          <Input
            disabled={!clientType}
            reduxKey="additionalNote"
            label={`NOTE ${clientType === 'OTHER' ? '' : 'ABOUT'} ${clientType}`}
            placeholder="Max 30 characters"
            onChange={handleChange}
            value={additionalNote}
            maxLength={30}
          />
        </BoxWrapper>
      </Form>
      <Divider disableBorder={true} />
      <Text text="Set Required?" variant="h4" />
      <Switch onChange={handleChange} value={setRequired} reduxKey="setRequired" />
    </>
  )
}
