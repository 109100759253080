import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { PageContent } from '../../containers'
import FSchemaResultSection from '../../containers/FormulaSchema/FSchemaResultSection'
import { fSchemaActions, fSchemaSliceActions } from '../../features/formulaSchema'
import { selectFSchemaError } from '../../features/formulaSchema/selectors'
import { GenericLayout } from '../../layouts'

const FSchemaResult: FC = () => {
  const dispatch = useDispatch()
  const fSchemaError = useSelector(selectFSchemaError)

  useEffect(() => {
    dispatch(fSchemaActions.getFormulaSchema())
    return () => {
      dispatch(fSchemaSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GenericLayout error={fSchemaError}>
      <PageContent headerHeight={headerHeight} footerHeight={footerHeight}>
        <FSchemaResultSection hasTitle hasFilterBtn hasAction />
      </PageContent>
    </GenericLayout>
  )
}

export default FSchemaResult
