import styled from 'styled-components'

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    height: 16px;
    font-size: 14px;
  }
`

export const Label = styled.span`
  font-size: medium;
  font-weight: bold;
`

export const Value = styled.span`
  font-size: medium;
  word-break: break-all;
`

export const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-picker {
    width: 100%;
  }
`
