import { isEmpty } from 'lodash'
import find from 'lodash/find'
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { sortItemsRanges } from '../../utils/sortItemsRanges'
import type { RootState } from '../store'
import { selectUserRole } from '../users/selectors'
import { ATTACHMENT_CONTENT_TYPE } from './constants'
import { Attachments, CATEGORY, PACKAGE_TYPE } from './model'
import { valueVial } from './selectOptions'
import {
  attachmentByRole,
  filterAttachmentsByRole,
  getRoleAttachments,
  makePackageVariants,
  makeVariantItem
} from './utils'

const selectPackaging = (state: RootState) => state.packaging

export const selectPackagingError = createSelector(
  selectPackaging,
  (state) => state.error
)

export const getPackagingItems = createSelector(
  selectPackaging,
  (packaging) => packaging.items
)

export const getIsLoading = createSelector(
  selectPackaging,
  (packaging) => packaging.isLoading
)

export const getPackagingItemsVials = createSelector(getPackagingItems, (items) => {
  return makePackageVariants(items)
})

export const getPackagingItemsByIds = createSelector(
  selectPackaging,
  (packaging) => packaging.itemsByIds
)

export const getPackagingFilters = createSelector(
  selectPackaging,
  (packaging) => packaging.filters
)
export const selectEditing = createSelector(selectPackaging, (packaging) => {
  return get(packaging, 'tempDetail', {})
})

export const getPackagingTempFilters = createSelector(
  selectPackaging,
  (packaging) => packaging.tempFilters
)

export const getPackagingFamilies = createSelector(getPackagingFilters, (filter) =>
  get(filter, 'packageFamilies')
)
export const getPackagingTempFamilies = createSelector(
  getPackagingTempFilters,
  (filter) => get(filter, 'packageFamilies')
)

export const getPackagingFiltersIsVials = createSelector(
  getPackagingFilters,
  (filters) => get(filters, '_type', '') === valueVial
)
export const getPackagingTempFiltersIsVials = createSelector(
  getPackagingTempFilters,
  (filters) => get(filters, '_type', '') === valueVial
)

export const getPackagingById = createSelector(
  selectPackaging,
  (packaging) => packaging.itemsByIds
)

export const getPackageDetail = createSelector(
  selectPackaging,
  (packaging) => packaging.detail
)

export const getNewPackage = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'newPackage')
)

export const getNewPackagingIsVials = createSelector(
  getNewPackage,
  (newPackage) => get(newPackage, '_type', '') === valueVial
)

export const selectIfFlowTypeSelected = createSelector(
  getNewPackage,
  (newPackage) => !!get(newPackage, '_flowType', '')
)

export const getNewPackagingAttachments = createSelector(getNewPackage, (newPackage) =>
  get(newPackage, 'filteredAttachment', [])
)

export const getPackageDetailVariant = createSelector(
  selectPackaging,
  (packaging) => packaging.detailVariant
)

export const getPackageDetailVial = createSelector(getPackageDetail, (detail) =>
  makeVariantItem(detail)
)

export const getPackageVariantById = (id?: string) =>
  createSelector(getPackageDetailVial, (variants) => {
    return find(variants, { id })
  })

export const getPackageDetailId = createSelector(getPackageDetail, (detail) =>
  get(detail, 'id', '')
)

export const getPackageDetailName = createSelector(getPackageDetail, (detail) =>
  get(detail, 'packagingCode', '')
)
export const getPackageVariantDetailName = createSelector(
  getPackageDetailVariant,
  (detail) => get(detail, 'variantCode', '')
)

export const getPackageById = (id: string) =>
  createSelector(getPackagingItemsByIds, (itemsByIds) => itemsByIds[id])

export const getPackagingDropdown = createSelector(
  selectPackaging,
  (packaging) => packaging.dropDown
)
export const getDropdownFilteredStatus = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'filteredStatus', [])
)

export const getPackagingDropdownBottle = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'bottle', [])
)

export const getPackagingDropdownDipstick = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'dipstick', [])
)

export const getPackagingDropdownWiper = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'wiper', [])
)

export const getPackagingDropdownApplicator = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'applicator', [])
)

export const getPackagingDropdownApplicatorNumber = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'applicatorNumber', [])
)

export const getVialDetailDropdownByKey = (key: string) =>
  createSelector(getPackagingDropdown, (dropDown) => dropDown[key] || [])

export const getPackagingDropdownCategory = createSelector(
  getPackagingDropdown,
  (dropDown) => {
    const category = get(dropDown, 'categoryAndSubcategory', {})
    return Object.keys(category).map((cat) => ({ label: cat, value: cat }))
  }
)

export const getDropdownTestedSupplier = createSelector(
  getPackagingDropdown,
  (dropDown) => {
    const dropDownList = get(dropDown, 'testedSupplier', [])
    return dropDownList.map(({ name, id, groupName }: any) => ({
      name,
      value: `${id}`,
      groupName: groupName
    }))
  }
)

export const selectCountryOfOriginDropDownItems = createSelector(
  selectPackaging,
  (packaging) => packaging.countriesDropdown
)

export const getTestedSupplierName = createSelector(
  getPackagingDropdown,
  getPackagingTempFilters,
  (dropDown, tempFilters) => {
    const dropDownList = get(dropDown, 'testedSupplier', [])
    const testedSupplierId = get(tempFilters, 'testedSupplier.id', '')
    const testedSupplier = dropDownList.find(({ id }: any) => testedSupplierId === id)
    return get(testedSupplier, 'name') || get(tempFilters, 'testedSupplier.name', '') // temp solution to package supplier text search
  }
)

export const getDropdownAttachmentContentTypes = createSelector(
  getPackagingDropdown,
  (dropDown) => {
    const dropDownList = get(dropDown, 'attachmentContentTypes', [])
    return dropDownList
      .map(({ name, id }: any) => ({ name, value: id }))
      .filter(
        ({ name }: { name: ATTACHMENT_CONTENT_TYPE }) =>
          attachmentByRole?.[name.toLowerCase() as ATTACHMENT_CONTENT_TYPE]
      )
  }
)

export const getDropdownFilteredContentTypes = createSelector(
  getDropdownAttachmentContentTypes,
  selectUserRole,
  (contentTypes, roles) => {
    const roleAttachments = getRoleAttachments(roles)
    const filteredContentTypes = contentTypes.filter((contType: string) =>
      filterAttachmentsByRole(roleAttachments, contType)
    )
    return filteredContentTypes
  }
)

export const getAttachmentContentTypesList = createSelector(
  getPackagingDropdown,
  (dropDown) => {
    return get(dropDown, 'attachmentContentTypes', [])
  }
)

export const getAllStatus = createSelector(getPackagingDropdown, (dropDown) => {
  return get(dropDown, 'allStatus', [])
})

export const getDropdownAttachmentStatus = createSelector(
  getPackagingDropdown,
  (dropDown) => {
    return get(dropDown, 'attachmentStatus', [])
  }
)

export const getPackagingDropdownCategoryVials = createSelector(
  getPackagingDropdownCategory,
  (category) => category.filter((e) => e.value === valueVial)
)

export const getPackagingDropdownCategoryNotVials = createSelector(
  getPackagingDropdownCategory,
  (category) => category?.filter((e) => e.value !== valueVial)
)

export const getPackagingDropdownCategoryFiltered = createSelector(
  getPackagingFiltersIsVials,
  getPackagingDropdownCategoryNotVials,
  getPackagingDropdownCategoryVials,
  (isVials, categoryNotVials, categoryVials) =>
    isVials ? categoryVials : categoryNotVials
)

export const getNewPackagingDropdownCategoryFiltered = createSelector(
  getNewPackagingIsVials,
  getPackagingDropdownCategoryNotVials,
  getPackagingDropdownCategoryVials,
  (isVials, categoryNotVials, categoryVials) =>
    isVials ? categoryVials : categoryNotVials
)

export const getPackagingDropdownSubcategory = createSelector(
  getPackagingDropdown,
  (dropDown) => get(dropDown, 'categoryAndSubcategory')
)

export const selectVariantEditing = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'variantEdit')
)
export const getFilterCategorySelected = createSelector(
  getPackagingFamilies,
  (packagingFamilies) => {
    return get(packagingFamilies, 'category')
  }
)

export const getFilterCategoryTempSelected = createSelector(
  getPackagingFamilies,
  (packagingFamilies) => {
    return get(packagingFamilies, 'category')
  }
)
export const getFilterSubCategorySelected = createSelector(
  getPackagingFamilies,
  (packagingFamilies) => {
    return get(packagingFamilies, 'subcategory')
  }
)

export const selectClosure = createSelector(
  getPackagingDropdown,
  getFilterCategorySelected,
  (dropDown, categorySelected: string) => {
    return get(dropDown, 'closure').filter((opt: { name: string; value: string }) =>
      categorySelected.toLocaleLowerCase() !== CATEGORY.PENCILS
        ? opt.value !== 'others'
        : true
    )
  }
)

export const getFilterTempCategorySelected = createSelector(
  getPackagingTempFamilies,
  (packagingFamilies) => {
    return get(packagingFamilies, 'category')
  }
)

export const getPackagingDropdownFilterSubcat = createSelector(
  getFilterTempCategorySelected,
  getPackagingDropdownSubcategory,
  (categorySelected, categoryAndSubcategory) => {
    const subcategory: string[] = get(categoryAndSubcategory, categorySelected) || []
    return subcategory.map((subcat: string) => ({ label: subcat, value: subcat }))
  }
)
export const getNewPackagingSubCategory = createSelector(
  getNewPackage,
  getPackagingDropdownSubcategory,
  (newPackage, categoryAndSubcategory) => {
    const category = get(newPackage, 'category', '')
    const subcategory: string[] = get(categoryAndSubcategory, category) || []
    return subcategory.map((subcat: string) => ({ label: subcat, value: subcat }))
  }
)
export const getEditPackagingDropdownFilterSubcat = createSelector(
  selectEditing,
  getPackagingDropdownSubcategory,
  (detail, categoryAndSubcategory) => {
    const category = get(detail, 'packageFamilies.0.category')
    const subcategory: string[] = get(categoryAndSubcategory, category) || []
    return subcategory.map((subcat: string) => ({ label: subcat, value: subcat }))
  }
)
export const selectPackageFamilies = createSelector(getPackageDetail, (detail) =>
  get(detail, 'packageFamilies')
)

export const selectAttachmentsPacakge = createSelector(
  selectPackageFamilies,
  selectUserRole,
  (pkFamilies, role) => {
    if (!pkFamilies) return []
    const lists: any = []
    pkFamilies.forEach((pkf: any) => {
      return pkf.packageVariants.forEach((pv: any) => {
        lists.push(...pv.attachments)
      })
    })
    const finalAttachments = lists.filter((attachment: Attachments) => {
      const contentType: ATTACHMENT_CONTENT_TYPE = get(
        attachment,
        'attachmentContentType.name',
        ''
      ).toLowerCase()
      const allowedRoles = attachmentByRole?.[contentType]

      return (
        allowedRoles && role?.some((currentRole) => allowedRoles.includes(currentRole))
      )
    })
    return finalAttachments.map((attachment: Attachments) => {
      return {
        ...attachment,
        children: attachment.otherVersions.length ? attachment.otherVersions : undefined
      }
    })
  }
)

export const selectPackagePagination = createSelector(
  selectPackaging,
  (state) => state.pagination
)

export const selectNewPackaging = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'newPackage')
)

export const selectNewPackagingCode = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'newPackagingCode')
)

export const getCurrentOpen = createSelector(getPackageDetail, (detail) => {
  return get(detail, 'packageFamilies.0.packageVariants.0.currentExpanded')
})

export const selectOpenedAttachment = createSelector(
  getCurrentOpen,
  selectAttachmentsPacakge,
  (open, attachments) => {
    const olderVersions =
      attachments.find((att: any) => att.id === open).olderVersions || []
    return olderVersions
  }
)
export const selectAttachmentsFilter = createSelector(
  selectPackaging,
  (packaging) => packaging.attachmentsFilters
)
export const selectNewAttachment = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'newAttachment')
)
export const selectVariantId = createSelector(
  selectPackageFamilies,
  (packageFamilies) => packageFamilies[0].packageVariants[0].id
)
export const selectAttachmentsWithFilters = createSelector(
  getPackageDetail,
  selectAttachmentsPacakge,
  selectAttachmentsFilter,
  (detail, attachments, filters) => {
    const packageType = get(detail, 'type')
    const currenteAttachments =
      packageType?.toLowerCase() === PACKAGE_TYPE.MULTI
        ? get(detail, 'attachments', [])
        : attachments
    if (filters) {
      const { name = '', description = '', SAPCodes = '' } = filters
      return currenteAttachments?.filter((attachment: Attachments) => {
        const filterByName = name.toLocaleLowerCase()
          ? attachment.name.toLocaleLowerCase().includes(name) ||
            attachment.dataType.toLocaleLowerCase().includes(name)
          : true
        const filterByDesc = description.toLocaleLowerCase()
          ? attachment.description.toLocaleLowerCase().includes(description)
          : true
        const sapCodesInLower = attachment.SAPCodes.map((sapCode) =>
          sapCode.toLocaleLowerCase()
        )
        const filterBySap = SAPCodes
          ? sapCodesInLower.includes(SAPCodes.toLocaleLowerCase())
          : true
        return filterByName && filterByDesc && filterBySap
      })
    }
  }
)

export const selectNewVersion = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'newVersion', {})
)

export const selectNewVersionRequirements = createSelector(
  selectNewVersion,
  (newVersion) =>
    !isEmpty(newVersion) &&
    get(newVersion, 'name', false) &&
    get(newVersion, 'file', false)
)

export const selectTempAttachment = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'tempAttachment', {})
)

export const selectAllTaxonomies = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'allTaxonomies', [])
)

export const selectAllPossibleCategory = createSelector(
  selectAllTaxonomies,
  (allTaxonomies) => {
    const allCategory = allTaxonomies.map(({ value, id }) => ({ value, id }))
    return allCategory
  }
)

export const selectAllCategoryDropDown = createSelector(
  selectAllPossibleCategory,
  (allCategory) => {
    const allCategoryDropDown = allCategory.map(({ value }: { value: string }) => ({
      label: value,
      value: value
    }))
    return allCategoryDropDown
  }
)

export const selectFilteredCategoryDropDown = createSelector(
  selectAllCategoryDropDown,
  (allDropdown) => {
    return allDropdown.filter(({ value }) => value.toLowerCase() !== 'vials')
  }
)

export const selectCategoryInTemp = createSelector(
  selectEditing,
  selectAllTaxonomies,
  (tempDetail, allTaxonomies) => {
    const selectedCategory: string = get(tempDetail, 'packageFamilies.0.category', '')
    const filteredCategory = allTaxonomies.find(
      ({ value }) => value.toLocaleLowerCase() === selectedCategory.toLocaleLowerCase()
    )
    return filteredCategory
  }
)

export const selectFilteredSubCategories = createSelector(
  selectCategoryInTemp,
  (filteredCategory) => {
    return get(filteredCategory, 'children.subcategory', [])
  }
)

export const selectFilteredSubCatDropDown = createSelector(
  selectFilteredSubCategories,
  (filteredSubcategory) => {
    if (!filteredSubcategory.length) return []
    return filteredSubcategory.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectSubCatInTemp = createSelector(
  selectEditing,
  selectFilteredSubCategories,
  (tempDetail, filteredSubcategory) => {
    const selectedSubCategory: string = get(
      tempDetail,
      'packageFamilies.0.subcategory',
      ''
    )
    const filteredSubCategory = filteredSubcategory.find(
      ({ value }: { value: string }) =>
        value.toLocaleLowerCase() === selectedSubCategory.toLocaleLowerCase()
    )
    return filteredSubCategory
  }
)

export const selectFilteredTecnologyDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.technology', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredSizeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.size', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredMaterialDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.material', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredCapacityRangeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.capacityRange', [])

    const data = list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
    return sortItemsRanges(data)
  }
)

export const selectFilteredShapeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.shape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredPanShapeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.panShape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredPanSizeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.panSize', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredTipShapeDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.tipShape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredAppAndAccDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.applicatorAndAccessories', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredClosureDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.closure', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredFillingDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.filling', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectFilteredLevelDropDown = createSelector(
  selectSubCatInTemp,
  selectCategoryInTemp,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.level', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectCategoryInTempFilter = createSelector(
  getPackagingTempFilters,
  selectAllTaxonomies,
  (tempFilters, allTaxonomies) => {
    const selectedCategory: string =
      get(tempFilters, 'packageFamilies.category', '') || ''
    const filteredCategory = allTaxonomies.find(
      ({ value }) => value.toLocaleLowerCase() === selectedCategory.toLocaleLowerCase()
    )
    return filteredCategory
  }
)

export const selectTempFiltersSubCategories = createSelector(
  selectCategoryInTempFilter,
  (filteredCategory) => {
    return get(filteredCategory, 'children.subcategory', [])
  }
)

export const selectTempFiltersSubCatDropDown = createSelector(
  selectTempFiltersSubCategories,
  (filteredSubcategory) => {
    if (!filteredSubcategory.length) return []
    return filteredSubcategory.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectSubCatInTempFilter = createSelector(
  getPackagingTempFilters,
  selectTempFiltersSubCategories,
  (tempFilters, filteredSubcategory) => {
    const selectedSubCategory: string = get(
      tempFilters,
      'packageFamilies.subcategory',
      ''
    )
    const filteredSubCategory = filteredSubcategory.find(
      ({ value }: { value: string }) =>
        value.toLocaleLowerCase() === selectedSubCategory.toLocaleLowerCase()
    )
    return filteredSubCategory
  }
)

export const selectTempFiltersTecnologyDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.technology', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersSizeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.size', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersMaterialDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.material', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersCapacityRangeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.capacityRange', [])
    const data = list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
    return sortItemsRanges(data)
  }
)

export const selectTempFiltersShapeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.shape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersPanShapeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.panShape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersPanSizeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.panSize', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersTipShapeDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.tipShape', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersAppAndAccDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.applicatorAndAccessories', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersClosureDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.closure', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersFillingDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.filling', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const selectTempFiltersLevelDropDown = createSelector(
  selectSubCatInTempFilter,
  selectCategoryInTempFilter,
  (filteredSubcategory, filteredCategory) => {
    const filtered = filteredSubcategory ? filteredSubcategory : filteredCategory
    const list = get(filtered, 'children.level', [])
    return list.map(({ value }: { value: string }) => ({
      value,
      label: value
    }))
  }
)

export const filteredVariantsAttachments = createSelector(
  selectPackaging,
  selectAttachmentsFilter,
  selectUserRole,
  (packaging, filters, role) => {
    const attachments = get(packaging, 'detailVariant.attachments', [])
      .map((attachment: Attachments) => ({
        ...attachment,
        children: !!attachment?.otherVersions?.length
          ? attachment.otherVersions
          : undefined
      }))
      .filter((attachment: Attachments) => {
        const contentType: ATTACHMENT_CONTENT_TYPE = get(
          attachment,
          'attachmentContentType.name',
          ''
        ).toLowerCase()
        const allowedRoles = attachmentByRole?.[contentType]

        return (
          allowedRoles && role?.some((currentRole) => allowedRoles.includes(currentRole))
        )
      })
    if (filters) {
      const { name, description, SAPCodes } = filters
      return attachments.filter((attachment: Attachments) => {
        const filterByName = name
          ? attachment.name.includes(name) || attachment.dataType.includes(name)
          : true
        const filterByDesc = description
          ? attachment.description.includes(description)
          : true
        const filterBySap = SAPCodes ? attachment.SAPCodes.includes(SAPCodes) : true
        return filterByName && filterByDesc && filterBySap
      })
    }
  }
)

export const getIsAlreadyVial = createSelector(getNewPackage, (newPackage) => {
  const category = get(newPackage, 'category', '')
  return category === 'Vials'
})

export const selectRelatedOnDelete = createSelector(
  selectPackaging,
  (packaging) => packaging.relatedProducts || []
)

export const selectTempAttachmentsFilters = createSelector(selectPackaging, (packaging) =>
  get(packaging, 'tempAttachmentsFilters')
)

export const selectFilteredAttachmentsByType = (type: 'multi' | 'mono') =>
  createSelector(getPackageDetail, selectUserRole, (detail, role) => {
    const attachments =
      type === 'multi'
        ? get(detail, 'attachments', [])
        : get(detail, 'packageFamilies[0].packageVariants', [])
            .map(({ attachments }: any) => attachments)
            .flat()
    return attachments.filter((attachment: Attachments) => {
      const contentType: ATTACHMENT_CONTENT_TYPE = get(
        attachment,
        'attachmentContentType.name',
        ''
      ).toLowerCase()
      const allowedRoles = attachmentByRole?.[contentType]

      return (
        allowedRoles && role?.some((currentRole) => allowedRoles.includes(currentRole))
      )
    })
  })

export const getIndipendentDropdownbyKey = (key: string) =>
  createSelector(getPackagingDropdown, (indipendentDropdowns) =>
    get(indipendentDropdowns, key, [])
  )
