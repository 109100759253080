import { memo, ReactNode } from 'react'
import { Text } from '../../Text'
import { StyledFormBox } from './styled'

type Layout = 'horizontal' | 'vertical' | 'inline'

export type Props = {
  children?: ReactNode
  name?: string
  layout: Layout
  title?: string
}

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  }
}

export const FormWrap = memo(({ name = '', children, layout, title = '' }: Props) => {
  return (
    <StyledFormBox layout={layout} {...formItemLayout} name={name}>
      {title && <Text variant="h4" text={title} />}
      {children}
    </StyledFormBox>
  )
})

FormWrap.displayName = 'FormWrap'
