import { Dropdown } from 'antd'
import styled from 'styled-components'

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px;
  color: black;
`

export const CheckboxWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .ant-checkbox-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  label.ant-checkbox-wrapper {
    width: 32%;
    margin-top: 5px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`
