import { FC } from 'react'
import { useSelector } from 'react-redux'
import Table from '../../components/Table'
import { ITActionColumnConfig } from '../../components/Table/types'
import TooltipBtn from '../../components/TooltipBtn'
import { Formula } from '../../features/formulaSchema/model'
import { selectPermissions } from '../../features/users/selectors'
import { AppRoutes } from '../../pages/constants'
import { columnsGeneratorHelper, rowsGeneratorHelper_new } from '../../utils'
import EditFormulaModalModal from './EditFormulaModal'
import { IFormulaSchemaTableProps } from './types'

const FormulaSchemaTable: FC<IFormulaSchemaTableProps> = ({
  tableConfig,
  dataList = [],
  enablePagination = false,
  onChangePage = () => {},
  pageData = { page: 1, pageSize: 10, total: 10 },
  enableSelect,
  selectedRows,
  isSingleSelect,
  onSelect,
  isLoading,
  isEditing = false,
  hasRedirect = false,
  applyRegVisibleRules = false,
  hasDelBtn = false,
  hasEditBtn = false,
  onDelete = () => {}
}) => {
  const permissions = useSelector(selectPermissions)
  const { page, pageSize, total } = pageData

  const actionConfig: ITActionColumnConfig = {
    columnTitle: 'Actions',
    actionRenderer: ({ data }) => {
      return (
        <>
          {hasEditBtn && <EditFormulaModalModal data={data as Formula} />}
          {hasDelBtn && (
            <TooltipBtn
              message="Do you want to delete this Formula Schema?"
              btnVariant="text"
              hasIcon
              iconName="DeleteOutlined"
              onConfirm={() => onDelete(data)}
            />
          )}
        </>
      )
    },
    detail: hasRedirect
      ? {
          btnLabel: 'Detail',
          btnVariant: 'text',
          destinationPath: AppRoutes.VAULT_FORMULA_SCHEMA_DETAILS
        }
      : undefined
  }

  const hasAction = isEditing || hasRedirect

  const columnGenerator = () => {
    let columnList = columnsGeneratorHelper(tableConfig)
    if (applyRegVisibleRules) {
      columnList = columnList.filter(({ key }) => {
        const canSee = permissions.vaultFormulaSchema?.canSeeField[key]?.onRead
        return canSee !== undefined ? canSee : true
      })
    }
    return columnList
  }

  const rowsGenerator = () => rowsGeneratorHelper_new({ dataList, tableConfig })

  const columns = columnGenerator()
  const dataRows = dataList.length ? rowsGenerator() : []

  return (
    <Table
      pagination={{
        current: page,
        pageSize,
        total
      }}
      onChangePage={onChangePage}
      enablePagination={enablePagination}
      paginationPosition={'TOP_RIGHT'}
      rowKey="id"
      columns={columns}
      items={dataRows}
      actionColumnConfig={hasAction ? actionConfig : undefined}
      enableRowSelect={enableSelect}
      selectedRows={selectedRows}
      isSingleSelect={isSingleSelect}
      onSelectRow={onSelect}
      isLoading={isLoading}
      forceHeight
    />
  )
}

export default FormulaSchemaTable
