import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { footerHeight, headerHeight } from '../../assets/mixins'
import { Button } from '../../components'
import { PageContent } from '../../containers'
import { FSchemaSearchForm } from '../../containers/FormulaSchema/FSchemaSearchForm'
import { fSchemaSliceActions } from '../../features/formulaSchema'
import {
  getSchemaTempFilters,
  selectFSchemaError
} from '../../features/formulaSchema/selectors'
import { GenericLayout } from '../../layouts'
import { AppRoutes } from '../constants'
import { ButtonWrapper, buttonWrapperHeight } from './styled'

const FSChemaSearch: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fSchemaError = useSelector(selectFSchemaError)
  const tempFilter = useSelector(getSchemaTempFilters)

  useEffect(() => {
    return () => {
      dispatch(fSchemaSliceActions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchHandler = () => {
    dispatch(fSchemaSliceActions.applyFilters(tempFilter))
    navigate(AppRoutes.VAULT_FORMULA_SCHEMA_RESULT)
  }

  const checkIfDisabled = () =>
    Object.values(tempFilter).every(
      (value) => value === null || value === '' || value === undefined
    )

  return (
    <GenericLayout error={fSchemaError}>
      <PageContent
        headerHeight={headerHeight}
        footerHeight={footerHeight + buttonWrapperHeight}
      >
        <FSchemaSearchForm />
      </PageContent>
      <ButtonWrapper>
        <Button
          label="Search"
          onClick={searchHandler}
          disabled={checkIfDisabled()}
          enableKeyPress
        />
      </ButtonWrapper>
    </GenericLayout>
  )
}

export default FSChemaSearch
