import { FC } from 'react'

import { WarningOutlined } from '@ant-design/icons'

import { Tooltip } from '../Tooltip'

import { IconWrapper } from './styled'
import { IWarningInfoProps } from './types'

export const WarningInfo: FC<IWarningInfoProps> = ({ message }) => {
  return (
    <IconWrapper>
      <Tooltip title={message}>
        <WarningOutlined
          style={{
            fontSize: '20px',
            color: 'orange'
          }}
        />
      </Tooltip>
    </IconWrapper>
  )
}
